import { selectCommonData } from '../selectors';

import { travelRegionPlacesAdapter } from './adapter';

const selectors = travelRegionPlacesAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).travelRegionPlaces,
);

export const selectTravelRegionPlaces = (state: IAppState) =>
  selectCommonData(state).travelRegionPlaces;

export const selectTravelRegionPlaceById =
  (id: TravelPlaceType['id']) => (state: IAppState) =>
    selectors.selectById(state, id);

export const selectAllTravelRegionPlaces = (state: IAppState) =>
  selectors.selectAll(state);

export const selectTravelRegionPlacesByIds =
  (placesIds: number[]) => (state: IAppState) =>
    placesIds.map((id) => selectTravelRegionPlaceById(id)(state));
