import React, { memo } from 'react';

import { StateTopicType } from 'common/redux/commonData/topics/typings';

import { MenuLinks } from '../MenuLinks';

import { useMenuItems } from './useTopicsMenu';

type TopicsMenuPropsType = {
  projectId: ProjectType['id'] | undefined;
  activeTopicId: StateTopicType['id'] | undefined | null;
  top100?: string;
};

/**
 * Компонент с ссылками на разные топики.
 * @param projectId - id вертикали;
 * @param activeTopicId - id топика к которому относится страница;
 * @param top100 - значение для топ 100.
 */
export const TopicsMenu = memo(
  ({ projectId, activeTopicId, top100 }: TopicsMenuPropsType) => {
    const top100Value = top100 || 'topics_menu';
    const menuItems = useMenuItems(projectId, activeTopicId);

    return <MenuLinks menuItems={menuItems} top100Value={top100Value} />;
  },
);
