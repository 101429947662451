import { fetchCurrenciesInfo } from 'common/redux/commonData/currencies';
import { fetchExchangeRateDynamics } from 'common/redux/commonData/widgets/exchangeRateDynamicsWidget/asyncs';
import { fetchRateExchangeForecast } from 'common/redux/commonData/widgets/exchangeRatesWidget';
import { fetchIndicesAndQuotes } from 'common/redux/commonData/widgets/indicesAndQuotesWidget/asyncs';
import {
  IndicesAndQuotesCharCode,
  IndicesAndQuotesMarketsName,
  IndicesAndQuotesPeriod,
} from 'common/redux/commonData/widgets/indicesAndQuotesWidget/typings';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { HUMAN_CENTERED_STRATEGY_SPLIT_VALUES } from 'config/constants/splits';
import { PromiseListType } from 'typings/AppRoute';

import {
  LIMIT_BY_TOP_DESKTOP,
  LIMIT_BY_TOP_GREEN,
  LIMIT_BY_TOP_MOBILE,
} from './constants';
import { GREEN_PROJECT_ALIASES, MIXED_PROJECT_ALIASES } from './home/constants';
import { GREEN_TOPIC_ALIASES } from './topic/constants';

type PropsType = {
  forceRedesign: boolean;
  redesignSplitValue: HUMAN_CENTERED_STRATEGY_SPLIT_VALUES;
  isMobile: boolean;
};

interface WithProjectAlias extends PropsType {
  projectAlias: ProjectType['alias'];
  gigaEnabled: boolean;
  topicAlias?: never;
}

interface WithTopicAlias extends PropsType {
  projectAlias?: never;
  gigaEnabled?: never;
  topicAlias: TopicType['alias'];
}

type GetTopLimitByPropsType = WithProjectAlias | WithTopicAlias;

/**
 * Функция получения лимита для запроса кластеров от топа
 * @param forceRedesign - флаг что используется редизайн версия
 * @param redesignSplitValue - значения сплита редизайна
 * @param projectAlias - alias проекта
 * @param gigaEnabled - флаг гигачата
 * @param topicAlias - alias топика
 * @param isMobile - флаг мобильной версии
 */
export const getTopLimitBy = ({
  forceRedesign,
  redesignSplitValue,
  projectAlias,
  gigaEnabled,
  topicAlias,
  isMobile,
}: GetTopLimitByPropsType) => {
  const cutClustersCount = getCutClustersCount(redesignSplitValue);

  if (projectAlias) {
    /**
     * Для всех зеленых и смешанных вертикалей мы рисуем
     *  50 новостей, так как там отключены рекомендации.
     */
    if (
      GREEN_PROJECT_ALIASES.includes(projectAlias) ||
      MIXED_PROJECT_ALIASES.includes(projectAlias)
    ) {
      return LIMIT_BY_TOP_GREEN;
    }

    /**
     * Смысл двух перечисленных условий состоит в том, что
     *  у нас на вертикали *Новости* в *редизайне* рисуется *гигачат*.
     * Гигачат требует несколько новостей в себя, которые мы потом вырезаем из топа, чтобы они не дублировались (в зависимости от сплита)
     *  поэтому мы запрашиваем на `cutClustersCount` новостей больше. ТОЛЬКО для нового дизайна и только с гигачатом.
     */
    if (isMobile) {
      return gigaEnabled &&
        projectAlias === PROJECT_ALIASES.News &&
        forceRedesign
        ? LIMIT_BY_TOP_MOBILE + cutClustersCount
        : LIMIT_BY_TOP_MOBILE;
    }

    return gigaEnabled && projectAlias === PROJECT_ALIASES.News && forceRedesign
      ? LIMIT_BY_TOP_DESKTOP + cutClustersCount
      : LIMIT_BY_TOP_DESKTOP;
  }

  /**
   * Для всех зеленых рубрик мы также рисуем 50 кластеров
   */
  if (topicAlias && GREEN_TOPIC_ALIASES.includes(topicAlias)) {
    return LIMIT_BY_TOP_GREEN;
  }

  if (isMobile) {
    return LIMIT_BY_TOP_MOBILE;
  }

  return LIMIT_BY_TOP_DESKTOP;
};

/**
 * Функция, которая возвращает список промисов.
 * @param dispatch - функция отправки события в redux;
 * @param withAllCurrency - флаг, нужно ли добавить в список фетч для получения всех валют.
 */
export const getFinancePromiseList = (
  dispatch: AppStore['dispatch'],
  withAllCurrency = true,
) => {
  const promiseList: PromiseListType = [
    dispatch(
      fetchIndicesAndQuotes({
        src: IndicesAndQuotesMarketsName.forex,
        period: IndicesAndQuotesPeriod.H1,
        charCode: IndicesAndQuotesCharCode.XAUUSD,
      }),
    ),
    dispatch(
      fetchIndicesAndQuotes({
        src: IndicesAndQuotesMarketsName.forex,
        period: IndicesAndQuotesPeriod.H1,
        charCode: IndicesAndQuotesCharCode.BRN,
      }),
    ),
    dispatch(
      fetchIndicesAndQuotes({
        src: IndicesAndQuotesMarketsName.moex,
        period: IndicesAndQuotesPeriod.H60,
        charCode: IndicesAndQuotesCharCode.RTSI,
      }),
    ),
    dispatch(fetchRateExchangeForecast()),
    dispatch(fetchExchangeRateDynamics()),
  ];

  if (withAllCurrency) {
    promiseList.push(dispatch(fetchCurrenciesInfo()));
  }

  return promiseList;
};

/**
 * Получение кол-ва кластеров которые вырезаем из топа на вертикали с гигачатом
 * @param splitValue – значение сплита редизайна
 */
export const getCutClustersCount = (
  splitValue: HUMAN_CENTERED_STRATEGY_SPLIT_VALUES,
) => {
  switch (splitValue) {
    case HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_4: {
      return 3;
    }

    case HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_1_4: {
      return 2;
    }

    default:
      return 0;
  }
};
