export enum PROJECT_IDS {
  Unknown = -1,
  News = 1,
  Auto = 2,
  Woman = 4,
  Sport = 5,
  Weekend = 6,
  Finance = 7,
  Doctor = 8,
  Kino = 10,
  Travel = 11,

  Family = 15,
  Life = 16,
  Pro = 17,
  Dom = 18,
  Ai = 19,
  Sci = 20,
}

export const isInProjectCodes = (val: number): val is PROJECT_IDS =>
  val in PROJECT_IDS;

export enum PROJECT_ALIASES {
  Unknown = 'unknown',
  News = 'news',
  Auto = 'auto',
  Woman = 'woman',
  Sport = 'sport',
  Weekend = 'weekend',
  Finance = 'finance',
  Doctor = 'doctor',
  Kino = 'kino',
  Travel = 'travel',

  Family = 'family',
  Life = 'life',
  Pro = 'pro',
  Dom = 'dom',
  Ai = 'ai',
  Sci = 'sci',
}

export const HEAD_DOMAIN = 'https://www.rambler.ru/';

export const PROJECT_ALIAS_BY_ID: Record<PROJECT_IDS, PROJECT_ALIASES> = {
  [PROJECT_IDS.Unknown]: PROJECT_ALIASES.Unknown,
  [PROJECT_IDS.News]: PROJECT_ALIASES.News,
  [PROJECT_IDS.Auto]: PROJECT_ALIASES.Auto,
  [PROJECT_IDS.Woman]: PROJECT_ALIASES.Woman,
  [PROJECT_IDS.Sport]: PROJECT_ALIASES.Sport,
  [PROJECT_IDS.Weekend]: PROJECT_ALIASES.Weekend,
  [PROJECT_IDS.Finance]: PROJECT_ALIASES.Finance,
  [PROJECT_IDS.Doctor]: PROJECT_ALIASES.Doctor,
  [PROJECT_IDS.Kino]: PROJECT_ALIASES.Kino,
  [PROJECT_IDS.Travel]: PROJECT_ALIASES.Travel,
  [PROJECT_IDS.Family]: PROJECT_ALIASES.Family,
  [PROJECT_IDS.Life]: PROJECT_ALIASES.Life,
  [PROJECT_IDS.Pro]: PROJECT_ALIASES.Pro,
  [PROJECT_IDS.Dom]: PROJECT_ALIASES.Dom,
  [PROJECT_IDS.Ai]: PROJECT_ALIASES.Ai,
  [PROJECT_IDS.Sci]: PROJECT_ALIASES.Sci,
};

export const PROJECTS_NAME_BY_ID: Record<PROJECT_IDS, string> = {
  [PROJECT_IDS.Unknown]: '',
  [PROJECT_IDS.News]: 'Рамблер/новости',
  [PROJECT_IDS.Auto]: 'Рамблер/авто',
  [PROJECT_IDS.Woman]: 'Рамблер/женский',
  [PROJECT_IDS.Sport]: 'Рамблер/спорт',
  [PROJECT_IDS.Weekend]: 'Рамблер/развлечение и отдых',
  [PROJECT_IDS.Finance]: 'Рамблер/личные финансы',
  [PROJECT_IDS.Doctor]: 'Рамблер/здоровье',
  [PROJECT_IDS.Kino]: 'Рамблер/кино',
  [PROJECT_IDS.Travel]: 'Рамблер/путешествия',

  [PROJECT_IDS.Family]: 'Рамблер/родители и дети',
  [PROJECT_IDS.Life]: 'Рамблер/жизненные ситуации',
  [PROJECT_IDS.Pro]: 'Рамблер/карьера',
  [PROJECT_IDS.Dom]: 'Рамблер/дом и уют',
  [PROJECT_IDS.Ai]: 'Рамблер/искусственный интеллект',
  [PROJECT_IDS.Sci]: 'Рамблер/технологии и тренды',
};

export const PROJECTS_SHORT_NAME_BY_ID: Record<PROJECT_IDS, string> = {
  [PROJECT_IDS.Unknown]: '',
  [PROJECT_IDS.News]: 'Все новости',
  [PROJECT_IDS.Auto]: 'Aвто',
  [PROJECT_IDS.Woman]: 'Женский',
  [PROJECT_IDS.Sport]: 'Спорт',
  [PROJECT_IDS.Weekend]: 'Развлечение и Отдых',
  [PROJECT_IDS.Finance]: 'Личные финансы',
  [PROJECT_IDS.Doctor]: 'Здоровье',
  [PROJECT_IDS.Kino]: 'Кино',
  [PROJECT_IDS.Travel]: 'Путешествия',

  [PROJECT_IDS.Family]: 'Родители и Дети',
  [PROJECT_IDS.Life]: 'Жизненные ситуации',
  [PROJECT_IDS.Pro]: 'Карьера',
  [PROJECT_IDS.Dom]: 'Дом и уют',
  [PROJECT_IDS.Ai]: 'Искусственный интеллект',
  [PROJECT_IDS.Sci]: 'Технологии и тренды',
};

export const LOGOS_WIDTH: Record<PROJECT_IDS, number> = {
  [PROJECT_IDS.Unknown]: 0,
  [PROJECT_IDS.News]: 221,
  [PROJECT_IDS.Weekend]: 250,
  [PROJECT_IDS.Finance]: 228,
  [PROJECT_IDS.Woman]: 223,
  [PROJECT_IDS.Kino]: 191,
  [PROJECT_IDS.Sport]: 198,
  [PROJECT_IDS.Auto]: 185,
  [PROJECT_IDS.Doctor]: 208,
  [PROJECT_IDS.Travel]: 258,

  // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
  [PROJECT_IDS.Family]: 0,
  [PROJECT_IDS.Life]: 0,
  [PROJECT_IDS.Pro]: 0,
  [PROJECT_IDS.Dom]: 0,
  [PROJECT_IDS.Ai]: 0,
  [PROJECT_IDS.Sci]: 0,
};

const NEWS_OG_IMG =
  'https://news.store.rambler.ru/img/cd667207cee24d5e7b056d20ecfd84e4';

export const PROJECT_OG_IMG: Record<PROJECT_ALIASES, string> = {
  [PROJECT_ALIASES.Unknown]: '',
  [PROJECT_ALIASES.News]: NEWS_OG_IMG,
  [PROJECT_ALIASES.Auto]:
    'https://news.store.rambler.ru/img/17b12d40e1cc8bf581e2d99476b4ed4b',
  [PROJECT_ALIASES.Woman]:
    'https://news.store.rambler.ru/img/81158b861317202aedac22b14af254bb',
  [PROJECT_ALIASES.Sport]:
    'https://news.store.rambler.ru/img/624bb9641e165f998955bdb814fe780e',
  [PROJECT_ALIASES.Weekend]:
    'https://news.store.rambler.ru/img/9c0a895ee4b899285acebe7d62077455',
  [PROJECT_ALIASES.Finance]:
    ' https://news.store.rambler.ru/img/f275e1ffb32facdcd395aa5360037337',
  [PROJECT_ALIASES.Doctor]:
    'https://news.store.rambler.ru/img/4361c64d20e8d8d4207922d6751fee9d',
  [PROJECT_ALIASES.Kino]:
    'https://news.store.rambler.ru/img/4c816c71217c84190ebc371194711aa8',
  [PROJECT_ALIASES.Travel]:
    'https://news.store.rambler.ru/img/dd4b98a93dfc001a5e91b293579dcae6',

  // CLONE NEWS. Ввиду отсутствия данных, тут - клон news
  [PROJECT_ALIASES.Family]: NEWS_OG_IMG,
  [PROJECT_ALIASES.Life]: NEWS_OG_IMG,
  [PROJECT_ALIASES.Pro]: NEWS_OG_IMG,
  [PROJECT_ALIASES.Dom]: NEWS_OG_IMG,
  [PROJECT_ALIASES.Ai]: NEWS_OG_IMG,
  [PROJECT_ALIASES.Sci]: NEWS_OG_IMG,
};

export const ID_PROJECT_BY_ALIAS: Record<PROJECT_ALIASES, PROJECT_IDS> = {
  [PROJECT_ALIASES.Unknown]: PROJECT_IDS.Unknown,
  [PROJECT_ALIASES.News]: PROJECT_IDS.News,
  [PROJECT_ALIASES.Auto]: PROJECT_IDS.Auto,
  [PROJECT_ALIASES.Woman]: PROJECT_IDS.Woman,
  [PROJECT_ALIASES.Sport]: PROJECT_IDS.Sport,
  [PROJECT_ALIASES.Weekend]: PROJECT_IDS.Weekend,
  [PROJECT_ALIASES.Finance]: PROJECT_IDS.Finance,
  [PROJECT_ALIASES.Doctor]: PROJECT_IDS.Doctor,
  [PROJECT_ALIASES.Kino]: PROJECT_IDS.Kino,
  [PROJECT_ALIASES.Travel]: PROJECT_IDS.Travel,

  [PROJECT_ALIASES.Family]: PROJECT_IDS.Family,
  [PROJECT_ALIASES.Life]: PROJECT_IDS.Life,
  [PROJECT_ALIASES.Pro]: PROJECT_IDS.Pro,
  [PROJECT_ALIASES.Dom]: PROJECT_IDS.Dom,
  [PROJECT_ALIASES.Ai]: PROJECT_IDS.Ai,
  [PROJECT_ALIASES.Sci]: PROJECT_IDS.Sci,
};
