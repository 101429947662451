import fecha, { setGlobalDateMasks, setGlobalDateI18n } from 'fecha';

// Маски отображения дат и времени
setGlobalDateMasks({
  longDate: 'YYYY-MM-DDTHH:mm:ssZZ',
  shortDate: 'DD.MM',
  rusMonthGenitive: 'MMM',
  rusMonth: 'MMMM',
  withoutCurrentYear: 'D MMM',
  rusFullDateGenitive: 'D MMM YYYY',
  dateWithSlashSeparator: '/YYYY/MM/DD/',
  calendar: 'YYYY-MM-DD',
  isoDateTime: 'YYYY-MM-DDTHH:mm:ssZ',
});

// Полноценные названия в именительном падеже, короткие в родительном
setGlobalDateI18n({
  dayNames: [
    'воскресенье',
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
  ],
  dayNamesShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  monthNamesShort: [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ],
  monthNames: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ],
});

export { fecha };
