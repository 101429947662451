import { selectCommonData } from '../selectors';

import { themesAdapter } from './adapter';

const selectors = themesAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).themes,
);

export const selectThemes = (state: IAppState) =>
  selectCommonData(state).themes;

export const selectThemeById =
  (themeId: ClusterResourceType['id']) => (state: IAppState) =>
    typeof themeId === 'number'
      ? selectors.selectById(state, themeId)
      : undefined;

export const selectThemesByIds =
  (themeIds: ClusterResourceType['id'][]) => (state: IAppState) =>
    (themeIds?.map((themeId) => ({
      ...selectThemeById(themeId)(state),
      type: 'theme',
    })) as (ATThemeInfo & { type: string })[]) || [];

export const selectThemesLoadingState = (state: IAppState) =>
  selectThemes(state).fetching;
