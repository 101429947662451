import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { editorsAdapter } from './adapter';
import {
  fetchEditorsByProjectId,
  fetchEditor,
  fetchEditorByCluster,
} from './asyncs';
import { createAliasToId } from './utils';

/**
 * Стейт редакторов.
 * @param entries - объект с ключ: id редактора - значение: информация о редакторе;
 * @param fetching - флаг, что происходит загрузка хотя бы одного редактора;
 * @param error - сообщение последней ошибки, произошедшей при загрузке редактора;
 * @param editorToId - объект-мапка для траснформации никнейма редактора в его id.
 */
const editorsSlice = createSlice({
  name: 'editors',
  initialState: editorsAdapter.getInitialState({
    fetching: false,
    error: '',
    aliasToId: {},
  } as Fetchable & AliasToIdAble<FullEditorType['id']>),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditorsByProjectId.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchEditor.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchEditorByCluster.pending, (state) => {
        state.fetching = true;
      });

    builder
      .addCase(
        fetchEditorsByProjectId.fulfilled,
        (state, { payload: allEditors }: PayloadAction<EditorType[]>) => {
          editorsAdapter.addMany(state, allEditors);
          state.aliasToId = {
            ...state.aliasToId,
            ...allEditors.reduce(createAliasToId, {
              ...state.aliasToId,
            } as AliasToIdAble['aliasToId']),
          };

          state.fetching = false;
        },
      )
      .addCase(
        fetchEditor.fulfilled,
        (state, { payload: editor }: PayloadAction<FullEditorType>) => {
          editorsAdapter.addOne(state, editor);

          if (!state.aliasToId[editor.alias]) {
            state.aliasToId = {
              ...state.aliasToId,
              [editor.alias]: editor.id,
            };
          }

          state.fetching = false;
        },
      )
      .addCase(
        fetchEditorByCluster.fulfilled,
        (state, { payload: editor }: PayloadAction<EditorType>) => {
          editorsAdapter.addOne(state, editor);

          if (!state.aliasToId[editor.alias]) {
            state.aliasToId = {
              ...state.aliasToId,
              [editor.alias]: editor.id,
            };
          }

          state.fetching = false;
        },
      );

    builder
      .addCase(fetchEditorsByProjectId.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      })
      .addCase(fetchEditor.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      })
      .addCase(fetchEditorByCluster.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const editorsReducer = editorsSlice.reducer;
export { fetchEditorByCluster };
