import { createSlice } from '@reduxjs/toolkit';

import { fetchBreakingNews, fetchBreakingNewsCluster } from './asyncs';

type BreakingNewsWidgetType = {
  clusterId: CardData['id'] | null;
  hasBreakingNews: boolean;
} & Fetchable;

/**
 * Слайс виджета для срочной новости.
 */
const breakingNewsSlice = createSlice({
  name: 'breakingNews',
  initialState: {
    clusterId: null,
    hasBreakingNews: false,
    fetching: false,
    error: '',
  } as BreakingNewsWidgetType,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBreakingNewsCluster.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(fetchBreakingNews.fulfilled, (state, { payload }) => {
      state.clusterId = payload.clusterId;
      state.hasBreakingNews = payload.hasBreakingNews;
    });

    builder.addCase(fetchBreakingNews.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message;
    });

    builder.addCase(fetchBreakingNewsCluster.fulfilled, (state) => {
      state.fetching = false;
    });

    builder.addCase(fetchBreakingNewsCluster.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message;
    });
  },
});

export const breakingNewsReducer = breakingNewsSlice.reducer;
