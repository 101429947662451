import { selectAutotagPuids } from 'common/redux/pages/autotag/selectors';
import { selectAutotagTopPuids } from 'common/redux/pages/autotagTop/selectors';
import { selectBanksPagePuids } from 'common/redux/pages/banks/selectors';
import { selectClusterPagePuids } from 'common/redux/pages/cluster/selectors';
import { selectClusterCommentsPagePuids } from 'common/redux/pages/clusterComments/selectors';
import { selectClusterItemsPuids } from 'common/redux/pages/clusterItems/selectors';
import { selectConsensusPagePuids } from 'common/redux/pages/consensus/selectors';
import { selectConverterPagePuids } from 'common/redux/pages/converter/selectors';
import { selectCrossPagePuids } from 'common/redux/pages/cross/selectors';
import { selectCurrenciesPagePuids } from 'common/redux/pages/currencies/selectors';
import { selectCurrencyPagePuids } from 'common/redux/pages/currency/selectors';
import { selectDatePuids } from 'common/redux/pages/date/selectors';
import { selectEditorPuids } from 'common/redux/pages/editor/selectors';
import { selectEditorsPuids } from 'common/redux/pages/editors/selectors';
import { selectExchangePagePuids } from 'common/redux/pages/exchange/selectors';
import { selectExpertPuids } from 'common/redux/pages/expert/selectors';
import { selectGuidePuids } from 'common/redux/pages/guide/selectors';
import { selectGuideCityPlacesPuids } from 'common/redux/pages/guideCityPlaces/selectors';
import { selectGuideContentPagePuids } from 'common/redux/pages/guideContentPage/selectors';
import { selectGuideCountryCitiesPuids } from 'common/redux/pages/guideCountryCities/selectors';
import { selectGuideCountryPlacesPuids } from 'common/redux/pages/guideCountryPlaces/selectors';
import { selectHomePagePuids } from 'common/redux/pages/home/selectors';
import { selectLatestPuids } from 'common/redux/pages/latest/selectors';
import { selectRegionPagePuids } from 'common/redux/pages/region/selectors';
import { selectTagPuids } from 'common/redux/pages/tag/selectors';
import { selectThemePuids } from 'common/redux/pages/theme/selectors';
import { selectTopicPagePuids } from 'common/redux/pages/topic/selectors';
import { PuidsType } from 'config/constants/common';
import { PAGE_TYPE } from 'config/constants/routerName';

const selectStubPuids = () => ({}) as PuidsType;

/**
 * Селекторы для получения пуидов в зависимости от типа страницы
 */
export const PUIDS_SELECTORS_BY_PAGENAME = {
  [PAGE_TYPE.autotag]: selectAutotagPuids,
  [PAGE_TYPE.autotagTop]: selectAutotagTopPuids,
  [PAGE_TYPE.cluster]: selectClusterPagePuids,
  [PAGE_TYPE.clusterComments]: selectClusterCommentsPagePuids,
  [PAGE_TYPE.clusterItems]: selectClusterItemsPuids,
  [PAGE_TYPE.consensus]: selectConsensusPagePuids,
  [PAGE_TYPE.converter]: selectConverterPagePuids,
  [PAGE_TYPE.banks]: selectBanksPagePuids,
  [PAGE_TYPE.cross]: selectCrossPagePuids,
  [PAGE_TYPE.currencies]: selectCurrenciesPagePuids,
  [PAGE_TYPE.currency]: selectCurrencyPagePuids,
  [PAGE_TYPE.date]: selectDatePuids,
  [PAGE_TYPE.editor]: selectEditorPuids,
  [PAGE_TYPE.editors]: selectEditorsPuids,
  [PAGE_TYPE.exchange]: selectExchangePagePuids,
  [PAGE_TYPE.expert]: selectExpertPuids,
  [PAGE_TYPE.formatTopic]: selectTopicPagePuids,
  [PAGE_TYPE.home]: selectHomePagePuids,
  [PAGE_TYPE.guide]: selectGuidePuids,
  [PAGE_TYPE.guideCountryCities]: selectGuideCountryCitiesPuids,
  [PAGE_TYPE.guideCountryPlaces]: selectGuideCountryPlacesPuids,
  [PAGE_TYPE.guideCityPlaces]: selectGuideCityPlacesPuids,
  [PAGE_TYPE.guideCountry]: selectGuideContentPagePuids,
  [PAGE_TYPE.guideCity]: selectGuideContentPagePuids,
  [PAGE_TYPE.guidePlace]: selectGuideContentPagePuids,
  [PAGE_TYPE.latest]: selectLatestPuids,
  [PAGE_TYPE.longread]: selectStubPuids,
  [PAGE_TYPE.notFound]: selectStubPuids,
  [PAGE_TYPE.region]: selectRegionPagePuids,
  [PAGE_TYPE.tag]: selectTagPuids,
  [PAGE_TYPE.theme]: selectThemePuids,
  [PAGE_TYPE.topic]: selectTopicPagePuids,
};
