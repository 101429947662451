import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
// export const useAppSelector = useSelector.withTypes<IAppState>();
export const useAppSelector: TypedUseSelectorHook<IAppState> = useSelector;
export const useAppStore = useStore.withTypes<AppStore>();
