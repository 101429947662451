import loadable from '@loadable/component';
import React from 'react';

import {
  setAutotagTopAlias,
  setAutotagTopPuids,
} from 'common/redux/pages/autotagTop';
import { fetchAutotagTop } from 'common/redux/pages/autotagTop/asyncs';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const AutotagTopDesktop = loadable(() => import('desktop/pages/AutotagTop'));
const AutotagTopMobile = loadable(() => import('mobile/pages/AutotagTop'));

/**
 * Роут топа автотегов.
 * https://news.rambler.ru/organization/
 * https://news.rambler.ru/person/top50/
 */
export const autotagTopRoutes = (
  isMobile: SettingsType['isMobile'],
  autotagAlias: AUTOTAG_TYPE.person | AUTOTAG_TYPE.organization,
): IAppRoute => {
  const path =
    autotagAlias === AUTOTAG_TYPE.person
      ? `/${autotagAlias}/top50/`
      : `/${autotagAlias}/`;

  return {
    name: PAGE_TYPE.autotagTop,
    exact: true,
    path,
    render: isMobile ? () => <AutotagTopMobile /> : () => <AutotagTopDesktop />,
    fetchData: async ({ dispatch, getState }) => {
      const projectAlias = selectProjectAlias(getState());

      dispatch(setAutotagTopPuids(projectAlias));
      dispatch(setAutotagTopAlias(autotagAlias));

      const promiseList: PromiseListType = [
        dispatch(fetchAutotagTop({ autotagAlias })),
      ];

      await Promise.all(
        appendPageDownloader({
          promiseList,
          Component: isMobile ? AutotagTopMobile : AutotagTopDesktop,
          key: Object.keys({ AutotagTopDesktop })[0],
        }),
      );
    },
  };
};
