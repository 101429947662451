import { createEntityAdapter } from '@reduxjs/toolkit';

/**
 * Адаптер мест для путешествий к типу [id места]: { данные по месту }
 */
export const travelRegionPlacesAdapter = createEntityAdapter<
  TravelPlaceType,
  number
>({
  selectId: (place) => place.id,
});
