import { createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type ConsensusState = {
  puids: PuidsType;
};

const initialState: ConsensusState = {
  // Рекламные пуиды
  puids: {},
};

const consensusSlice = createSlice({
  name: PAGE_TYPE.consensus,
  initialState,
  reducers: {
    /**
     * Генерация puids для страницы.
     */
    setConsensusPuids(state) {
      const puidName = getPuidName(PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_currencies`.toUpperCase(),
        puid18: `${puidName}_currencies_forecast`.toUpperCase(),
      };
    },
  },
});

export const consensusReducer = consensusSlice.reducer;

export const { setConsensusPuids } = consensusSlice.actions;
