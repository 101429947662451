import Banners from 'config/constants/banner/banners';
import { PROJECT_IDS } from 'config/constants/projects/constants';

import { videoAdAdapter } from './adapter';

const VIDEO_AD_DATA_DEFAULT: VideoAdData = {
  projectId: -1,
  title: '',
  embedCode: '',
  active: false,
  excludedTopics: [],
};

export const selectAppController = (state: IAppState) => state.appController;

const videoAdSelectors = videoAdAdapter.getSelectors(
  (state: IAppState) => selectAppController(state).videoAd,
);

export const selectServerRender = (state: IAppState) =>
  selectAppController(state).serverRender;

export const selectReloadKey = (state: IAppState) =>
  selectAppController(state).reloadKey;

export const selectPageName = (state: IAppState) =>
  selectAppController(state).pageName;

export const selectDisableAdv = (state: IAppState) =>
  selectAppController(state).disableAdv;

export const selectBannersStateByBannerName =
  (name: Banners) => (state: IAppState) =>
    selectAppController(state).bannersState[name];

export const selectIsBrandingByBannerName =
  (name: Banners) => (state: IAppState) =>
    selectBannersStateByBannerName(name)(state)?.isBranding;

export const selectVideoAdById = (id: PROJECT_IDS) => (state: IAppState) => {
  return videoAdSelectors.selectById(state, id) || VIDEO_AD_DATA_DEFAULT;
};

export const selectViewportType = (state: IAppState) =>
  selectAppController(state).viewportType;
