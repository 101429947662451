import {
  AsyncThunk,
  PayloadAction,
  asyncThunkCreator,
  buildCreateSlice,
} from '@reduxjs/toolkit';

import { StatusTypes } from './typings';

export const getPuidName = (projectAlias: ProjectType['alias']) =>
  `r${projectAlias}`;

/**
 * Функция сопоставления. Если нужно выполнить одинаковые действия для нескольких разных запросов
 * @param type Тип статуса запроса
 * @param thunks Массив thunk созданных с помощью createAsyncThunk
 * @returns true/false
 */
export const matchType =
  (type: StatusTypes, thunks: AsyncThunk<unknown, unknown, object>[]) =>
  (action: PayloadAction) => {
    if (!Array.isArray(thunks)) return false;

    const similarActions = {
      pending: thunks.map((thunk) => thunk.pending.type),
      fulfilled: thunks.map((thunk) => thunk.fulfilled.type),
      rejected: thunks.map((thunk) => thunk.rejected.type),
    };

    return similarActions[type].includes(action.type);
  };

/**
 * Функция создания слайса (взамен createSlice из @reduxjs/toolkit)
 *  Для возможности создания asyncThunk прямо в слайсе
 */
export const createSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});
