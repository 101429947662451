import { dateToFullYear } from 'utils/date/dtime';
import { getCrossCourseList } from 'utils/finance';

import {
  calculateStats,
  dataLoaderForCourseChartFabric,
  dataLoaderForTwoCoursesChartFabric,
} from './utils';

/**
 * Загрузка данных для графика "Динамика курса" и "Кросс-курс",
 * где показывается динамика курса относительно рубля.
 * @param props.currencyCharCodes - массив, состоящий из одной валюты.
 */
export const loadDataForSingleCourseChart = dataLoaderForCourseChartFabric(
  'singleCourseChart',
  (rates) => {
    const chartData =
      // Увы, надо клонировать массив, иначе с ним нельзя работать
      [...rates].reverse().map((val, index, arr) => {
        const rate = val.rate ?? 0;
        const rawPrevRate = index !== 0 ? arr[index - 1].rate : 0;
        const prevRate = rawPrevRate !== null ? rawPrevRate : 0;

        return {
          y: rate,
          x: dateToFullYear(val.dt),
          diff: index === 0 ? 0 : rate - prevRate,
        };
      }) ?? [];

    const stats = calculateStats(chartData);

    return {
      ...stats,
      chartData,
    };
  },
);

/**
 * Загрузка данных для графика "Конвертер валют",
 * где показывается динамика курса двух валют относительно друг друга.
 * @param props.currencyCharCodes - массив, состоящий из одной валюты.
 */
export const loadDataForManyCourseChart = dataLoaderForTwoCoursesChartFabric(
  'twoCoursesChart',
  (result) => {
    const {
      amount,
      firstRates,
      secondRates,
      firstCurrencyData,
      secondCurrencyData,
    } = result;

    const chartData =
      firstCurrencyData && secondCurrencyData && amount
        ? getCrossCourseList({
            firstCurrencyData,
            secondCurrencyData,
            firstCurrencyRates: firstRates,
            secondCurrencyRates: secondRates,
            amount,
          })
        : [];

    const stats = calculateStats(chartData);

    return {
      ...stats,
      chartData,
    };
  },
);
