import { CURRENCY_CHAR_CODE } from 'config/constants/finance';

import { DEFAULT_DIRECTION } from './constants';
import {
  AciveSortType,
  BankExchangeType,
  CurrencyExchangeType,
  RATE_TYPE,
  SORT_DIRECTION,
  SORT_TYPE,
} from './typings';

type SortBankType = (props: {
  direction: SORT_DIRECTION;
}) => (bankA: BankExchangeType, bankB: BankExchangeType) => number;

/**
 * Функция сортировки по названию банка.
 * @param direction - направление сортировки.
 */
export const sortBank: SortBankType =
  ({ direction }) =>
  (bankA, bankB) => {
    const sortOrder = direction === SORT_DIRECTION.asc ? 1 : -1;

    if (bankA.name > bankB.name) {
      return sortOrder;
    }

    if (bankA.name < bankB.name) {
      return -sortOrder;
    }

    return 0;
  };

type SortCurrencyType = ({
  currency,
  rate,
  direction,
}: {
  currency: CurrencyExchangeType;
  rate: RATE_TYPE;
  direction: SORT_DIRECTION;
}) => (bankA: BankExchangeType, bankB: BankExchangeType) => number;

/**
 * Функция сортировки по значению валюты.
 * @param currency - валюта для сортировки.
 * @param rate - цена покупки/продажи для сортировки.
 * @param direction - направление сортировки.
 */
export const sortCurrency: SortCurrencyType =
  ({ currency, rate, direction }) =>
  (bankA, bankB) => {
    const sortOrder = direction === SORT_DIRECTION.asc ? 1 : -1;
    const rateA = bankA[currency][rate];
    const rateB = bankB[currency][rate];

    if (Number(rateA) > Number(rateB)) {
      return sortOrder;
    }

    if (Number(rateA) < Number(rateB)) {
      return -sortOrder;
    }

    return 0;
  };

type GetSortDirectionType = (props: {
  sort: SORT_TYPE;
  aciveSort: AciveSortType;
}) => SORT_DIRECTION.asc | SORT_DIRECTION.desc;

/**
 * Функция получения направления сортировки.
 * @param sort - тип сортировки.
 * @param aciveSort - объект текущей сортировки.
 */
export const getSortDirection: GetSortDirectionType = ({ sort, aciveSort }) => {
  if (aciveSort.sort === sort) {
    return aciveSort.direction === SORT_DIRECTION.asc
      ? SORT_DIRECTION.desc
      : SORT_DIRECTION.asc;
  }

  return DEFAULT_DIRECTION;
};

/**
 * Функция получения типа сортировки для мобилы.
 * @param sort - тип сортировки.
 * @param currency - валюта.
 */
type GetSortTypeType = (props: {
  sort: SORT_TYPE;
  currency: CurrencyExchangeType;
}) => SORT_TYPE;

export const getSortType: GetSortTypeType = ({ sort, currency }) => {
  const isBuy = sort.toLocaleLowerCase().includes('buy');

  if (currency === CURRENCY_CHAR_CODE.USD) {
    return isBuy ? SORT_TYPE.usdBuy : SORT_TYPE.usdSell;
  }

  if (currency === CURRENCY_CHAR_CODE.EUR) {
    return isBuy ? SORT_TYPE.eurBuy : SORT_TYPE.eurSell;
  }

  return SORT_TYPE.bank;
};
