import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCurrencies, getWeather } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { fetchWeather as fetchWeatherTopline } from 'desktop/components/RamblerComponents/RamblerTopline';

/**
 * Получение cписка валют для виджета хедера.
 *
 * Использует кэширование.
 */
export const fetchHeaderCurrencies = createAsyncThunk(
  'fetchHeaderCurrencies',
  async (_props, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);
    const { error, data } = await getCurrencies(apiConfig);

    if (error || !data) {
      throw new Error(`Нет данных о валюте`);
    }

    return data;
  },
);

/**
 * Получение данных о погоде.
 */
export const fetchWeather = createAsyncThunk(
  'fetchWeather',
  async (
    { geoid, isRedesign }: { geoid: string | string[]; isRedesign: boolean },
    { getState },
  ) => {
    if (isRedesign && !Array.isArray(geoid)) {
      // Когда починят функцию - её можно будет добавить туть
      return fetchWeatherTopline(Number(geoid));
    }

    const apiConfig = selectApiConfig(getState() as IAppState);

    const { error, data } = await getWeather(apiConfig, geoid);

    if (error || !data) {
      throw new Error(`Нет данных о погоде: ${geoid}`);
    }

    return data;
  },
);
