import loadable from '@loadable/component';
import React from 'react';

import { fetchTravelCities } from 'common/redux/commonData/travelCities';
import { fetchTravelCountries } from 'common/redux/commonData/travelCountries';
import {
  setPageCities,
  setPageCountryAlias,
} from 'common/redux/pages/guideCountryCities';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const GuideCountryCitiesDesktop = loadable(
  () => import('desktop/pages/GuideCountryCities'),
);
const GuideCountryCitiesMobile = loadable(
  () => import('mobile/pages/GuideCountryCities'),
);

/**
 * Роут Путеводителя по конкретному региону.
 *
 * https://travel.rambler.ru/guide/Russia/cities/
 */
export const guideCountryCitiesRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute<{ countryAlias: string }> => ({
  name: PAGE_TYPE.guideCountryCities,
  exact: true,
  path: '/guide/:countryAlias([-A-Za-z]+)/cities/',
  render: isMobile
    ? () => <GuideCountryCitiesMobile />
    : () => <GuideCountryCitiesDesktop />,
  fetchData: async ({ dispatch }, { params: { countryAlias } }) => {
    const callback = (data: TravelRegionType[]) => {
      const citiesAliases = data.map(({ alias }) => alias);

      dispatch(setPageCities(citiesAliases));
    };

    dispatch(setPageCountryAlias(countryAlias));

    const promiseList: PromiseListType = [
      // Список стран для селекта в виджете
      dispatch(fetchTravelCountries()),
      // Карточки городов текущей страны
      dispatch(fetchTravelCities({ regionAlias: countryAlias, callback })),
    ];

    const GuideCountryCities = isMobile
      ? GuideCountryCitiesMobile
      : GuideCountryCitiesDesktop;

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: GuideCountryCities,
        key: Object.keys({ GuideCountryCities })[0],
      }),
    );
  },
});
