import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { matchType } from 'common/redux/utils';

import { regionsAdapter } from './adapter';
import { fetchRegion, fetchRegionClusters } from './asyncs';

type AddRegionIdAction = PayloadAction<{
  alias: ATRegion['alias'];
  id: ATRegion['id'];
}>;

const thunks = [fetchRegion, fetchRegionClusters];

/**
 * Слайс данных для регионов.
 *
 * Получает и хранит данные о регионах по id.
 */
export const regionsSlice = createSlice({
  name: 'regions',
  initialState: regionsAdapter.getInitialState({
    fetching: false,
    error: '',
    aliasToId: {} as Record<ATRegion['alias'], ATRegion['id']>,
  }),
  reducers: {
    /**
     * Метод установки уникального идентификатора для региона.
     * @param action.payload.alias - alias региона.
     * @param action.payload.id - id региона.
     */
    addRegionId: (state, { payload }: AddRegionIdAction) => {
      const { alias, id } = payload;
      if (alias && id) state.aliasToId[alias] = id;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRegion.fulfilled, (state, { payload: data }) => {
      state.fetching = false;

      const { id, alias } = data || {};
      if (alias && id) state.aliasToId[alias] = id;
      regionsAdapter.addOne(state, data);
    });
    builder.addCase(fetchRegionClusters.fulfilled, (state, { payload }) => {
      const { clusterIds = [], id } = payload;

      if (id) {
        regionsAdapter.updateOne(state, { id, changes: { clusterIds } });
      }

      state.fetching = false;
    });
    // @ts-expect-error: поправить при переезде на новый синтаксис
    builder.addMatcher(matchType('pending', thunks), (state) => {
      state.fetching = true;
      state.error = '';
    });
    // @ts-expect-error: поправить при переезде на новый синтаксис
    builder.addMatcher(matchType('rejected', thunks), (state, { error }) => {
      state.error = error.message;
      state.fetching = false;
    });
  },
});

export const regionsReducer = regionsSlice.reducer;
export const { addRegionId } = regionsSlice.actions;

export { fetchRegion, fetchRegionClusters };
