import { createAsyncThunk } from '@reduxjs/toolkit';

import { getClusterResource } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

/**
 * Функция загрузки источнка кластера.
 * @param clusterId - id кластера, для которого загружается источник;
 * @param callback - коллбек, которому возвращаются данные успешно загруженного источника.
 */
export const fetchResourceByCluster = createAsyncThunk(
  'fetchResourceByCluster',
  async (
    {
      clusterId,
      callback,
    }: {
      clusterId: ClusterData['id'];
      callback?: (resource: ClusterResourceType) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as IAppState);

    const { data: resource, error } = await getClusterResource(api, clusterId);

    if (error || !resource) {
      throw (
        error || new Error(`Ошибка получения источника кластера ${clusterId}`)
      );
    }

    if (callback) await callback(resource);

    return resource;
  },
);
