import { createEntityAdapter } from '@reduxjs/toolkit';

import { createCharToIdMap } from 'utils/createCharToIdMap';

import { BankType, BankFetchingType } from './typings';

export const banksAdapter = createEntityAdapter<BankFetchingType>({});

export const banksCharToIdAdapter = createCharToIdMap<BankType>({
  selectAlias: (val) => val.code,
});
