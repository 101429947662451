import { RequestError } from 'api/getData/types';
import { COUNTERS_NAMES } from 'server/typings';

import { metricsBatch } from '.';

const MIN_REQUEST_COUNTER = 1;
const MIN_REQUEST_REPEAT_COUNTER = 0;
const MAX_REQUEST_REPEAT_COUNTER = 3;

type SendRequestErrorPropsType = {
  requestUrl: string;
  requestError: RequestError;
};

/**
 * Функция отправки ошибки в sentry и prometheus.
 * @param props.requestUrl - url запроса;
 * @param props.requestError - ошибка запроса.
 */
export const sendRequestError = ({
  requestError,
}: SendRequestErrorPropsType) => {
  // Batch ошибки запроса
  metricsBatch.pushToCounters<COUNTERS_NAMES.RequestError>({
    counterName: COUNTERS_NAMES.RequestError,
    params: {
      errorType: requestError.name,
      entrypoint: requestError.entrypoint,
      status: `${requestError.status}`,
    },
  });
};

type RefetchFailedRequestType = (
  requestCallback: () => Promise<any>,
  requestCounter?: number,
) => Promise<APIResponse<any, any>>;

/**
 * Обертка для повторных запросов.
 * @param requestCallback - функция запроса;
 * @param requestCounter - счетчик запроса.
 */
export const refetchFailedRequest: RefetchFailedRequestType = async (
  requestCallback,
  requestCounter = 0,
) => {
  const { data, error, entrypoint } = await requestCallback();

  // eslint-disable-next-line no-param-reassign
  requestCounter += 1;

  if (
    (!Array.isArray(data) || !data.length || error) &&
    requestCounter <= MAX_REQUEST_REPEAT_COUNTER
  ) {
    return refetchFailedRequest(requestCallback, requestCounter);
  }

  const counter =
    requestCounter > MIN_REQUEST_COUNTER
      ? requestCounter - MIN_REQUEST_COUNTER
      : MIN_REQUEST_REPEAT_COUNTER;

  // Batch повторяющихся запросов
  metricsBatch.pushToCounters<COUNTERS_NAMES.RepeatRequest>({
    counterName: COUNTERS_NAMES.RepeatRequest,
    params: {
      entrypoint,
      counter: `${counter}`,
    },
  });

  return { data, error };
};
