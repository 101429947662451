import React, { memo } from 'react';
import { Organization } from 'schema-dts';

import { JsonLd } from 'utils/jsond';

import { useItemForOrganization } from './hooks';

/**
 * Инфо о организации для schemaOrg
 */
export const OrganizationInfoSchema = memo(() => {
  const item = useItemForOrganization();

  return <JsonLd<Organization> item={item} />;
});
