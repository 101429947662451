import { selectPages } from '../selectors';

export const selectGuideContentPage = (state: IAppState) =>
  selectPages(state).guideContentPage;

export const selectGuideContentPagePuids = (state: IAppState) =>
  selectGuideContentPage(state).puids;

export const selectGuideContentPageCountryAlias = (state: IAppState) =>
  selectGuideContentPage(state).countryAlias;

export const selectGuideContentPageCityAlias = (state: IAppState) =>
  selectGuideContentPage(state).cityAlias;

export const selectGuideContentPagePlaceId = (state: IAppState) =>
  selectGuideContentPage(state).placeId;
