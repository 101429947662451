import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAutotagPageInfo, getClusterAutotags } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { AUTOTAG_TYPE } from 'config/constants/cluster';

/**
 * Функция загрузки автотегов кластера.
 * @param clusterId - id кластера, для которого загружаются автотеги;
 * @param callback - коллбек, которому возвращаются данные успешно загруженных автотегов.
 */
export const fetchAutotagsByCluster = createAsyncThunk(
  'fetchAutotagsByCluster',
  async (
    {
      clusterId,
      callback,
    }: {
      clusterId: ClusterData['id'];
      callback?: (resource: ATAutotag[]) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as IAppState);

    const { data: autotags, error: autotagError } = await getClusterAutotags(
      api,
      clusterId,
    );

    if (autotagError || !autotags) {
      throw (
        autotagError ||
        new Error(`Ошибка при получении автотегов по кластеру: ${clusterId}`)
      );
    }

    if (callback) await callback(autotags);

    return autotags;
  },
);

/**
 * Функция загрузки автотега по алиасу.
 * @param alias - символьное наименование автотега, по которому делается запрос;
 * @param type - тип автотега.
 */
export const fetchAutotagByAlias = createAsyncThunk(
  'fetchAutotagByAlias',
  async (
    {
      type,
      alias,
    }: {
      type: AUTOTAG_TYPE;
      alias: string;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as IAppState);

    const { data: autotag, error: autotagError } = await getAutotagPageInfo(
      api,
      type,
      alias,
    );

    if (autotagError || !autotag) {
      throw (
        autotagError ||
        new Error(`Ошибка при получении автотегов по alias: ${alias}`)
      );
    }

    return autotag;
  },
);
