import { createSlice } from '@reduxjs/toolkit';

import { indicesAndQuotesWidgetAdapter } from './adapter';
import { fetchIndicesAndQuotes } from './asyncs';

/**
 * Слайс виджета Индексы и котировки
 */
const indicesAndQuotesSlice = createSlice({
  name: 'indicesAndQuotes',
  initialState: indicesAndQuotesWidgetAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIndicesAndQuotes.pending, (state, { meta }) => {
      const { charCode } = meta.arg;

      indicesAndQuotesWidgetAdapter.addOne(state, {
        id: charCode,
        data: null,
        fetching: true,
        error: '',
      });
    });

    builder.addCase(
      fetchIndicesAndQuotes.fulfilled,
      (state, { meta, payload }) => {
        const { charCode } = meta.arg;

        indicesAndQuotesWidgetAdapter.upsertOne(state, {
          id: charCode,
          data: payload,
          fetching: false,
          error: '',
        });
      },
    );

    builder.addCase(
      fetchIndicesAndQuotes.rejected,
      (state, { meta, error }) => {
        const { charCode } = meta.arg;

        indicesAndQuotesWidgetAdapter.upsertOne(state, {
          id: charCode,
          data: null,
          fetching: false,
          error: error.message,
        });
      },
    );
  },
});

export const indicesAndQuotesReducer = indicesAndQuotesSlice.reducer;
