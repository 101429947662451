import loadable from '@loadable/component';
import React from 'react';

import { fetchBankRegions } from 'common/redux/commonData/bankRegions/asyncs';
import { fetchBankExchange } from 'common/redux/commonData/widgets/exchangeWidget';
import { DEFAULT_REGION } from 'common/redux/commonData/widgets/exchangeWidget/constants';
import { setExchangePuids } from 'common/redux/pages/exchange';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

import { appendPageDownloader } from '../pageLoadable';
import { getFinancePromiseList } from '../utils';

const ExchangeDesktop = loadable(() => import('desktop/pages/Exchange'));
const ExchangeMobile = loadable(() => import('mobile/pages/Exchange'));

/**
 * Роут страницы курса обмена доллара и евро.
 * https://finance.rambler.ru/currencies/exchange/
 * @see https://www.figma.com/file/vboo3WIcMAD7qk7BgafOEl/Finance?type=design&node-id=2356-22929&mode=design&t=ppK0DiHthgibbGX6-4
 */
export const exchangeRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute => ({
  name: PAGE_TYPE.exchange,
  exact: true,
  path: '/currencies/exchange/',
  render: isMobile ? () => <ExchangeMobile /> : () => <ExchangeDesktop />,
  fetchData: async ({ dispatch }) => {
    dispatch(setExchangePuids());

    const promiseList: PromiseListType = [
      dispatch(fetchBankRegions()),
      dispatch(fetchBankExchange({ bankRegion: DEFAULT_REGION })),
      ...getFinancePromiseList(dispatch, false),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? ExchangeMobile : ExchangeDesktop,
        key: Object.keys({ ExchangeDesktop })[0],
      }),
    );
  },
});
