import { createAsyncThunk } from '@reduxjs/toolkit';

import { getNewsByTopic, getTopsByProject } from 'api';
import {
  selectApiConfig,
  selectDomainConfig,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { adaptClusterToCard } from 'utils/adapters';

import { upsertEntries } from '../../entries';

const PAGE_BY_TOPIC_CLUSTERS = 1;
const LIMIT_BY_TOPIC_CLUSTERS = 30;
const EXTRA_API = { clientTimeout: 20000, timeout: 20000 };

type FetchTopicNewsPropsType = {
  topicId: ATTopic['id'] | undefined;
};

/**
 * Функция загрузки новостей для виджета c новостями по топику.
 * @param props.topicId - id топика;
 * @param props.excludeIds - исключаемые из отображения id.
 */
export const fetchTopicNews = createAsyncThunk(
  'widgets/fetchTopicNews',
  async ({ topicId }: FetchTopicNewsPropsType, { getState, dispatch }) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);

    if (!topicId) {
      throw new Error(`Отсутствует topicId: ${topicId}`);
    }

    const { data, error } = await getNewsByTopic({
      apiConfig: {
        ...apiConfig,
        peroxide: { ...apiConfig.peroxide, ...EXTRA_API },
      },
      topicId,
      page: PAGE_BY_TOPIC_CLUSTERS,
      limit: LIMIT_BY_TOPIC_CLUSTERS,
    });

    if (error || !data?.clusters) {
      throw error;
    }

    const cards = data.clusters.map((cluster) =>
      adaptClusterToCard({ cluster, domainConfig, variables }),
    );

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);

type FetchProjectTopNewsType = {
  projectId: ProjectType['id'] | undefined;
};

/**
 * Функция загрузки новостей для виджета c новостями по топику.
 * @param props.projectId - id топика;
 * @param props.excludeIds - исключаемые из отображения id.
 */
export const fetchProjectTopNews = createAsyncThunk(
  'widgets/fetchProjectTopNews',
  async ({ projectId }: FetchProjectTopNewsType, { getState, dispatch }) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);

    if (!projectId) {
      throw new Error(`Отсутствует projectId: ${projectId}`);
    }

    const { data, error } = await getTopsByProject(
      {
        ...apiConfig,
        peroxide: { ...apiConfig.peroxide, ...EXTRA_API },
      },
      projectId,
      'project',
      LIMIT_BY_TOPIC_CLUSTERS,
    );

    if (error || !data) {
      throw error;
    }

    const cards = data.map((cluster) =>
      adaptClusterToCard({ cluster, domainConfig, variables }),
    );

    dispatch(upsertEntries(cards));

    return cards.map(({ id }) => id);
  },
);
