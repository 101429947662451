/** Главные новости по текущей вертикали */
export const TOP_ID_PROJECT = 'project';

/** Главные новости по порталу */
export const TOP_ID_COMMON = 'common';

/** Кол-во новостей в виджете топа вертикали */
export const TOP_PROJECT_WIDGET_NEWS_COUNT = 7;

/** Кол-во новостей в тизере топ новостей для статьи */
export const TOP_PROJECT_TEASER_NEWS_COUNT = 9;
