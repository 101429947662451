import { getRecommendedClusters } from 'api';
import { selectPageName } from 'common/redux/appController/selectors';
import { selectHomePageClusterIds } from 'common/redux/pages/home/selectors';
import { selectTopicPageClusterIds } from 'common/redux/pages/topic/selectors';
import {
  selectApiConfig,
  selectIsBot,
  selectIsMobile,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import {
  selectPageTopicName,
  selectRegionNameByRegionPage,
} from 'common/redux/selectors';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { RUID_MOCK } from 'config/constants/recommend';
import { PAGE_TYPE } from 'config/constants/routerName';

const TOP_NEWS_EXCLUDED_COUNT_MOBILE = 10;
const TOP_NEWS_EXCLUDED_COUNT = 29;
const REC_CLUSTERS_COUNT_DESK = 20;
const DEFAULT_REC_CLUSTERS_COUNT_MOB = 17;
const DEFAULT_REC_CLUSTERS_COUNT_FOR_BOT_MOB = 24;

type FetchRecommendedClustersPropTypes = {
  rcmBlockType: RCM_BLOCK_TYPE;
  clusterId: ClusterData['id'] | null;
  limit?: number;
  itemExcludedIds?: ClusterData['id'][];
  sessionID?: string;
};

/**
 * Получение списка рекомендованных кластеров
 * @param rcmBlockType - алиас, под которым хранится BlockID в сторе
 * @param clusterId - id кластера, который будет исключен из выдачи
 * @param limit - лимит количества возвращаемых id
 * @param itemExcludedIds - список id кластеров, которые будут исключены из выдачи рекомендаций
 * @param sessionID - id сесси общения с рекомендами
 */
export const fetchRecommendedClusters =
  ({
    rcmBlockType,
    clusterId,
    limit,
    itemExcludedIds,
    sessionID,
  }: FetchRecommendedClustersPropTypes) =>
  async (_dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    const state = getState();

    const { ruid, userId, adtech } = selectRuntime(state);
    const apiConfig = selectApiConfig(state);
    const pageName = selectPageName(state);
    const isBot = selectIsBot(state);
    const locationName = selectRegionNameByRegionPage(state);
    const category = selectPageTopicName(state);

    const blockId = adtech.recommendBlockID[rcmBlockType];

    return await getRecommendedClusters({
      blockId,
      xuid: ruid || (__DEV__ || __DOCKER__ ? RUID_MOCK : ''),
      itemId: clusterId,
      limit,
      itemExcludedIds,
      userId,
      sessionID,
      pageName,
      isBot,
      category,
      locationName,
      apiConfig,
    });
  };

/**
 * Генерация айтемов для исключения из выдачи рекоменд
 * @param state - redux-state приложения
 * @param page - текущая страница приложения
 * @param count - количество айтемов, которые нужно исключить
 */
const getExcludedItems = ({
  state,
  page,
  count,
}: {
  state: IAppState;
  page: string;
  count: number;
}) => {
  let items: ClusterData['id'][] = [];

  switch (page) {
    case PAGE_TYPE.home: {
      items = selectHomePageClusterIds(state);
      break;
    }

    case PAGE_TYPE.topic: {
      items = selectTopicPageClusterIds(state);
      break;
    }

    default:
      break;
  }

  return items.slice(0, count);
};

type FetchRecommendedClustersWrapperType<T = void> = ({
  rcmBlockType,
  recCount,
  sessionID,
  clientTimeout,
  excludedItems,
}: {
  rcmBlockType: RCM_BLOCK_TYPE;
  recCount?: number;
  sessionID?: string;
  clientTimeout?: number;
  excludedItems?: ClusterData['id'][];
}) => (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
) => Promise<T>;

/**
 * Упрощенный интерфейс для получения списка рекомендованных кластеров
 * @param rcmBlockType - алиас, под которым хранится BlockID в сторе
 * @param recCount - необходимое количество рекомендашек
 * @param sessionID - id сесси общения с рекомендами
 * @param excludedItems - исключенные из выдачи rcm кластера, если есть потребность настраивать исключение отдельно
 */
export const fetchRecommendedClustersWrapper: FetchRecommendedClustersWrapperType<
  APIResponse<ATRecommender | null>
> =
  ({ rcmBlockType, recCount, sessionID, excludedItems }) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    const state = getState();

    const isBot = selectIsBot(state);
    const isMobile = selectIsMobile(state);
    const pageName = selectPageName(state);

    const excludedCount = isMobile
      ? TOP_NEWS_EXCLUDED_COUNT_MOBILE
      : TOP_NEWS_EXCLUDED_COUNT;

    const mobileLimit = isBot
      ? DEFAULT_REC_CLUSTERS_COUNT_FOR_BOT_MOB
      : DEFAULT_REC_CLUSTERS_COUNT_MOB;

    // Исключаем из выдачи рек. системы топовые новости (если такие есть)
    const itemExcludedIds =
      excludedItems ??
      getExcludedItems({
        state,
        count: excludedCount,
        page: pageName,
      });

    return dispatch(
      fetchRecommendedClusters({
        rcmBlockType,
        clusterId: null,
        limit: recCount || (isMobile ? mobileLimit : REC_CLUSTERS_COUNT_DESK),
        itemExcludedIds,
        sessionID,
      }),
    );
  };
