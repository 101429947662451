/* eslint-disable sonarjs/no-duplicate-string */
/**
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=37339005
 * До вызова скриптов загрузки или перезагрузки всех рекламных мест,
 * необходимо вызывать скрипт удаления баннера брендирования.
 *
 * ИСПОЛЬЗОВАТЬ ТОЛЬКО НА КЛИЕНТЕ.
 */
export const brandingCleaner = () => {
  if (__SERVER__) {
    console.error(
      'Функция brandingCleaner должна использоваться только НА КЛИЕНТЕ',
    );

    return;
  }

  document.body.classList.remove('with-branding');
  document.body.classList.remove('with-mini-billboard');

  if (typeof window['DSP-Branding'] === 'undefined') {
    return;
  }

  const banners = Object.keys(window['DSP-Branding'].banners);

  if (banners.length > 0) {
    banners.forEach((key) => {
      window['DSP-Branding'].banners[key].removeBanner();
    });
  }
};
