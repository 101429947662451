/* eslint-disable sonarjs/no-duplicate-string */
import { CURRENCY_CHAR_CODE } from 'config/constants/finance';

export const CROSS_CURRENCIES: CurrencyType[] = [
  {
    accusativeSingular: 'мексиканское песо',
    gen: 'Мексиканских песо',
    charCode: CURRENCY_CHAR_CODE.MXN,
    gender: 'm',
    genitivePlural: 'мексиканских песо',
    genitiveSingular: 'мексиканское песо',
    genitiveSingularCorrect: 'мексиканское США',
    id: 17,
    nom: 'Мексиканское песо',
    nominal: 1,
    nominalAccusative: 'один мексиканский песо',
    nominativeSingular: 'мексиканское песо',
    prepositionalPlural: 'мексиканских песо',
  },
  {
    accusativeSingular: 'новозеландский доллар',
    charCode: CURRENCY_CHAR_CODE.NZD,
    gen: 'Новозеландских доллара',
    gender: 'm',
    genitivePlural: 'новозеландских долларов',
    genitiveSingular: 'новозеландских доллара',
    genitiveSingularCorrect: 'новозеландских доллара',
    id: 35,
    nom: 'Новозеландский доллар',
    nominal: 1,
    nominalAccusative: 'один новозеландский доллар',
    nominativeSingular: 'новозеландский доллар',
    prepositionalPlural: 'новозеландских долларах',
  },
];
