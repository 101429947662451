/* eslint-disable unicorn/filename-case */
import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectRuid, selectRuntime } from 'common/redux/runtime/selectors';
import { YM_PARAMS_EVENT_TYPE, ymParams } from 'utils/counters/yaMetrika';

/**
 * Хук, что отправляет данные о юзере в Яндекс Метрику при старте страницы.
 */
export const useYMUserParams = () => {
  const runtime = useSelector(selectRuntime, shallowEqual);
  const ruid = useSelector(selectRuid);

  useEffect(() => {
    if (!ruid) return;

    ymParams({
      runtime,
      ymCommonOptions: {
        UserID: ruid,
      },
      eventType: YM_PARAMS_EVENT_TYPE.UserParams,
    });
  }, [ruid, runtime]);
};
