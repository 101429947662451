import { html as htmlTemplate, oneLineTrim } from 'common-tags';

import { COUNTERS } from 'config/constants/counters';
import { createNode } from 'utils/createNode';

const counters: CountersType & ResourcesCountersType =
  COUNTERS as ExtendedCountersType;

/**
 * Mediascope(TNS) счетчик
 * @param projectAlias - алиас проекта
 */
export const TNShit = (projectAlias: ProjectType['alias']) => {
  const randomValue = Math.floor(Math.random() * 999999999);

  createNode({
    tagName: 'img',
    src: `${COUNTERS.resources.counters.tns}${counters[projectAlias].tns}/${randomValue}`,
    isHidden: true,
  });
};

type InitCounterPropsType = {
  account: string;
  tmsec: string;
};

/**
 * Инициализация счетчика Медиаскопа
 * https://jira.rambler-co.ru/browse/NEWS-11726
 * @param props.account - имя аккаунта для счетика;
 * @param props.tmsec - имя для раздела сайта.
 */
const initCounter = ({ account, tmsec }: InitCounterPropsType) =>
  oneLineTrim(htmlTemplate`
  <script type="text/javascript">
    (function(win, doc, cb){
      (win[cb] = win[cb] || []).push(function() {
        try {
          tnsCounterXXXXXX = new Mediascope.TnsCounter({
            'account': ${account},
            'tmsec': ${tmsec}
          });
        } catch(e){}
      });

      var tnsscript = doc.createElement('script');
      tnsscript.type = 'text/javascript';
      tnsscript.async = true;
      tnsscript.src = ('https:' == doc.location.protocol ? 'https:' : 'http:') +
        '//www.tns-counter.ru/tcounter.js';
      var s = doc.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(tnsscript, s);
    })(window, this.document,'tnscounter_callback');
  </script>`);

/**
 * Генератор скрипта для счетчика Медиаскопа для случаев, когда у юзера отключены скрипты.
 * https://jira.rambler-co.ru/browse/NEWS-11726
 * @param props.account - имя аккаунта для счетика;
 * @param props.tmsec - имя для раздела сайта.
 */
const noScriptWatcher = ({ account, tmsec }: InitCounterPropsType) =>
  oneLineTrim(htmlTemplate`
    <noscript>
      <img src="//www.tns-counter.ru/V13a****${account}/ru/UTF-8/tmsec=${tmsec}/" width="0" height="0" alt="" />
    </noscript>
  `);

/**
 * Инициализация счетчиков Медиаскопа.
 */
export const initMediascope = () => {
  const countersScripts: string[] = [
    ...COUNTERS.resources.counters.mediascope.map(({ account, tmsec }) =>
      initCounter({ account, tmsec }),
    ),
    ...COUNTERS.resources.counters.mediascope.map(({ account, tmsec }) =>
      noScriptWatcher({ account, tmsec }),
    ),
  ];

  return oneLineTrim(htmlTemplate`${countersScripts.join('')}`);
};
