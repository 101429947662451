import { createEntityAdapter } from '@reduxjs/toolkit';

/**
 * Адаптер городов для путешествий к типу [alias города]: { данные по городу }
 */
export const travelCitiesAdapter = createEntityAdapter<
  TravelRegionType,
  string
>({
  selectId: (city) => city.alias,
});
