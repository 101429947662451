import loadable from '@loadable/component';
import React from 'react';

import { fetchTravelCityInfo } from 'common/redux/commonData/travelCities';
import { fetchTravelCountries } from 'common/redux/commonData/travelCountries';
import { fetchTravelRegionPlaces } from 'common/redux/commonData/travelRegionPlaces';
import {
  setPageCityAlias,
  setPageCityPlaces,
  setPageCountryAlias,
} from 'common/redux/pages/guideCityPlaces';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const GuideCityPlacesDesktop = loadable(
  () => import('desktop/pages/GuideCityPlaces'),
);
const GuideCityPlacesMobile = loadable(
  () => import('mobile/pages/GuideCityPlaces'),
);

/**
 * Роут Путеводителя по местам одного города.
 *
 * https://travel.rambler.ru/guide/Russia/Moscow/places/
 */
export const guideCityPlacesRoutes = (
  isMobile: Runtime['settings']['isMobile'],
): IAppRoute<{ countryAlias: string; cityAlias: string }> => ({
  name: PAGE_TYPE.guideCityPlaces,
  exact: true,
  path: '/guide/:countryAlias([-A-Za-z]+)/:cityAlias([-A-Za-z]+)/places/',
  render: isMobile
    ? () => <GuideCityPlacesMobile />
    : () => <GuideCityPlacesDesktop />,
  fetchData: async ({ dispatch }, { params: { countryAlias, cityAlias } }) => {
    const callback = (data: TravelPlaceType[]) => {
      const placesIds = data.map(({ id }) => id);

      dispatch(setPageCityPlaces(placesIds));
    };

    dispatch(setPageCityAlias(cityAlias));
    dispatch(setPageCountryAlias(countryAlias));

    const promiseList: PromiseListType = [
      // Список стран для селекта в виджете
      dispatch(fetchTravelCountries()),
      // Получение информации о текущем городе
      dispatch(fetchTravelCityInfo({ regionAlias: cityAlias })),
      // Карточки мест текущего города
      dispatch(fetchTravelRegionPlaces({ regionAlias: cityAlias, callback })),
    ];

    const GuideCityPlaces = isMobile
      ? GuideCityPlacesMobile
      : GuideCityPlacesDesktop;

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: GuideCityPlaces,
        key: Object.keys({ GuideCityPlaces })[0],
      }),
    );
  },
});
