import { createAsyncThunk } from '@reduxjs/toolkit';

import { getNewsByTopic } from 'api';
import {
  selectApiConfig,
  selectDomainConfig,
  selectProjectId,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { TOPIC_ALIAS_VIDEO } from 'config/constants/topics';
import { adaptClusterToCard } from 'utils/adapters';

import { upsertEntries } from '../../entries';
import { selectTopicIdByAlias } from '../../topics/selectors';

/**
 * Функция загрузки кластера-видеовиджета.
 */
export const fetchVideoForWidget = createAsyncThunk(
  'widgets/fetchVideo',
  async (_props, { getState, dispatch }) => {
    /* Использовать аккуратно - стейт перестает динамически обновляться */
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectId = selectProjectId(state);
    const topicVideoId = selectTopicIdByAlias(
      TOPIC_ALIAS_VIDEO,
      projectId,
    )(state);

    if (!topicVideoId) {
      throw new Error(`Отсутствует topicVideoId: ${topicVideoId}`);
    }

    const { data, error } = await getNewsByTopic({
      apiConfig,
      topicId: topicVideoId,
      onlyMainTopic: false,
      limit: 10,
    });

    const firstCluster = data?.clusters?.[0];

    if (error || !firstCluster) {
      throw error;
    }

    dispatch(
      upsertEntries([
        adaptClusterToCard({ cluster: firstCluster, domainConfig, variables }),
      ]),
    );

    return String(data.clusters?.[0]?.id);
  },
);
