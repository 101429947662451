import { withSentryRouting } from '@sentry/react';
import { Route } from 'react-router-dom';

/**
 * Обертка Sentry над компонентом роута.
 * Нужна для функционирования Sentry.
 */
const SentryRoute = withSentryRouting(Route);

export { SentryRoute };
