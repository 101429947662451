export const ONE_DAY = 1000 * 60 * 60 * 24;

/**
 * Конфиг с параметрами дат для запроса валют за период.
 * Через функцию изза бага обновления даты. НЕ ИСПОЛЬЗОВАТЬ new Date() вне функций
 *
 * start - начало периода;
 * end - конец периода;
 * period - объект с обозначениями периодов для разных инструментов
 * (для cbr бесполезно, там работают только start и end нормально).
 */
export const generateDateConfig = () => {
  const today = new Date().getTime();
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();

  const todayString = new Date(year, month, day).toDateString();
  const todayStart = new Date(year, month, day).getTime();
  const yesterdayString = new Date(todayStart - ONE_DAY).toDateString();

  return {
    today,
    year,
    month,
    day,

    todayString,
    todayStart,
    yesterdayString,

    periodOptions: {
      day: {
        end: Math.round(today / 1000),
        start: Math.round(todayStart / 1000),
        period: {
          cbr: '1',
          bcs: '60',
          moex: '60',
          forex: 'h1',
        },
      },
      threeDays: {
        end: Math.round(today / 1000),
        start: Math.round((today - ONE_DAY * 3) / 1000),
        period: {
          cbr: '1',
          bcs: '60',
          moex: '60',
          forex: 'h1',
        },
      },
      week: {
        end: Math.round(today / 1000),
        start: Math.round((today - ONE_DAY * 7) / 1000),
        period: {
          cbr: '1',
          bcs: 'D',
          moex: 'D',
          forex: 'D1',
        },
      },
      month: {
        end: Math.round(today / 1000),
        start: Math.round((today - ONE_DAY * 30) / 1000),
        period: {
          cbr: '1',
          bcs: 'D',
          moex: 'D',
          forex: 'D1',
        },
      },
      quarter: {
        end: Math.round(today / 1000),
        start: Math.round((today - ONE_DAY * 30 * 4) / 1000),
        period: {
          cbr: '5',
          bcs: 'W',
          moex: 'W',
          forex: 'W',
        },
      },
      year: {
        end: Math.round(today / 1000),
        start: Math.round((today - ONE_DAY * 30 * 12) / 1000),
        period: {
          cbr: '22',
          bcs: 'W',
          moex: 'W',
          forex: 'W1',
        },
      },
      all: {
        end: Math.round(today / 1000),
        start: Math.round(new Date(2010, 0, 1).getTime() / 1000),
        period: {
          cbr: '264',
          bcs: 'M',
          moex: 'M',
          forex: 'M1',
        },
      },
    },
  };
};
