import { selectWidgets } from '../selectors';

export const selectWidgetsConsensus = (state: IAppState) =>
  selectWidgets(state).consensusWidget;

export const selectWidgetsConsensusCurrency = (state: IAppState) =>
  selectWidgetsConsensus(state).currency;

export const selectWidgetsConsensusForecaster = (state: IAppState) =>
  selectWidgetsConsensus(state).forecaster;

export const selectWidgetsConsensusForecasts = (state: IAppState) =>
  selectWidgetsConsensus(state).forecasts;
