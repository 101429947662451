import { html as htmlTemplate, oneLineTrim } from 'common-tags';

import {
  selectIsMobile,
  selectProjectAlias,
  selectSplits,
  selectUserId,
} from 'common/redux/runtime/selectors';

import { COMMON_COUNTER_ID } from './constants';
import { createCountersParams, getCountersIDs } from './utils';

const WEBVISOR_PERCENT = 0.05;

/**
 * Про параметры и сплиты в комментах: https://yandex.ru/blog/metrika-club/a-b-testirovanie-elementov-dizayna
 * Дока по методам старого счетчика: https://yandex.ru/support/metrica/objects/_method-reference.html
 * Стоит старый счетчик (https://mc.yandex.ru/metrika/watch.js)
 */

/**
 * Инициализация скрипта Я.Метрики.
 */
export const initScript = () =>
  oneLineTrim(htmlTemplate`
    <script>
      (function(m,e,t,r,i,k,a){
        m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),
        a=e.getElementsByTagName(t)[0],
        k.async=1,k.src=r,
        a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    </script>
  `);

type InitCounterType = {
  counterId: number | string;
  params: string;
  hasWebvisor: boolean;
};

/**
 * Генератор скрипта инициализации счетчика.
 * @param counterId - ID счетчика;
 * @param params - параметры визита.
 * @param hasWebvisor - включать ли вебвизор, карту скроллинга, аналитику форм
 */
const initCounter = ({ counterId, params, hasWebvisor }: InitCounterType) =>
  oneLineTrim(htmlTemplate`
    <script>
      window.ym("${counterId}", "init", {
        id: "${counterId}",
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        triggerEvent: true,
        webvisor: ${String(hasWebvisor)},
        params: ${params},
      });
    </script>
  `);

/**
 * Генератор скрипта для я метрики для счетчика для случаев, когда у юзера отключены скрипты.
 * @param counterId - id счетчика, для которого принудительно включается отслеживание.
 */
const noScriptWatcher = (counterId: number | string) =>
  oneLineTrim(htmlTemplate`
    <noscript><img src="https://mc.yandex.ru/watch/${counterId}" style="position:absolute; left:-9999px;" alt="" /></noscript>
  `);

/**
 * Строковое представление скриптов.
 * https://yandex.ru/support/metrica/code/counter-initialize.html
 * @param projectAlias - алиас проекта
 * @param isMobile - тип приложения (мобильное/десктопное)
 * @param splits - объект сплитов
 * @param pageName - имя страницы
 * @param rccid - rsid пользователя
 */

type YaCountersScripts = {
  projectAlias: ProjectType['alias'];
  isMobile: SettingsType['isMobile'];
  splits: DebuggerConfigType['splits'];
  rccid: Runtime['userId'];
  state: IAppState;
};

const yaCountersScripts = ({
  projectAlias,
  isMobile,
  state,
}: YaCountersScripts): string => {
  const countersIDs = getCountersIDs({
    projectAlias,
  });

  const params = JSON.stringify(
    createCountersParams({
      isMobile,
      state,
    }),
  );

  const countersScripts: string[] = [
    initScript(),
    ...[COMMON_COUNTER_ID, ...countersIDs].map((counterId) => {
      const hasWebvisor =
        COMMON_COUNTER_ID === String(counterId) &&
        Math.random() < WEBVISOR_PERCENT;

      return initCounter({ counterId, params, hasWebvisor });
    }),
    ...[COMMON_COUNTER_ID, ...countersIDs].map((counterId) =>
      noScriptWatcher(counterId),
    ),
  ];

  return oneLineTrim(htmlTemplate`${countersScripts.join('')}`);
};

/**
 * Про параметры и сплиты в комментах: https://yandex.ru/blog/metrika-club/a-b-testirovanie-elementov-dizayna
 * Дока по методам старого счетчика: https://yandex.ru/support/metrica/objects/_method-reference.html
 * Стоит старый счетчик (https://mc.yandex.ru/metrika/watch.js)
 */

type InitYaMetrikaType = {
  runtime: Runtime;
  appController: AppController;
  isClusterAdvertisingExperiment?: boolean;
};

/**
 * Вставка скрипта загрузки и инициализации счетчиков yaMetriks.
 * @param runtime - объект конфигурации приложения;
 * @param isClusterAdvertisingExperiment - флаг эксперимента с рекламной сеткой мобильного кластера.
 */
export const initYaMetrika = ({
  runtime,
  appController,
}: InitYaMetrikaType) => {
  const stateRuntime = { runtime, appController } as IAppState;

  const projectAlias = selectProjectAlias(stateRuntime);
  const isMobile = selectIsMobile(stateRuntime);
  const userId = selectUserId(stateRuntime);
  const splits = selectSplits(stateRuntime);

  const countersStr = yaCountersScripts({
    projectAlias,
    isMobile,
    splits,
    rccid: userId,
    state: stateRuntime,
  });

  return oneLineTrim(htmlTemplate`
    ${countersStr}
  `);
};
