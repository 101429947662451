/**
 * Класс ошибки по таймауту
 */
export class TimeoutError extends Error {
  static additionalMessage = '[TIMEOUT ERROR]';

  static className = 'TimeoutError';

  /**
   * Защитник, отделяющий обычные ошибки от ошибки типа таймаута.
   * @param error - объект ошибки.
   */
  static isTimeoutError = (error: Error) =>
    TimeoutError.className === error.name;

  /**
   * Защитник, отделяющий обычные ошибки от ошибки типа таймаута
   * по переданному сообщению ошибки.
   * @param errorMessage - объект сообщения ошибки.
   */
  static isTimeoutMessage = (errorMessage: Error['message']) =>
    errorMessage && errorMessage.includes(TimeoutError.additionalMessage);

  constructor(message: string, status?: number) {
    super(`${TimeoutError.additionalMessage} ${message}`);
    this.name = TimeoutError.className;
    this.status = status ?? 0;
  }

  status: number;
}

type PromiseTimeoutProps<T> = {
  // Количество миллисекунд, после которых промис будет считаться отмененным
  ms: number;
  // Промис, который ограничен по времени
  promise: Promise<T>;
  // Урл, по которому может запрашиваться указанный таймаут.
  entrypoint?: string;
};

/**
 * Обертка над промисом, которая обрывает его в случае отключения по таймауту.
 * @param ms - количество миллисекунд, после которых промис будет считаться отмененным;
 * @param promise - промис, который ограничен по времени;
 * @param onTimeout - коллбек, срабатывающий при таймауте промиса.
 *  Например, через него можно сделать отмену промиса.
 * @param entrypoint - * урл, по которому может запрашиваться указанный таймаут.
 */
export const promiseTimeout = <T>({
  ms,
  promise,
  entrypoint = 'someEntrypoint',
}: PromiseTimeoutProps<T>): Promise<T> => {
  const timeout = new Promise<T>((_resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new TimeoutError(`Timed out in ${ms} ms in ${entrypoint}`));
    }, ms);
  });

  return Promise.race([promise, timeout]);
};

/**
 * Безопасная версия promiseTimeout.
 * Несет внутри себя обработчики таймаута и других ошибок и приводит данны к типу даты и ошибки.
 * @param props - данные для promiseTimeout, @see PromiseTimeoutProps
 */
export const safePromiseTimeout = <T>(
  props: PromiseTimeoutProps<T>,
): Promise<{ res: T | null; error: Error | null }> =>
  promiseTimeout<T>(props)
    .then((_res) => ({ res: _res, error: null }))
    .catch((error) => ({ res: null, error }));
