import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchAutotagTop } from './asyncs';

type StateAutotagTopType = {
  alias: AUTOTAG_TYPE.person | AUTOTAG_TYPE.organization;
  puids: PuidsType;
  tags: ATAutotagPopular[];
} & Fetchable;

const autotagTopSlice = createSlice({
  name: PAGE_TYPE.autotagTop,
  initialState: {
    alias: '' as AUTOTAG_TYPE,
    puids: {},
    tags: [],
    fetching: false,
  } as StateAutotagTopType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setAutotagTopPuids: (state, action: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_tag`.toUpperCase(),
      };
    },

    /**
     * Установка алиаса для страницы топа.
     * @param action.payload - алиас для страницы топа.
     */
    setAutotagTopAlias: (
      state,
      action: PayloadAction<AUTOTAG_TYPE.person | AUTOTAG_TYPE.organization>,
    ) => {
      state.alias = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutotagTop.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchAutotagTop.fulfilled, (state, { payload }) => {
        state.tags = payload;
        state.fetching = false;
      })
      .addCase(fetchAutotagTop.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const autotagTopReducer = autotagTopSlice.reducer;

export const { setAutotagTopPuids, setAutotagTopAlias } =
  autotagTopSlice.actions;
