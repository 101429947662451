export type TopProjectWidgetType = {
  id: ProjectType['id'] | TAG_WIDGET;
  clusterIds: CardData['id'][] | null;
} & Fetchable;

export enum TAG_WIDGET {
  HotNews = 'hotnews',
  Mnenia = 'mnenia',
}

export type FetchHotNewsWidgetClustersType = {
  projectId: ProjectType['id'];
  widgetType: TAG_WIDGET;
  withExperts?: boolean;
};

export type DataFetcherPropsType =
  | {
      projectId: ProjectType['id'];
      topId: TopType;
    }
  | {
      topicId: ATTopic['id'];
    };

export type FetchTopProjectWidgetType = {
  excludeTopicsIds?: TopicType['alias'][];
  limit?: number;
} & DataFetcherPropsType;
