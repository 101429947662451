import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCrossRate } from 'api';
import { getApiCrossRateData } from 'api/adapters';
import { selectApiConfig } from 'common/redux/runtime/selectors';

import { CrossDescriptionType, CrossRateType } from './typings';
import { getCrossCurrencies } from './utils';

/**
 * Функция загрузки кросс-курса валют.
 */
export const fetchCrossRate = createAsyncThunk(
  'widgets/fetchCrossRate',
  async (_props, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data, error } = await getCrossRate(apiConfig);

    if (error || !data) {
      throw error || new Error('Ошибка при получении кросс-курса валют');
    }

    const crossRates = getApiCrossRateData(data);

    const descriptions = crossRates.reduce((acc, crossRate) => {
      acc[crossRate.charCode] = { description: crossRate.description };

      return acc;
    }, {} as CrossDescriptionType);

    const charCodes = crossRates.map((crossRate) => crossRate.charCode);

    const rates = charCodes.reduce((acc, charCode) => {
      const { firstCharCode, secondCharCode } = getCrossCurrencies(charCode);

      acc[firstCharCode] =
        acc[firstCharCode] !== undefined
          ? [...acc[firstCharCode], secondCharCode]
          : [secondCharCode];

      return acc;
    }, {} as CrossRateType);

    return { data: crossRates, rates, descriptions };
  },
);
