/**
 * Адаптер для ручного тега.
 * @param tag - недаптированный объект ручного тега.
 */
export const createManualTagData = (tag: APITagInfo): ManualTagData => ({
  id: tag?.id || -1,
  projectId: tag?.project_id || 1,
  alias: tag?.alias || '',
  title: tag?.text || '',
});
