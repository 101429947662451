import { PayloadAction } from '@reduxjs/toolkit';

import { getTravelPlace, getTravelPlaces } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { createSlice } from 'common/redux/utils';

import { travelRegionPlacesAdapter } from './adapter';

const LIMIT_DEFAULT = 100;
const PAGE_DEFAULT = 1;
const INITIAL_STATE = travelRegionPlacesAdapter.getInitialState({
  fetching: false,
  error: '',
});

type FetchTravelRegionPlacesType = {
  regionAlias: string;
  callback?: (data: TravelPlaceType[]) => void;
  limit?: 10 | 15 | 20 | 25 | 30 | 40 | 50 | 100;
  page?: number;
};

/**
 * Слайс с местами для страниц путешествий.
 */
const travelRegionPlacesSlice = createSlice({
  name: 'travelRegionPlaces',
  initialState: INITIAL_STATE,
  reducers: (create) => ({
    /**
     * Обновление списка мест для региона тк изначально может быть не полный список
     */
    setRegionPlaces: create.reducer(
      (state, action: PayloadAction<TravelPlaceType>) => {
        travelRegionPlacesAdapter.upsertOne(state, action.payload);
      },
    ),

    /**
     * Функция загрузки мест для путешествий.
     */
    fetchTravelRegionPlaces: create.asyncThunk(
      async (
        { regionAlias, limit, page, callback }: FetchTravelRegionPlacesType,
        { getState },
      ) => {
        const apiConfig = selectApiConfig(getState() as IAppState);

        const { data, error } = await getTravelPlaces(apiConfig, {
          limit: limit || LIMIT_DEFAULT,
          page: page || PAGE_DEFAULT,
          regionAlias,
        });

        if (error || !data) {
          throw error || new Error('Ошибка при получении списка мест');
        }

        if (callback) {
          callback(data);
        }

        return { data };
      },

      {
        pending: (state) => {
          state.fetching = true;
          state.error = '';
        },
        fulfilled: (state, { payload }) => {
          travelRegionPlacesAdapter.upsertMany(state, payload.data);
          state.error = '';
        },
        rejected: (state, { error }) => {
          state.error = error.message || '';
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),

    /**
     * Функция загрузки конкретного места.
     * @param pointId - id места.
     */
    fetchTravelRegionPlace: create.asyncThunk(
      async ({ pointId }: { pointId: string }, { getState }) => {
        const apiConfig = selectApiConfig(getState() as IAppState);

        const { data, error } = await getTravelPlace(apiConfig, pointId);

        if (error || !data) {
          throw error || new Error('Ошибка при получении места');
        }

        return { data };
      },

      {
        pending: (state) => {
          state.fetching = true;
          state.error = '';
        },
        fulfilled: (state, { payload }) => {
          travelRegionPlacesAdapter.upsertOne(state, payload.data);
          state.error = '';
        },
        rejected: (state, { error }) => {
          state.error = error.message || '';
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),
  }),
});

export const {
  setRegionPlaces,
  fetchTravelRegionPlaces,
  fetchTravelRegionPlace,
} = travelRegionPlacesSlice.actions;

export const travelRegionPlacesReducer = travelRegionPlacesSlice.reducer;
