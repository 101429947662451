import loadable from '@loadable/component';
import React from 'react';

import { fetchTravelCities } from 'common/redux/commonData/travelCities';
import { fetchTravelCountries } from 'common/redux/commonData/travelCountries';
import {
  setCityAlias,
  setCountryAlias,
  setGuideContentPagePuids,
} from 'common/redux/pages/guideContentPage';
import { getPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const GuideCityPageDesktop = loadable(() => import('desktop/pages/GuideCity'));
const GuideCityPageMobile = loadable(() => import('mobile/pages/GuideCity'));

type GuideCityMatchRoute = {
  countryAlias: string;
  cityAlias: string;
};

/**
 * Роут контентной страницы Города.
 * https://travel.rambler.ru/guide/Russia/Moscow/#1
 */
export const guideCityRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<GuideCityMatchRoute> => ({
  name: PAGE_TYPE.guideCity,
  exact: true,
  path: '/guide/:countryAlias([-A-Za-z]+)/:cityAlias([-A-Za-z]+)/',
  render: isMobile
    ? () => <GuideCityPageMobile />
    : () => <GuideCityPageDesktop />,
  fetchData: async ({ dispatch }, { params }) => {
    const pageDownloaderParams = isMobile
      ? ([GuideCityPageMobile, 'GuideCityPageMobile'] as const)
      : ([GuideCityPageDesktop, 'GuideCityPageDesktop'] as const);
    const { countryAlias, cityAlias } = params;

    dispatch(setCityAlias(cityAlias));
    dispatch(setCountryAlias(countryAlias));
    dispatch(setGuideContentPagePuids(PAGE_TYPE.guideCity));

    await dispatch(fetchTravelCountries());
    await dispatch(fetchTravelCities({ regionAlias: countryAlias }));
    await getPageDownloader(pageDownloaderParams[0], pageDownloaderParams[1]);
  },
});
