import path from 'path';

import * as extestmProdConfig from 'config/profiles/extestmProd';
import * as extestmTestConfig from 'config/profiles/extestmTest';
import * as localConfig from 'config/profiles/local';
import * as releaseConfig from 'config/profiles/release';
import * as stage1ProdConfig from 'config/profiles/stage1Prod';
import * as stage1TestConfig from 'config/profiles/stage1Test';
import * as stage2ProdConfig from 'config/profiles/stage2Prod';
import * as stage2TestConfig from 'config/profiles/stage2Test';
import * as stage3ProdConfig from 'config/profiles/stage3Prod';
import * as stage3TestConfig from 'config/profiles/stage3Test';
import * as stage4ProdConfig from 'config/profiles/stage4Prod';
import * as stage4TestConfig from 'config/profiles/stage4Test';
import * as stage5ProdConfig from 'config/profiles/stage5Prod';
import * as stage5TestConfig from 'config/profiles/stage5Test';
import * as stage6ProdConfig from 'config/profiles/stage6Prod';
import * as stage6TestConfig from 'config/profiles/stage6Test';
import * as stageProdConfig from 'config/profiles/stageProd';
import * as stageTestConfig from 'config/profiles/stageTest';
import { Config } from 'typings/Config';
import { generateAppName } from 'utils/generateAppName';

import { BANNERS } from './constants/banner';
import { PROFILES } from './constants/configProfiles';

const { VERSION = 'local', APP_ENV } = process.env;

let STATS_FILE = '';

try {
  if (APP_ENV !== 'CI') {
    STATS_FILE = path.resolve('./build/loadable-stats.json');
  }
} catch {
  console.error('Ошибка чтения данных из loadable-stats.json');
}

// Объект локальных настроек для переопределения конфигурации сервера
let SETTINGS_LOCAL = {};

try {
  if (__DEV__) {
    SETTINGS_LOCAL = require('../settings-local.json');
  }
} catch {
  console.error('Ошибка чтения данных из settings-local.json');
}

const RUN_PROFILE: Config['RUN_PROFILE'] =
  (process.env.RUN_PROFILE as Config['RUN_PROFILE']) || 'local';

let profile = {};

if (RUN_PROFILE === PROFILES.stageTest) {
  profile = { ...stageTestConfig };
} else if (RUN_PROFILE === PROFILES.stageProd) {
  profile = { ...stageProdConfig };
} else if (RUN_PROFILE === PROFILES.stage1Test) {
  profile = { ...stage1TestConfig };
} else if (RUN_PROFILE === PROFILES.stage1Prod) {
  profile = { ...stage1ProdConfig };
} else if (RUN_PROFILE === PROFILES.stage2Test) {
  profile = { ...stage2TestConfig };
} else if (RUN_PROFILE === PROFILES.stage2Prod) {
  profile = { ...stage2ProdConfig };
} else if (RUN_PROFILE === PROFILES.stage3Test) {
  profile = { ...stage3TestConfig };
} else if (RUN_PROFILE === PROFILES.stage3Prod) {
  profile = { ...stage3ProdConfig };
} else if (RUN_PROFILE === PROFILES.stage4Prod) {
  profile = { ...stage4ProdConfig };
} else if (RUN_PROFILE === PROFILES.stage4Test) {
  profile = { ...stage4TestConfig };
} else if (RUN_PROFILE === PROFILES.stage5Test) {
  profile = { ...stage5TestConfig };
} else if (RUN_PROFILE === PROFILES.stage5Prod) {
  profile = { ...stage5ProdConfig };
} else if (RUN_PROFILE === PROFILES.stage6Test) {
  profile = { ...stage6TestConfig };
} else if (RUN_PROFILE === PROFILES.stage6Prod) {
  profile = { ...stage6ProdConfig };
} else if (RUN_PROFILE === PROFILES.extestmTest) {
  profile = { ...extestmTestConfig };
} else if (RUN_PROFILE === PROFILES.extestmProd) {
  profile = { ...extestmProdConfig };
} else if (RUN_PROFILE === PROFILES.release) {
  profile = { ...releaseConfig };
} else {
  profile = { ...localConfig };
}

export const config: Config = {
  APP_NAME: generateAppName(VERSION),
  RUN_PROFILE,
  VERSION,
  PROJECTS: {},
  TOPICS: {},
  BANNERS,
  STATS_FILE,
  ...profile,
  ...SETTINGS_LOCAL,
};
