import { createAsyncThunk } from '@reduxjs/toolkit';

import rubleData from '@mocks/api/currencies/rubleData.json';
import {
  getCurrenciesDataFromCbr,
  getCurrenciesRatesFromCbr,
  getCurrencyDataFromCbr,
  getCurrencyRatesFromOuterSources,
} from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';
import { PERIODS } from 'config/constants/finance';

import { CURRENCY_SOURCES } from '../widgets/exchangeRatesWidget/typings';

import { generateDateConfig } from './config';
import { CROSS_CURRENCIES } from './constants';

/**
 * Функция загрузки основной информации о валютах.
 */
export const fetchCurrenciesInfo = createAsyncThunk(
  'fetchCurrenciesInfo',
  async (_props, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: currenciesInfo, error } =
      await getCurrenciesDataFromCbr(apiConfig);

    if (error || !currenciesInfo) {
      throw error || new Error('Ошибка при получении информации о валютах');
    }

    return [...CROSS_CURRENCIES, ...currenciesInfo];
  },
);

type FetchCurrenciesRatesFromPeriod = {
  period: PERIODS;
};

/**
 * Функция загрузки основной информации о котировках валют за определенный период.
 * @param props.start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param props.end - конец временного отрезка (в секундах).
 */
export const fetchCurrenciesRatesFromPeriod = createAsyncThunk(
  'fetchCurrenciesRatesFromPeriod',
  async ({ period }: FetchCurrenciesRatesFromPeriod, { getState }) => {
    const { periodOptions } = generateDateConfig();

    const { start, end } = periodOptions[period] || {};

    if (!start || !end) {
      throw new Error(
        'Для запроса необходимо передать корректные временные отрезки',
      );
    }

    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: currenciesRates, error } = await getCurrenciesRatesFromCbr({
      apiConfig,
      start,
      end,
    });

    if (error || !currenciesRates) {
      throw (
        error || new Error('Ошибка при получении информации о котировках валют')
      );
    }

    const rubleValues = currenciesRates[1].map(({ dt }) => ({ dt, rate: 1 }));

    return { ...currenciesRates, [rubleData.id]: rubleValues };
  },
);

/**
 * Функция загрузки основной информации о котировках валют.
 * @param props.start - начало временного отрезка, за который запрашиваем информацию (в секундах);
 * @param props.end - конец временного отрезка (в секундах).
 */
export const fetchCurrenciesRates = createAsyncThunk(
  'fetchCurrenciesRates',
  async (_props, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: currenciesRates, error } = await getCurrenciesRatesFromCbr({
      apiConfig,
    });

    if (error || !currenciesRates) {
      throw (
        error || new Error('Ошибка при получении информации о котировках валют')
      );
    }

    const rubleValues = currenciesRates[1].map(({ dt }) => ({ dt, rate: 1 }));

    return { ...currenciesRates, [rubleData.id]: rubleValues };
  },
);

/**
 * Функция загрузки основной информации о конкретной валюте.
 * @param props.charCode - код валюты, о которой хотим получить информацию.
 */
export const fetchCurrencyInfo = createAsyncThunk(
  'fetchCurrencyInfo',
  async (
    { charCode }: { charCode: CurrencyType['charCode'] },
    { getState },
  ) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: currency, error } = await getCurrencyDataFromCbr({
      apiConfig,
      charCode,
    });

    if (error || !currency) {
      throw (
        error ||
        new Error(`Ошибка при получении информации о валюте ${charCode}`)
      );
    }

    return currency;
  },
);

type FetchCurrencyRatesFromPeriod = {
  charCode: CurrencyType['charCode'];
  period: PERIODS;
  source?: CURRENCY_SOURCES;
};

/**
 * Функция загрузки основной информации о котировках конкретной валюты за период.
 * @param props.charCode - код валюты, о которой хотим получить информацию;
 * @param props.period - название периода из PERIODS.
 */
export const fetchCurrencyRatesFromPeriod = createAsyncThunk(
  'fetchCurrencyRatesFromPeriod',
  async (
    { charCode, source, period }: FetchCurrencyRatesFromPeriod,
    { getState },
  ) => {
    const { periodOptions } = generateDateConfig();
    const { start, end } = periodOptions[period] || {};

    if (!start || !end) {
      throw new Error(
        'Для запроса необходимо передать корректные временные отрезки',
      );
    }

    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: currencyRates, error } =
      await getCurrencyRatesFromOuterSources({
        apiConfig,
        charCode,
        source,
        start,
        end,
      });

    if (error || !currencyRates) {
      throw (
        error ||
        new Error(
          `Ошибка при получении информации о котировках валюты ${charCode}`,
        )
      );
    }

    return { currencyRates, period };
  },
);

/**
 * Функция загрузки основной информации о котировках конкретной валюты.
 * @param props.charCode - код валюты, о которой хотим получить информацию;
 */
export const fetchCurrencyRates = createAsyncThunk(
  'fetchCurrencyRates',
  async (
    { charCode }: { charCode: CurrencyType['charCode'] },
    { getState },
  ) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: currencyRates, error } =
      await getCurrencyRatesFromOuterSources({
        apiConfig,
        charCode,
      });

    if (error || !currencyRates) {
      throw (
        error ||
        new Error(
          `Ошибка при получении информации о котировках валюты ${charCode}`,
        )
      );
    }

    return currencyRates;
  },
);
