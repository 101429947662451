import { createAsyncThunk } from '@reduxjs/toolkit';

import { getResourceRnet } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

import { addEntries } from '../rnetEntries';

type FetchRnetWidgetType = {
  blockId: ATResourceRnetBlockInfo['blockId'];
  queryParams: string | null;
};

/**
 * Функция загрузки новостей для виджета "новости источника".
 * @param blockId - id для блока rnet;
 */
export const fetchRnetNews = createAsyncThunk(
  'widgets/fetchRnetNews',
  async (
    { blockId, queryParams }: FetchRnetWidgetType,
    { dispatch, getState },
  ) => {
    const { data, error } = await getResourceRnet({
      apiConfig: selectApiConfig(getState() as IAppState),
      blockId: String(blockId),
      queryParams,
    });

    if (error || !data) {
      throw error;
    }

    const cards = data[0].banners;

    dispatch(addEntries(cards));

    return cards.map(({ bannerId: id }) => id);
  },
);
