import { createSlice } from '@reduxjs/toolkit';

import { getTopicsFromMemoryCache } from 'config/staticData/topics';

import { topicsAdapter } from './adapter';
import {
  fetchAllTopics,
  fetchClustersByTopic,
  fetchTopicsByCluster,
} from './asyncs';
import { AliasToIdType } from './typings';
import { createAliasToId, createTopicToState } from './utils';

/**
 * Стейт топиков.
 * Топики не загружаются напрямую при непосредственном обращении, а лежат в объекте конфига,
 * который переодически обновляется. Поэтому важно перед использованием данного стейта вызвать initTopics.
 * @param entries - объект с ключ: id топика - значение: информация о топике;
 * @param fetching - флаг, что происходит загрузка хотя бы одного топика;
 * @param error - сообщение последней ошибки, произошедшей при загрузке топика.
 */
const topicsSlice = createSlice({
  name: 'topics',
  initialState: topicsAdapter.getInitialState({
    aliasToId: {} as AliasToIdType,
    fetching: false,
    error: '',
  }),
  reducers: {
    initTopics: (state, { payload: { projectId } }) => {
      if (!__SERVER__) {
        state.error = 'Топики невозможно назначить на клиенте';

        return;
      }

      const filteredTopics = getTopicsFromMemoryCache()?.byProjectID[projectId];

      if (!Array.isArray(filteredTopics)) return;

      topicsAdapter.addMany(state, filteredTopics.map(createTopicToState));
      state.aliasToId = filteredTopics.reduce(createAliasToId, {
        ...state.aliasToId,
      } as AliasToIdType);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTopics.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchTopicsByCluster.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchClustersByTopic.pending, (state) => {
        state.fetching = true;
      });

    builder
      .addCase(fetchAllTopics.fulfilled, (state, { payload: topics }) => {
        topicsAdapter.addMany(state, topics.map(createTopicToState));
        state.aliasToId = topics.reduce(createAliasToId, {} as AliasToIdType);
        state.fetching = false;
      })
      .addCase(fetchTopicsByCluster.fulfilled, (state) => {
        /**
         * Мы ничего не делаем с загруженными для кластера топиками,
         * так как данные там все равно урезанные и толку от них нет.
         */
        state.fetching = false;
      })
      .addCase(
        fetchClustersByTopic.fulfilled,
        (state, { payload: { topicId, clusterIds, hasNextPage } }) => {
          state.fetching = false;
          topicsAdapter.updateOne(state, {
            id: topicId,
            changes: { clusterIds, hasNextPage },
          });
        },
      );

    builder
      .addCase(fetchAllTopics.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message || '';
      })
      .addCase(fetchTopicsByCluster.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message || '';
      })
      .addCase(fetchClustersByTopic.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message || '';
      });
  },
});

export const topicsReducer = topicsSlice.reducer;
export const { initTopics } = topicsSlice.actions;
