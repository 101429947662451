import { useCallback } from 'react';

import { setAdblockEnabled } from 'common/redux/appController';
import { useAppDispatch } from 'store/hooks';

/**
 * Хук, возвращает callback, который определяет включен AdBlock или нет
 */
export const useAdblockDetect = () => {
  const dispatch = useAppDispatch();

  return useCallback((adblockEnabled: boolean) => {
    dispatch(setAdblockEnabled(adblockEnabled));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
