export enum CLUSTER_TYPE {
  news = 0,
  photo = 1,
  video = 2,
  article = 3,
  test = 4,
}

export enum CLUSTER_TYPE_ALIAS {
  news = 'News',
  photo = 'Photo',
  video = 'Video',
  article = 'Article',
  test = 'Test',
}

/**
 * Статусы кластера
 * @param toRemove - Скрыт. 404. Кластер скрыт, так как ссылка на оригинал (источник)
 * @param new - Скрыт. 404. Только созданные
 * @param baked - Активен
 * @param unknown2 - Скрыт. Древний статус
 * @param unknown3 - Скрыт. Древний статус
 * @param trash - Скрыт. Древний статус
 * @param hidden - Скрыт в админке
 * @param censored - Скрыт. Цензура
 * @param uncensored - Активен
 * @param noFulltext - Скрыт. Неполнотекстовые кластера. (!) Отказываемся
 */
export enum CLUSTER_STATUS {
  // 404
  toRemove = -1,
  // 404
  new = 0,
  // 200
  baked = 1,
  // 404
  unknown2 = 2,
  // 404
  unknown3 = 3,
  // 404
  trash = 4,
  // 410
  hidden = 5,
  // 410
  censored = 6,
  // 200
  uncensored = 7,
  // 410
  noFulltext = 8,
}

type ClusterNameType = 'news' | 'photo' | 'video' | 'article' | 'test';

export const CLUSTER_NAME_TYPES: Record<CLUSTER_TYPE, ClusterNameType> = {
  [CLUSTER_TYPE.news]: 'news',
  [CLUSTER_TYPE.photo]: 'photo',
  [CLUSTER_TYPE.video]: 'video',
  [CLUSTER_TYPE.article]: 'article',
  [CLUSTER_TYPE.test]: 'test',
};

export enum AUTOTAG_TYPE {
  person = 'person',
  organization = 'organization',
  media = 'media',
  category = 'category',
  games = 'games',
  movie = 'movie',
  auto = 'auto',
  region = 'region',
  city = 'city',
  country = 'country',
}

export const FILTER_AUTOTAG_TYPE: Record<AUTOTAG_TYPE, boolean> = {
  [AUTOTAG_TYPE.person]: false,
  [AUTOTAG_TYPE.organization]: false,
  [AUTOTAG_TYPE.media]: false,
  [AUTOTAG_TYPE.category]: false,
  [AUTOTAG_TYPE.games]: false,
  [AUTOTAG_TYPE.movie]: false,
  [AUTOTAG_TYPE.auto]: false,
  [AUTOTAG_TYPE.region]: true,
  [AUTOTAG_TYPE.city]: true,
  [AUTOTAG_TYPE.country]: true,
};

export enum CLUSTER_TOPIC_TAG_ID {
  other = 209,
  ukraine = 191,
  usa = 189,
  newsMoscow = 176,
}

export enum CLUSTER_AUTO_TAG_ID {
  ukraine = 135640,
  usa = 135650,
}

export enum CLUSTER_TAG_ID_TYPE {
  tragedy = 130049,
  death = 150633,
}

/**
 * Алиасы тегов мультикластера для разных вертикалей,
 * смотрим по алиасам или имени тега, т.к. id на стейджах и проде могут быть разные.
 */
export enum MULTICLUSTER_TAG_ALIASES {
  multiNews = 'multi-news',
  multiWoman = 'multi-woman',
  multiFinance = 'multi-finance',
  multiKino = 'multi-kino',
  multiTravel = 'multi-travel',
  multiWeekend = 'multi-weekend',
  multiDoctor = 'multi-doctor',
  multiSport = 'multi-sport',
  multiAuto = 'multi-auto',
}

export const MULTICLUSTER_TAG_NAME = 'Мультикластер';

/**
 * Если в display_type кластера есть данная подстрока, то кластер относится к типу "статья"
 */
export const ARTICLE_TAG_ALIAS = 'article';

/** Ограничитель количества новостей в ленте */
export const FEED_LENGTH = 5;

/** Если тип кластера статья то в бесконечке один кластер */
export const FEED_LENGTH_ARTICLE = 1;

/**
 * Ключевое слово с которого должно начинаться короткое описание кластера.
 * Если его нет, то короткое описание не отображаем.
 */
export const SHORT_BLOCK_SECRET_WORD = 's1ap7ha7boo7ych33ks';
