// NEWS-11896 Важно! Версии fullscreen_off и fullscreen_on используются на Рамблер/главная. На медиа они не меняют визуал и отображается дефолтная версия
export enum HUMAN_CENTERED_STRATEGY_SPLIT_VALUES {
  // дефолтная версия
  default = 'default',
  // контрольная группа для редизайна
  off = 'off',
  // редизайн включен, с первой новости
  on = 'on',
  // редизайн включен, с четвертой новости
  on_4 = 'on_4',
  // редизайн включен, пропуская новости 2 и 3
  on_1_4 = 'on_1_4',
  // контрольная группа для фулскрина, редизайн неактивен
  fullscreen_off = 'fullscreen_off',
  // фулскрин активен, редизайн неактивен
  fullscreen_on = 'fullscreen_on',
}

export enum SPLITS_NAMES {
  humanCenteredStrategy = 'humanCenteredStrategy',
}

export type SPLITS = Partial<{
  // Сплит с новым перезапуском
  [SPLITS_NAMES.humanCenteredStrategy]: HUMAN_CENTERED_STRATEGY_SPLIT_VALUES;
}>;
