export const HOROSCOPE_NAMES: { [key: string]: string } = {
  'tsivin-mihail': 'mihail',
  'pogrebinskiy-mihail': 'mihail',
  'saakashvili-mihail': 'mihail',
  'pshenichnyy-mihail': 'mihail',
  'chaplyga-mihail': 'mihail',
  'maslyakov-aleksandr': 'aleksandr',
  'sladkov-aleksandr': 'aleksandr',
  'lukashenko-aleksandr': 'aleksandr',
  'hodakovskiy-aleksandr': 'aleksandr',
  'rozenbaum-aleksandr': 'aleksandr',
  'dapirka-mariya': 'mariya',
  'shukshina-mariya': 'mariya',
  'zaharova-mariya': 'mariya',
  'mironova-mariya': 'mariya',
  'kamenskaya-mariya': 'mariya',
  'netrebko-anna': 'anna',
  'korsun-anna': 'anna',
  'koshmal-anna': 'anna',
  'kovalchuk-anna': 'anna',
  'semenovich-anna': 'anna',
  'zavorotnyuk-anastasiya': 'anastasiya',
  'volochkova-anastasiya': 'anastasiya',
  'makeeva-anastasiya': 'anastasiya',
  'spiridonova-anastasiya': 'anastasiya',
  'ivleeva-anastasiya': 'anastasiya',
  'trepova-darya': 'darya',
  'pereverzeva-darya': 'darya',
  'pynzar-darya': 'darya',
  'dontsova-darya': 'darya',
  'aslamova-darya': 'darya',
  'sheynin-artem': 'artem',
  'uss-artem': 'artem',
  'lebedev-artemiy': 'artem',
  'shulgin-artemiy': 'artem',
  'maslov-artem': 'artem',
  'gagarina-polina': 'polina',
  'dibrova-polina': 'polina',
  'dashkova-polina': 'polina',
  'maksimova-polina': 'polina',
  'milushkova-polina': 'polina',
  'chekalina-valeriya': 'arina',
  'sokolova-galina': 'galina',
  'kalina-isaak': 'arina',
  'somova-alina': 'arina',
  'zolina-galina': 'galina',
  'galkin-maksim': 'maksim',
  'shevchenko-maksim': 'maksim',
  'drozd-maksim': 'maksim',
  'shugaley-maksim': 'maksim',
  'fadeev-maksim': 'maksim',
  'surovikin-sergey': 'sergey',
  'sudarikov-sergey': 'sergey',
  'shoygu-sergey': 'sergey',
  'furgal-sergey': 'sergey',
  'mardan-sergey': 'sergey',
  'nagiev-dmitriy': 'dmitriy',
  'dibrov-dmitriy': 'dmitriy',
  'rogozin-dmitriy': 'dmitriy',
  'komarov-dmitriy': 'dmitriy',
  'gordon-dmitriy': 'dmitriy',
  'montyan-tatyana': 'tatyana',
  'mostyko-tatyana': 'tatyana',
  'lazareva-tatyana': 'tatyana',
  'doronina-tatyana': 'tatyana',
  'navka-tatyana': 'tatyana',
  'strizhenova-ekaterina': 'ekaterina',
  'varnava-ekaterina': 'ekaterina',
  'andreeva-ekaterina': 'ekaterina',
  'sokolenko-ekaterina': 'ekaterina',
  'klimova-ekaterina': 'ekaterina',
  'arshavina-alisa': 'alisa',
  'freyndlih-alisa': 'alisa',
  'teplyakova-alisa': 'alisa',
  'kazmina-alisa': 'alisa',
  'sher-alisa': 'alisa',
  'karaulov-andrey': 'andrey',
  'molochnyy-andrey': 'andrey',
  'malahov-andrey': 'andrey',
  'fursov-andrey': 'andrey',
  'viharev-andrey': 'andrey',
  'urgant-ivan': 'ivan',
  'belozertsev-ivan': 'ivan',
  'ohlobystin-ivan': 'ivan',
  'filiponenko-ivan': 'ivan',
  'krasko-ivan': 'ivan',
  'buzova-olga': 'olga',
  'skabeeva-olga': 'olga',
  'vasileva-olga': 'olga',
  'mirimskaya-olga': 'olga',
  'shelest-olga': 'olga',
  'dytsevich-kirill': 'kirill',
  'nabutov-kirill': 'kirill',
  'patriarh-kirill': 'kirill',
  'safonov-kirill': 'kirill',
  'kanahin-kirill': 'kirill',
  'rotaru-sofiya': 'sofiya',
  'evdokimenko-sofiya': 'sofiya',
  'sapega-sofiya': 'sofiya',
  'nizharadze-sofiya': 'sofiya',
  'tyurina-sofiya': 'sofiya',
  'novoselskaya-arina': 'arina',
  'sharapova-arina': 'arina',
  'borodina-arina': 'arina',
  'perchik-arina': 'arina',
  'sobolenko-arina': 'arina',
  'blinovskaya-elena': 'elena',
  'volosyuk-elena': 'elena',
  'malysheva-elena': 'elena',
  'vaenga-elena': 'elena',
  'vorobey-elena': 'elena',
  'zaluzhnyy-valeriy': 'valeriy',
  'leontev-valeriy': 'valeriy',
  'meladze-valeriy': 'valeriy',
  'solovey-valeriy': 'valeriy',
  'semin-valeriy': 'valeriy',
  'yabbarov-ilya': 'ilya',
  'yashin-ilya': 'ilya',
  'reznik-ilya': 'ilya',
  'kiva-ilya': 'ilya',
  'klebanov-ilya': 'ilya',
  'mihalkov-nikita': 'nikita',
  'presnyakov-nikita': 'nikita',
  'dzhigurda-nikita': 'nikita',
  'panfilov-nikita': 'nikita',
  'belyh-nikita': 'nikita',
  'prigozhin-evgeniy': 'evgeniy',
  'poddubnyy-evgeniy': 'evgeniy',
  'royzman-evgeniy': 'evgeniy',
  'petrosyan-evgeniy': 'evgeniy',
  'muraev-evgeniy': 'evgeniy',
  'panin-aleksey': 'aleksey',
  'arestovich-aleksey': 'aleksey',
  'viharev-aleksey': 'aleksey',
  'serebryakov-aleksey': 'aleksey',
  'navalnyy-aleksey': 'aleksey',
  'sobchak-kseniya': 'kseniya',
  'borodina-kseniya': 'kseniya',
  'rappoport-kseniya': 'kseniya',
  'larina-kseniya': 'kseniya',
  'shoygu-kseniya': 'kseniya',
  'bonya-viktoriya': 'viktoriya',
  'nuland-viktoriya': 'viktoriya',
  'romanets-viktoriya': 'viktoriya',
  'dayneko-viktoriya': 'viktoriya',
  'lopyreva-viktoriya': 'viktoriya',
  'boyarskaya-elizaveta': 'elizaveta',
  'elizaveta-ii': 'elizaveta',
  'peskova-elizaveta': 'elizaveta',
  'lihacheva-elizaveta': 'elizaveta',
  'arzamasova-elizaveta': 'elizaveta',
  'karaulova-varvara': 'varvara',
  'vizbor-varvara': 'varvara',
  'gusinskaya-varvara': 'varvara',
  'turova-varvara': 'varvara',
  'dmitrieva-varvara': 'varvara',
  'rozanova-irina': 'irina',
  'amanti-irina': 'irina',
  'allegrova-irina': 'irina',
  'apeksimova-irina': 'irina',
  'alferova-irina': 'irina',
  'timoshenko-yuliya': 'yuliya',
  'vysotskaya-yuliya': 'yuliya',
  'proskuryakova-yuliya': 'yuliya',
  'mihalkova-yuliya': 'yuliya',
  'menshova-yuliya': 'yuliya',
  'skvortsova-veronika': 'veronika',
  'lario-veronika': 'veronika',
  'dolina-veronika': 'veronika',
  'kruglova-veronika': 'veronika',
  'krasheninnikova-veronika': 'veronika',
  'zhukov-egor': 'egor',
  'holyavin-egor': 'egor',
  'beroev-egor': 'egor',
  'stroev-egor': 'egor',
  'kolyvanov-egor': 'egor',
  'ganapolskiy-matvey': 'matvey',
  'morozov-matvey': 'matvey',
  'urin-matvey': 'matvey',
  'lykov-matvey': 'matvey',
  'volodarskiy-matvey': 'matvey',
  'poklonskaya-natalya': 'natalya',
  'eprikyan-natalya': 'natalya',
  'senchukova-natalya': 'natalya',
  'gorchakova-natalya': 'natalya',
  'vodyanova-natalya': 'natalya',
  'kostomarov-roman': 'roman',
  'abramovich-roman': 'roman',
  'protasevich-roman': 'roman',
  'starovoyt-roman': 'roman',
  'trotsenko-roman': 'roman',
  'polna-eva': 'eva',
  'merkacheva-eva': 'eva',
  'mendes-eva': 'eva',
  'kayli-eva': 'eva',
  'gertsigova-eva': 'eva',
  'troyanova-yana': 'yana',
  'poplavskaya-yana': 'yana',
  'rudkovskaya-yana': 'yana',
  'balan-yana': 'yana',
  'churikova-yana': 'yana',
  'arbenina-diana': 'diana',
  'ankudinova-diana': 'diana',
  'shurygina-diana': 'diana',
  'gurtskaya-diana': 'diana',
  'panchenko-diana': 'diana',
  'uolberg-mark': 'mark',
  'solonin-mark': 'mark',
  'feygin-mark': 'mark',
  'bogatyrev-mark': 'mark',
  'tsukerberg-mark': 'mark',
  'martynov-daniil': 'daniil',
  'strahov-daniil': 'daniil',
  'bezsonov-daniil': 'daniil',
  'berman-daniil': 'daniil',
  'sorokin-daniil': 'daniil',
  'proshutinskaya-kira': 'kira',
  'yarmysh-kira': 'kira',
  'zalevskaya-kira': 'kira',
  'mayer-kira': 'kira',
  'turchak-kira': 'kira',
  'lopatkina-ulyana': 'ulyana',
  'ivanova-ulyana': 'ulyana',
  'sergeenko-ulyana': 'ulyana',
  'sinetskaya-ulyana': 'ulyana',
  'lobanova-ulyana': 'ulyana',
  'pushilin-denis': 'denis',
  'prokopenko-denis': 'denis',
  'dobrovolskiy-denis': 'denis',
  'shalnyh-denis': 'denis',
  'maydanov-denis': 'denis',
  'orbakayte-kristina': 'kristina',
  'asmus-kristina': 'kristina',
  'potupchik-kristina': 'kristina',
  'orsa-kristina': 'kristina',
  'kazinskaya-kristina': 'kristina',
  'babenko-alena': 'alena',
  'vodonaeva-alena': 'alena',
  'sviridova-alena': 'alena',
  'hmelnitskaya-alena': 'alena',
  'doletskaya-alena': 'alena',
  'zelenskiy-vladimir': 'vladimir',
  'putin-vladimir': 'vladimir',
  'pozner-vladimir': 'vladimir',
  'danilets-vladimir': 'vladimir',
  'solovev-vladimir': 'vladimir',
  'boyko-yaroslav': 'yaroslav',
  'dronov-yaroslav': 'yaroslav',
  'sumbaev-yaroslav': 'yaroslav',
  'kudrinskiy-yaroslav': 'yaroslav',
  'kuzminov-yaroslav': 'yaroslav',
  'bazhenov-timofey': 'timofey',
  'karataev-timofey': 'timofey',
  'razuvaev-timofey': 'timofey',
  'kulyabin-timofey': 'timofey',
  'tribuntsev-timofey': 'timofey',
  'afrikantova-marina': 'marina',
  'hlebnikova-marina': 'marina',
  'lyulchuk-marina': 'marina',
  'rakova-marina': 'marina',
  'ovsyannikova-marina': 'marina',
  'belyy-ruslan': 'ruslan',
  'onischenko-ruslan': 'ruslan',
  'baysarov-ruslan': 'ruslan',
  'alahverdiev-ruslan': 'ruslan',
  'puhov-ruslan': 'ruslan',
  'mishulina-karina': 'karina',
  'orlova-karina': 'karina',
  'tsurkan-karina': 'karina',
  'razumovskaya-karina': 'karina',
  'dobrotvorskaya-karina': 'karina',
  'yatsenyuk-arseniy': 'arseniy',
  'popov-arseniy': 'arseniy',
  'vesnin-arseniy': 'arseniy',
  'shulgin-arseniy': 'arseniy',
  'borodin-arseniy': 'arseniy',
  'ivanov-timur': 'timur',
  'eremeev-timur': 'timur',
  'batrutdinov-timur': 'timur',
  'bekmansurov-timur': 'timur',
  'kulibaev-timur': 'timur',
  'grudinin-pavel': 'pavel',
  'priluchnyy-pavel': 'pavel',
  'volya-pavel': 'pavel',
  'delong-pavel': 'pavel',
  'chikov-pavel': 'pavel',
  'tihanovskaya-svetlana': 'svetlana',
  'loboda-svetlana': 'svetlana',
  'svetlichnaya-svetlana': 'svetlana',
  'dragan-svetlana': 'svetlana',
  'hodchenkova-svetlana': 'svetlana',
  'vovk-angelina': 'angelina',
  'dubrovskaya-angelina': 'angelina',
  'nikolaeva-angelina': 'angelina',
  'egorova-angelina': 'angelina',
  'koba-angelina': 'angelina',
  'shurygin-vladislav': 'vladislav',
  'surkov-vladislav': 'vladislav',
  'doronin-vladislav': 'vladislav',
  'ershov-vladislav': 'vladislav',
  'inozemtsev-vladislav': 'vladislav',
  'strelkov-igor': 'igor',
  'konashenkov-igor': 'igor',
  'pushkarev-igor': 'igor',
  'nikolaev-igor': 'igor',
  'krutoy-igor': 'igor',
  'bondarenko-nikolay': 'nikolay',
  'platoshkin-nikolay': 'nikolay',
  'baskov-nikolay': 'nikolay',
  'zlobin-nikolay': 'nikolay',
  'svanidze-nikolay': 'nikolay',
  'kashtanova-sofya': 'sofya',
  'sinitsyna-sofya': 'sofya',
  'hotina-sofya': 'sofya',
  'arzhakovskaya-sofya': 'sofya',
  'apfelbaum-sofya': 'sofya',
  'sivkov-konstantin': 'konstantin',
  'habenskiy-konstantin': 'konstantin',
  'bogomolov-konstantin': 'konstantin',
  'kinchev-konstantin': 'konstantin',
  'ernst-konstantin': 'konstantin',
  'volodina-vasilisa': 'vasilisa',
  'berzhanskaya-vasilisa': 'vasilisa',
  'starshova-vasilisa': 'vasilisa',
  'kozhina-vasilisa': 'vasilisa',
  'pyavko-vasilisa': 'vasilisa',
  'simonyan-margarita': 'margarita',
  'terehova-margarita': 'margarita',
  'suhankina-margarita': 'margarita',
  'gracheva-margarita': 'margarita',
  'agibalova-margarita': 'margarita',
  'ptushkin-anton': 'anton',
  'krasovskiy-anton': 'anton',
  'makarskiy-anton': 'anton',
  'alihanov-anton': 'anton',
  'batyrev-anton': 'anton',
  'kazachenko-vadim': 'vadim',
  'karasev-vadim': 'vadim',
  'rabinovich-vadim': 'vadim',
  'harchenko-vadim': 'vadim',
  'volkov-vadim': 'vadim',
  'kushhov-amir': 'amir',
  'gallyamov-amir': 'amir',
  'tuygunov-amir': 'amir',
  'miftahov-amir': 'amir',
  'safin-amir': 'amir',
  'samoylova-oksana': 'oksana',
  'akinshina-oksana': 'oksana',
  'fandera-oksana': 'oksana',
  'marchenko-oksana': 'oksana',
  'fedorova-oksana': 'oksana',
  'leschenko-lev': 'lev',
  'kuznetsov-lev': 'lev',
  'shlosberg-lev': 'lev',
  'kamenetskiy-lev': 'lev',
  'dodin-lev': 'lev',
  'smolyaninov-artur': 'artur',
  'ryno-artur': 'artur',
  'pirozhkov-artur': 'artur',
  'ocheretnyy-artur': 'artur',
  'arutyunyan-artur': 'artur',
  'povaliy-taisiya': 'taisiya',
  'vilkova-taisiya': 'taisiya',
  'kornilova-taisiya': 'taisiya',
  'osipova-taisiya': 'taisiya',
  'rumyantseva-taisiya': 'taisiya',
  'manukyan-david': 'david',
  'nuriev-david': 'david',
  'zaleev-david': 'david',
  'arahamiya-david': 'david',
  'damiano-david': 'david',
  'vinnik-oleg': 'oleg',
  'zhdanov-oleg': 'oleg',
  'gazmanov-oleg': 'oleg',
  'shishkanov-oleg': 'oleg',
  'lyashko-oleg': 'oleg',
  'panfilov-gleb': 'gleb',
  'grozovskiy-gleb': 'gleb',
  'pavlovskiy-gleb': 'gleb',
  'samoylov-gleb': 'gleb',
  'pyanyh-gleb': 'gleb',
  'brezhneva-vera': 'vera',
  'alentova-vera': 'vera',
  'polyakova-vera': 'vera',
  'vasileva-vera': 'vera',
  'afanaseva-vera': 'vera',
  'stashinskiy-bogdan': 'bogdan',
  'titomir-bogdan': 'bogdan',
  'benyuk-bogdan': 'bogdan',
  'butkevich-bogdan': 'bogdan',
  'bezpalko-bogdan': 'bogdan',
  'nikolaev-yuriy': 'yuriy',
  'podolyaka-yuriy': 'yuriy',
  'shatunov-yuriy': 'yuriy',
  'pichugin-yuriy': 'yuriy',
  'galtsev-yuriy': 'yuriy',
  'denisova-darina': 'darina',
  'chizh-darina': 'darina',
  'sharpilo-darina': 'darina',
  'gromova-darina': 'darina',
  'kozlova-darina': 'darina',
  'slepakov-semen': 'semen',
  'pegov-semen': 'semen',
  'semenchenko-semen': 'semen',
  'treskunov-semen': 'semen',
  'bagdasarov-semen': 'semen',
  'menschikov-stepan': 'stepan',
  'dzhigarhanyan-stepan': 'stepan',
  'guzey-stepan': 'stepan',
  'mihalkov-stepan': 'stepan',
  'poltorak-stepan': 'stepan',
  'savchenko-nadezhda': 'nadezhda',
  'babkina-nadezhda': 'nadezhda',
  'kadysheva-nadezhda': 'nadezhda',
  'ermakova-nadezhda': 'nadezhda',
  'tolokonnikova-nadezhda': 'nadezhda',
  'sobolev-viktor': 'viktor',
  'baranets-viktor': 'viktor',
  'medvedchuk-viktor': 'viktor',
  'baturin-viktor': 'viktor',
  'efimov-viktor': 'viktor',
  'yudashkin-valentin': 'valentin',
  'katasonov-valentin': 'valentin',
  'zemlyanskiy-valentin': 'valentin',
  'yumashev-valentin': 'valentin',
  'smirnitskiy-valentin': 'valentin',
  'talyzina-valentina': 'valentina',
  'matvienko-valentina': 'valentina',
  'tereshkova-valentina': 'valentina',
  'legkostupova-valentina': 'valentina',
  'ivanova-valentina': 'valentina',
  'bondarchuk-fedor': 'fedor',
  'dobronravov-fedor': 'fedor',
  'konyuhov-fedor': 'fedor',
  'lukyanov-fedor': 'fedor',
  'chistyakov-fedor': 'fedor',
  'polskih-galina': 'galina',
  'yuzefovich-galina': 'galina',
  'lebedeva-galina': 'galina',
  'ostanina-nina': 'nina',
  'usatova-nina': 'nina',
  'ananiashvili-nina': 'nina',
  'hartli-nina': 'nina',
  'gogaeva-nina': 'nina',
  'galkina-liza': 'elizaveta',
  'adamenko-liza': 'elizaveta',
  'tishkina-liza': 'elizaveta',
  'miller-liza': 'elizaveta',
  'niemi-liza': 'elizaveta',
  'gildeeva-liliya': 'liliya',
  'shevtsova-liliya': 'liliya',
  'chanysheva-liliya': 'liliya',
  'biryukova-liliya': 'liliya',
  'hegay-liliya': 'liliya',
  'zheleznyak-olesya': 'olesya',
  'sudzilovskaya-olesya': 'olesya',
  'nosova-olesya': 'olesya',
  'haritonenko-olesya': 'olesya',
  'loseva-olesya': 'olesya',
  'astafeva-dasha': 'darya',
  'zhukova-dasha': 'darya',
  'gauzer-dasha': 'darya',
  'suvorova-dasha': 'darya',
  'charusha-dasha': 'darya',
  'prilepin-zahar': 'zahar',
  'vinogradov-zahar': 'zahar',
  'milostivenko-zahar': 'zahar',
  'sarapulov-zahar': 'zahar',
  'kabanov-zahar': 'zahar',
  'shadaev-damir': 'damira',
  'valeev-damir': 'damira',
  'shavaleev-damir': 'damira',
  'muginov-damir': 'damira',
  'kashapov-damir': 'damira',
  'aliev-samir': 'samir',
  'bagirov-samir': 'samir',
  'dzhafarov-samir': 'samir',
  'nasri-samir': 'samir',
  'veliev-samir': 'samir',
  'deynega-milena': 'milena',
  'fadeeva-milena': 'milena',
  'avada-milena': 'milena',
  'tachuliya-milena': 'milena',
  'orlova-milena': 'milena',
  'teslya-gerasimov-georgiy': 'georgiy',
  'bovt-georgiy': 'georgiy',
  'boos-georgiy': 'georgiy',
  'satarov-georgiy': 'georgiy',
  'uglava-georgiy': 'georgiy',
  'yavlinskiy-grigoriy': 'grigoriy',
  'yudin-grigoriy': 'grigoriy',
  'leps-grigoriy': 'grigoriy',
  'grabovoy-grigoriy': 'grigoriy',
  'perelman-grigoriy': 'grigoriy',
  'kim-vitaliy': 'vitaliy',
  'borzenko-vitaliy': 'vitaliy',
  'borodin-vitaliy': 'vitaliy',
  'milonov-vitaliy': 'vitaliy',
  'gogunskiy-vitaliy': 'vitaliy',
};
