/**
 * Дата публикации кластера
 * @param value - время
 * @param endings - строчки-склонения времени
 */
export const rupluralize = (value: number | undefined, endings: string[]) => {
  if (Number(value) % 100 >= 11 && Number(value) % 100 <= 14) return endings[2];
  if (Number(value) % 10 === 1) return endings[0];
  if (Number(value) % 10 >= 2 && Number(value) % 10 <= 4) return endings[1];

  return endings[2];
};
