import { CURRENCY_CHAR_CODE } from 'config/constants/finance';

export enum SORT_DIRECTION {
  asc = 'asc',
  desc = 'desc',
}

export enum SORT_TYPE {
  bank = 'bank',
  usdBuy = 'usdBuy',
  usdSell = 'usdSell',
  eurBuy = 'eurBuy',
  eurSell = 'eurSell',
}

export enum RATE_TYPE {
  buyRate = 'buyRate',
  sellRate = 'sellRate',
}

export enum CURRENCY_ACTION_NAME {
  buy = 'Покупка',
  buyShort = 'Пок.',
  sell = 'Продажа',
  sellShort = 'Прод.',
}

export type CurrencyExchangeType =
  | CURRENCY_CHAR_CODE.USD
  | CURRENCY_CHAR_CODE.EUR;

export type CurrencyDataExchangeType = {
  updatedAt: string;
  sellRate: string;
  buyRate: string;
};

export type BankExchangeType = {
  code: string;
  id: number;
  name: string;
} & {
  [currency in CurrencyExchangeType]: CurrencyDataExchangeType;
};

export type AciveSortType = {
  sort: SORT_TYPE;
  direction: SORT_DIRECTION;
};

export type FetchBankExchangePropsType = {
  bankRegion: string;
  activeSort?: AciveSortType;
};
