import { LoadableComponent } from '@loadable/component';

/** Объект флагов ранее загруженных страниц */
const componentsController: {
  [componentName: string]: boolean;
} = {};

/**
 * Функция, возвращающая promise-обертку для принудительной загрузки чанка со страницей.
 * @param Component - объект компонента-чанка;
 * @param componentName - имя компонента.
 */
export const pageLoadable = (
  Component: LoadableComponent<object>,
  componentName: string,
) => {
  if (__BROWSER__ && !componentsController[componentName]) {
    return () =>
      new Promise((resolve) =>
        Component.load().then((data) => {
          componentsController[componentName] = true;
          resolve(data);
        }),
      );
  }

  return () => Promise.resolve(Component);
};

/**
 * Функция, возвращающая promise для принудительной загрузки чанка со страницей.
 * @param Component - объект компонента-чанка;
 * @param key - название компонента-чанка.
 */
export const getPageDownloader = (
  Component: LoadableComponent<object>,
  key: string,
) => {
  const pageDownloader = pageLoadable(Component, key);

  return pageDownloader();
};

type AppendPageDownloaderPropsType = {
  promiseList: (Promise<unknown> | void)[];
  Component: LoadableComponent<object>;
  key: string;
};

/**
 * Функция, возвращающая массив promise'ов с promise для принудительной загрузки чанка со страницей.
 * @param promiseList - массив promise'ов;
 * @param Component - объект компонента-чанка;
 * @param key - название компонента или любой другой уникальный ключ для хранения информации о странице.
 */
export const appendPageDownloader = ({
  promiseList,
  Component,
  key,
}: AppendPageDownloaderPropsType) => {
  const pageDownloader = pageLoadable(Component, key);

  return [...promiseList, pageDownloader()];
};
