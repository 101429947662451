import { Metric } from 'web-vitals';

export interface MetricDCLType extends Partial<Omit<Metric, 'name'>> {
  name: string;
}

/**
 * Получение времени до загрузки DOM
 * @param onReport - callback по окончании загрузки
 */
export const onDCL = (onReport: (metric: MetricDCLType) => void) => {
  const observer = new PerformanceObserver((list) => {
    list.getEntries().forEach((item) => {
      const entry = item as PerformanceNavigationTiming;

      if (entry.domComplete > 0) {
        onReport({ name: 'DCL', value: entry.domComplete });
        observer.disconnect();
      }
    });
  });

  observer.observe({ type: 'navigation', buffered: true });
};
