import { createSlice } from '@reduxjs/toolkit';

import { topProjectWidgetAdapter } from './adapter';
import {
  fetchTagNewsWidgetClusters,
  fetchTopProjectNews,
  fetchTopNewsRec,
} from './asyncs';

/**
 * Слайс виджета главного топа.
 * Вынес данные в отдельный слайс, чтобы сделать виджет независимым от страницы.
 */
const topProjectSlice = createSlice({
  name: 'topProject',
  initialState: topProjectWidgetAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTopProjectNews.pending, (state, { meta }) => {
      topProjectWidgetAdapter.upsertOne(state, {
        id: 'projectId' in meta.arg ? meta.arg.projectId : meta.arg.topicId,
        fetching: true,
        error: '',
        clusterIds: null,
      });
    });

    builder.addCase(
      fetchTopProjectNews.fulfilled,
      (state, { payload: clusterIds, meta }) => {
        topProjectWidgetAdapter.upsertOne(state, {
          id: 'projectId' in meta.arg ? meta.arg.projectId : meta.arg.topicId,
          fetching: false,
          error: '',
          clusterIds,
        });
      },
    );

    builder.addCase(fetchTopProjectNews.rejected, (state, { error, meta }) => {
      topProjectWidgetAdapter.upsertOne(state, {
        id: 'projectId' in meta.arg ? meta.arg.projectId : meta.arg.topicId,
        fetching: false,
        error: error.message,
        clusterIds: [],
      });
    });

    builder.addCase(fetchTopNewsRec.pending, (state, { meta }) => {
      topProjectWidgetAdapter.upsertOne(state, {
        id: meta.arg.projectId,
        fetching: true,
        error: '',
        clusterIds: null,
      });
    });

    builder.addCase(
      fetchTopNewsRec.fulfilled,
      (state, { payload: clusterIds, meta }) => {
        topProjectWidgetAdapter.upsertOne(state, {
          id: meta.arg.projectId,
          fetching: false,
          error: '',
          clusterIds,
        });
      },
    );

    builder.addCase(fetchTopNewsRec.rejected, (state, { error, meta }) => {
      topProjectWidgetAdapter.upsertOne(state, {
        id: meta.arg.projectId,
        fetching: false,
        error: error.message,
        clusterIds: [],
      });
    });

    builder.addCase(
      fetchTagNewsWidgetClusters.pending,
      (
        state,
        {
          meta: {
            arg: { widgetType },
          },
        },
      ) => {
        topProjectWidgetAdapter.addOne(state, {
          id: widgetType,
          fetching: true,
          error: '',
          clusterIds: null,
        });
      },
    );

    builder.addCase(
      fetchTagNewsWidgetClusters.fulfilled,
      (
        state,
        {
          payload: clusterIds,
          meta: {
            arg: { widgetType },
          },
        },
      ) => {
        topProjectWidgetAdapter.upsertOne(state, {
          id: widgetType,
          fetching: false,
          error: '',
          clusterIds,
        });
      },
    );

    builder.addCase(
      fetchTagNewsWidgetClusters.rejected,
      (
        state,
        {
          error,
          meta: {
            arg: { widgetType },
          },
        },
      ) => {
        topProjectWidgetAdapter.upsertOne(state, {
          id: widgetType,
          fetching: false,
          error: error.message,
          clusterIds: [],
        });
      },
    );
  },
});

export const topProjectReducer = topProjectSlice.reducer;
