import loadable from '@loadable/component';
import React from 'react';

import {
  fetchRegion,
  fetchRegionClusters,
} from 'common/redux/commonData/regions';
import { selectRegionAliasToId } from 'common/redux/commonData/regions/selectors';
import { setRegionId, setRegionPuids } from 'common/redux/pages/region';
import {
  selectRegionPageClustersIds,
  selectRegionPageRecommendClusterIds,
} from 'common/redux/pages/region/selectors';
import {
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import {
  appendPageDownloader,
  getPageDownloader,
} from 'common/routes/pageLoadable';
import { loadWidgetData } from 'common/routes/topic/utils';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const RegionDesktop = loadable(() => import('desktop/pages/Region'));
const RegionMobile = loadable(() => import('mobile/pages/Region'));

type RegionMatchRoute = {
  regionAlias: string;
};

/**
 * Роут региона.
 * https://news.rambler.ru/Torzhok/
 */
export const regionRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<RegionMatchRoute> => ({
  name: PAGE_TYPE.region,
  exact: true,
  sensitive: true,
  strict: true,
  path: '/:regionAlias([-A-Za-z]+)/',
  render: isMobile ? () => <RegionMobile /> : () => <RegionDesktop />,
  fetchData: async ({ dispatch, getState }, { params: { regionAlias } }) => {
    const projectId = selectProjectId(getState());
    const projectAlias = selectProjectAlias(getState());

    dispatch(setRegionPuids(projectAlias));

    /*
     * Запросы вынесены из Promise.all тк внутри fetchRegionClusters важно чтобы на момент запроса были айди региона изза selectRegionAliasToId
     * Запрос данных региона
     */
    await dispatch(fetchRegion(regionAlias));

    // Запрос кластеров для региона
    await dispatch(fetchRegionClusters({ alias: regionAlias, limit: 30 }));

    const regionId = selectRegionAliasToId(regionAlias)(getState());

    dispatch(setRegionId(regionId));

    const regionClustersIds = selectRegionPageClustersIds(getState());
    const regionRecommendClusterIds =
      selectRegionPageRecommendClusterIds(getState());

    if (isMobile) {
      await getPageDownloader(RegionMobile, Object.keys({ RegionMobile })[0]);

      return;
    }

    const promiseList: PromiseListType = [
      ...loadWidgetData({
        projectId,
        itemExcludedIds: [...regionClustersIds, ...regionRecommendClusterIds],
        rcmBlockType: RCM_BLOCK_TYPE.regionDesktop,
      }).map((promise) => dispatch(promise)),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: RegionDesktop,
        key: Object.keys({ RegionDesktop })[0],
      }),
    );
  },
});
