import { createSlice } from '@reduxjs/toolkit';

import { topicNewsWidgetAdapter } from './adapter';
import { fetchProjectTopNews, fetchTopicNews } from './asyncs';

/**
 * Слайс виджета с новостями топика.
 */
const topicNewsSlice = createSlice({
  name: 'topicNews',
  initialState: topicNewsWidgetAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTopicNews.pending, (state, { meta }) => {
      if (!meta.arg.topicId) return;

      topicNewsWidgetAdapter.addOne(state, {
        id: meta.arg.topicId,
        clusterIds: null,
        fetching: true,
        error: '',
      });
    });

    builder.addCase(fetchProjectTopNews.pending, (state, { meta }) => {
      if (!meta.arg.projectId) return;

      topicNewsWidgetAdapter.addOne(state, {
        id: meta.arg.projectId,
        clusterIds: null,
        fetching: true,
        error: '',
      });
    });

    builder.addCase(
      fetchTopicNews.fulfilled,
      (state, { payload: clusterIds, meta }) => {
        if (!meta.arg.topicId) return;

        topicNewsWidgetAdapter.upsertOne(state, {
          id: meta.arg.topicId,
          clusterIds,
          fetching: false,
          error: '',
        });
      },
    );

    builder.addCase(
      fetchProjectTopNews.fulfilled,
      (state, { payload: clusterIds, meta }) => {
        if (!meta.arg.projectId) return;

        topicNewsWidgetAdapter.upsertOne(state, {
          id: meta.arg.projectId,
          clusterIds,
          fetching: false,
          error: '',
        });
      },
    );

    builder.addCase(fetchTopicNews.rejected, (state, { error, meta }) => {
      if (!meta.arg.topicId) return;

      topicNewsWidgetAdapter.upsertOne(state, {
        id: meta.arg.topicId,
        clusterIds: [],
        fetching: false,
        error: error.message,
      });
    });

    builder.addCase(fetchProjectTopNews.rejected, (state, { error, meta }) => {
      if (!meta.arg.projectId) return;

      topicNewsWidgetAdapter.upsertOne(state, {
        id: meta.arg.projectId,
        clusterIds: [],
        fetching: false,
        error: error.message,
      });
    });
  },
});

export const topicNewsReducer = topicNewsSlice.reducer;
