import { BlockRendererMapType } from '@media-components/draft-parser';
import { BlockRendererType } from 'redraft';

/** Перечисление всех кастомных блоков для данной версии приложения */
export enum CUSTOM_BLOCK_TYPE {
  ArticleMediaSection = 'article-media-section',
  ArticleMediaSectionMobile = 'article-media-section-mobile',
  Banner = 'banner',
  Related = 'related',
  ResourceRelated = 'resource-related',
  ShortSummary = 'short-summary',
}

export type CustomBlockRendererMapType = {
  [key in CUSTOM_BLOCK_TYPE]: BlockRendererType;
} & BlockRendererMapType;

export type OuterDraftPropsType = {
  // Страница, на которой отображается драфт
  pageUrl?: string | null;
  // Id контента на странице, на которой отображается драфт
  pageId?: string | null;
  // Данные о пуидах для рекламы
  puids?: { [puidName: string]: string | undefined };
  // Флаг, который принудительно рисует легаси энтити. Нужен для отладки
  forceLegacy?: boolean;
  // Флаг, который говорит о том, что это мобильная версия (для стилей в некоторых блоках и энтити)
  isMobile?: boolean;
  // Данные о пользователе для компонентов рекламы в видеоплеере - id пользователя
  sspUserId?: string;
  // Данные для компонентов рекламы в видеоплеере - скоуп (общий домен для кук)
  sspUserIdScope?: string;
};

/* #region Names */

/**
 * Наименования блоков в драфте кластера.
 */
export enum INLINE_TYPES {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Underline = 'UNDERLINE',
  Strikethrough = 'STRIKETHROUGH',
}

/**
 * Наименования блоков в драфте кластера.
 */
export enum BLOCK_TYPES {
  H1 = 'header-one',
  H2 = 'header-two',
  H3 = 'header-three',
  H4 = 'header-four',
  H5 = 'header-five',
  H6 = 'header-six',
  Code = 'code-block',
  Blockquote = 'blockquote',
  ULItem = 'unordered-list-item',
  OLItem = 'ordered-list-item',
  Unstyled = 'unstyled',
  Atomic = 'atomic',
  Paragraph = 'paragraph',
}

/**
 * Наименования энтити (особых вставок) в драфте кластера.
 */
export enum ENTITY_TYPES {
  Link = 'LINK',
  Twitter = 'TWITTER',
  Instagram = 'INSTAGRAM',
  Facebook = 'FACEBOOK',
  Video = 'VIDEO',
  Youtube = 'YOUTUBE',
  VideoPlatform = 'VIDEO_PLATFORM',
  VideoPlatformLive = 'VIDEO_PLATFORM_LIVE',
  Telegram = 'TELEGRAM',
  Image = 'IMAGE',
  RAdvTurbo = 'R_ADV_TURBO',
  RTag = 'R_TAG',
  VK = 'VK',
  Expert = 'EXPERT',
  Legacy = 'LEGACY',
  RamblerQuiz = 'QUIZ',
  GoogleForm = 'GOOGLE_FORM',
  ApplePodcast = 'APPLE_PODCAST',
}

/* #endregion */
