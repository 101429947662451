/**
 * Принимает ключ и массив значений,
 * возвращает гет параметр в виде строки 'key[]=News&key[]=Photo&key[]=Video'
 * @param key ключ get-параметра
 * @param values массив значений get-parametra
 */
export const getArrayQueryParams = (
  key: string,
  values: (string | number | null | undefined)[],
) => {
  if (!key) return '';

  return values
    .map((value) => {
      if (value === null || value === undefined || value === '') {
        return '';
      }

      if (typeof value === 'number' && !Number.isFinite(value)) {
        return '';
      }

      return `${key}[]=${value}`;
    })
    .filter(Boolean)
    .join('&');
};
