type GetFilteredClustersPropsType = {
  incomingClusters: CardData[] | undefined;
  existingClustersIds: CardData['id'][];
};

/**
 * Функция возвращает отфильтрованный массив кластеров по айдишникам.
 * @param props.incomingClusters - новый массив кластеров;
 * @param props.existingClustersIds - массив айдишников существующих кластеров.
 */
export const getFilteredClusters = ({
  incomingClusters,
  existingClustersIds,
}: GetFilteredClustersPropsType) => {
  const incomingClustersIds = incomingClusters?.map(({ id }) => id);

  const foundDuplicateClustersIds = existingClustersIds.filter((id) =>
    incomingClustersIds?.includes(id),
  );

  return (
    incomingClusters?.filter(
      ({ id }) => !foundDuplicateClustersIds.includes(id),
    ) || []
  );
};
