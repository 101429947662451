import { createSlice } from '@reduxjs/toolkit';

import { fetchExchangeRateDynamics } from './asyncs';
import { ExchangeRateDynamicsWidgetType } from './typings';

/**
 * Слайс виджета Индексы и котировки
 */
const exchangeRateDynamicsSlice = createSlice({
  name: 'exchangeRateDynamics',
  initialState: {
    data: [] as APICurrencyRateType[],
    diff: null,
    minimumOfRate: {},
    maximumOfRate: {},
    fetching: false,
    error: '',
  } as ExchangeRateDynamicsWidgetType,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExchangeRateDynamics.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(
      fetchExchangeRateDynamics.fulfilled,
      (state, { payload: { data, diff, minimumOfRate, maximumOfRate } }) => {
        state.data = data;
        state.diff = diff;
        state.minimumOfRate = minimumOfRate;
        state.maximumOfRate = maximumOfRate;
        state.fetching = false;
      },
    );

    builder.addCase(fetchExchangeRateDynamics.rejected, (state, { error }) => {
      state.fetching = false;
      state.error = error.message;
    });
  },
});

export const exchangeRateDynamicsReducer = exchangeRateDynamicsSlice.reducer;
