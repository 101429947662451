import { useSelector } from 'react-redux';

import { selectRuid } from 'common/redux/runtime/selectors';

/**
 * Функция, генерирующая скоуп (общий домен для кук) для рекламы
 */
export const getPublisherScope = () => `.rambler.ru`;

/**
 * Хук, отдающий данные о юзере для компонентов рекламы.
 */
export const usePublisherAdData = () => {
  const publisherId = useSelector(selectRuid);

  return {
    publisherId: publisherId ?? '',
    publisherScope: getPublisherScope(),
  };
};
