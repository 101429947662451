import { selectCardById } from '../../entries/selectors';
import { selectWidgets } from '../selectors';

import { recommendationWidgetAdapter } from './adapter';

const selectors = recommendationWidgetAdapter.getSelectors(
  (state: IAppState) => state.commonData.widgets.recommendationWidget,
);

export const selectWidgetsRecomended = (state: IAppState) =>
  selectWidgets(state).recommendationWidget;

export const selectWidgetRecomendedByClusterId =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectors.selectById(state, clusterId);

export const selectWidgetsTopProjectClusterIDs =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectWidgetRecomendedByClusterId(clusterId)(state)?.clusterIds || [];

export const selectWidgetsRecommendedCluster =
  (clusterIds: CardData['id'][]) => (state: IAppState) =>
    clusterIds?.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectWidgetsRecomendedClusterInCardData =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectWidgetsRecommendedCluster(
      selectWidgetsTopProjectClusterIDs(clusterId)(state),
    )(state);

export const selectWidgetsRecommendedLoadingState =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectWidgetRecomendedByClusterId(clusterId)(state)?.fetching || false;

export const selectWidgetsRecommendedErrorState =
  (clusterId: CardData['id']) => (state: IAppState) =>
    selectWidgetRecomendedByClusterId(clusterId)(state)?.error;

export const selectLoadedRecommendedClusters = (
  state: IAppState,
): CardData['id'][] =>
  selectors.selectAll(state).reduce(
    (items, cluster) => {
      items.push(...cluster.clusterIds);

      return items;
    },
    [] as CardData['id'][],
  );
