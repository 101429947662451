import { selectTravelCitiesByAliases } from 'common/redux/commonData/travelCities/selectors';

import { selectPages } from '../selectors';

export const selectGuideCountryCities = (state: IAppState) =>
  selectPages(state).guideCountryCities;

export const selectGuideCountryCitiesPuids = (state: IAppState) =>
  selectGuideCountryCities(state).puids;

export const selectGuideCountryCitiesAliases = (state: IAppState) =>
  selectGuideCountryCities(state).citiesAliases;

export const selectGuideCountryAlias = (state: IAppState) =>
  selectGuideCountryCities(state).countryAlias;

export const selectGuideCountryCitiesData = (state: IAppState) => {
  const aliases = selectGuideCountryCitiesAliases(state);

  return selectTravelCitiesByAliases(aliases)(state);
};
