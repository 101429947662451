import { selectCardById } from '../../entries/selectors';
import { selectWidgets } from '../selectors';

export const selectWidgetsBreakingNews = (state: IAppState) =>
  selectWidgets(state).breakingNewsWidget;

export const selectWidgetsBreakingNewsHasBreakingNews = (state: IAppState) =>
  selectWidgetsBreakingNews(state).hasBreakingNews;

export const selectWidgetsBreakingNewsClusterId = (state: IAppState) =>
  selectWidgetsBreakingNews(state).clusterId;

export const selectWidgetsBreakingNewsCluster =
  (clusterId: CardData['id'] | null) => (state: IAppState) =>
    selectCardById(clusterId)(state);

export const selectWidgetsBreakingNewsClusterInCardData = (state: IAppState) =>
  selectWidgetsBreakingNewsCluster(selectWidgetsBreakingNewsClusterId(state))(
    state,
  );

export const selectWidgetsBreakingNewsLoadingState = (state: IAppState) =>
  selectWidgetsBreakingNews(state).fetching;
