import { createContext, useContext } from 'react';

export type WidgetsStatusConfigValue = {
  setInitedScript: (widgetName: string) => void;
  statuses: Record<string, boolean>;
};

export const initialState: WidgetsStatusConfigValue = {
  setInitedScript: () => null,
  statuses: {},
};

/**
 * Контекст для хранения статуса по вставке скриптов виджетов.
 */
const WidgetsStatusContext =
  createContext<WidgetsStatusConfigValue>(initialState);

export const WidgetsStatusContextProvider = WidgetsStatusContext.Provider;

export const useWidgetStatus = () => {
  const { setInitedScript, statuses } = useContext(WidgetsStatusContext);

  return {
    setInitedScript: setInitedScript ?? initialState?.setInitedScript,
    statuses: statuses ?? initialState?.statuses,
  };
};
