type DataType = Record<string, unknown>;

type MergeArraysType<T> = {
  originalArray: T[];
  newArray: T[];
  keyBy?: string;
};

type MergeArraysTypeNullable<T> = {
  originalArray: T[] | null;
  newArray: T[] | null;
  keyBy?: string;
};

type FindSameItemsByKeyReturnType<T> = {
  sameItems: [T, T][];
  filteredNewArray: T[];
};

/**
 * Проходит по массиву originalArray и ищет в массиве newArray такую же сущность по keyBy
 * @param params.originalArray - исходный массив данных
 * @param params.newArray – массив с новыми данными
 * @param params.keyBy – ключ по которому будут искаться одинаковые сущности
 * @returns {{sameItems, filteredNewArray}}
 *  - sameItems - массив с парами одинаковых сущностей
 *  - filteredNewArray - отфильтрованный от найденных сущностей массив
 */
const findSameItemsByKey = <T extends Record<string, unknown>>({
  originalArray,
  newArray,
  keyBy = 'id',
}: MergeArraysType<T>): FindSameItemsByKeyReturnType<T> => {
  const sameItems: FindSameItemsByKeyReturnType<T>['sameItems'] = [];
  const filteredNewArray = [...newArray];

  originalArray.forEach((originalItem) => {
    const sameItemIndex = filteredNewArray.findIndex(
      (newItem) => newItem[keyBy] === originalItem[keyBy],
    );

    if (sameItemIndex !== -1) {
      sameItems.push([originalItem, filteredNewArray[sameItemIndex]]);
      filteredNewArray.splice(sameItemIndex, 1);
    }
  });

  return { sameItems, filteredNewArray };
};

/**
 * Объединяет 2 объекта в 1 с определенными условиями
 * P.S. не через lodash тк в assignWith/mergeWith при выводе в консоль objValue и srcValue из customizer всегда одинаковы, и совпадают с замещающим значением
 * в итоге не получается сделать так, чтобы существующие значения не перезаписывались на null или пустую строку
 * @param originalItem – исходный объект
 * @param newItem – новый объект
 */
const mergeItems = <T extends Record<string, unknown>>(
  originalItem: T,
  newItem: T,
): T => {
  if (!originalItem || typeof originalItem !== 'object') return newItem;
  if (!newItem || typeof newItem !== 'object') return originalItem;

  return Object.keys(newItem).reduce(
    (mergedItem, key) => {
      // Если нет нового значения, оставляем старое
      if (!newItem[key]) return mergedItem;

      // Если новое значение является объектом, то сравниваем со старым и оставляем то, что длиннее
      if (Array.isArray(newItem[key])) {
        const isNeedReplace =
          !originalItem[key] ||
          (newItem[key] as []).length > (originalItem[key] as [])?.length;

        return {
          ...mergedItem,
          [key]: isNeedReplace ? newItem[key] : originalItem[key],
        };
      }

      // Если новое значение является объектом, то перезаходим в функцию mergeItems и объединяем их
      if (typeof newItem[key] === 'object') {
        return {
          ...mergedItem,
          [key]: mergeItems(
            originalItem[key] as DataType,
            newItem[key] as DataType,
          ),
        };
      }

      return { ...mergedItem, [key]: newItem[key] };
    },
    { ...originalItem },
  );
};

/**
 * Объединяет 2 массива объектов в один. При этом одинаковые сущности ищутся по keyBy и уже имеющиеся значения не перезаписываются
 * Используется например для объединения entries тк в сторе может лежать ClusterData а потом прийти CardData у которого поля мб null или ''
 * @param params.originalArray – исходный массив данных
 * @param params.newArray – массив с новыми данными
 * @param params.keyBy – ключ по которому будут искаться одинаковые сущности
 * @returns массив со смерженными объектами
 */
export const mergeArrays = <T extends Record<string, unknown>>({
  originalArray,
  newArray,
  keyBy = 'id',
}: MergeArraysTypeNullable<T>): T[] => {
  if (!Array.isArray(originalArray)) return [];
  if (!Array.isArray(newArray)) return [];
  if (originalArray[0] && typeof originalArray[0] !== 'object') return newArray;
  if (newArray[0] && typeof newArray[0] !== 'object') return originalArray;

  const { sameItems, filteredNewArray } = findSameItemsByKey<T>({
    originalArray,
    newArray,
    keyBy,
  });
  const mergedItems = sameItems.map((item) => mergeItems(item[0], item[1]));

  return [...filteredNewArray, ...mergedItems];
};
