import { CacheConfigType } from 'typings/Config';
import { checkingXperias } from 'utils/checkingXperias';
import { insertScriptIntoHead } from 'utils/insertScriptIntoHead';

type InitServiceWorkerPropsType = {
  cacheConfig: CacheConfigType;
  pushwoosh: PushwooshType;
  projectName: ProjectType['name'];
  projectAlias: ProjectType['alias'];
};

/**
 * Инициализация воркеров
 * @param cacheConfig - конфигурация для кеша
 * @param pushwoosh - конфигурация для пуш-уведомлений
 * @param projectName - название проекта
 * @param projectAlias - alias проекта
 */
export const initServiceWorker = async ({
  cacheConfig,
  pushwoosh,
  projectName,
  projectAlias,
}: InitServiceWorkerPropsType) => {
  insertScriptIntoHead({
    src: '//cdn.pushwoosh.com/webpush/v3/pushwoosh-web-notifications.js',
  });

  // Для включения кэширования настраивает settings-local.json
  if (!cacheConfig.ALL && !cacheConfig.SERVICE_WORKER_CACHE) {
    return;
  }

  try {
    const applicationCode = pushwoosh && pushwoosh.app_code;
    const defaultNotificationImage = `i/favicon-${projectAlias}/apple-icon-114x114.png`;
    const defaultNotificationTitle = projectName;
    const safariWebsitePushID = `web.${projectAlias}.rambler.ru`;

    const serviceWorkerUrl =
      __DEV__ || checkingXperias()
        ? '/rambler-service-worker.js'
        : '/rnews/static/service-worker.js';

    const pushWooshSettigns = {
      // logLevel: 'info' || 'debug'
      logLevel: 'info',
      applicationCode,
      safariWebsitePushID,
      defaultNotificationTitle,
      defaultNotificationImage,
      serviceWorkerUrl,
      autoSubscribe: true,
    };

    if ('serviceWorker' in window.navigator) {
      await window.navigator.serviceWorker.register(serviceWorkerUrl, {
        scope: '/',
      });
    }

    window.Pushwoosh = window.Pushwoosh || [];

    if (!checkingXperias()) {
      window.Pushwoosh.push(['init', pushWooshSettigns]);
      window.Pushwoosh.push([
        'onSubscribe',
        () => {
          console.warn('User successfully subscribed');
        },
      ]);
    }
  } catch (error) {
    console.error('SW init error', error);
  }
};
