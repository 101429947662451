import parseLinkHeader from 'parse-link-header';

import { API_NAMES } from 'typings/Config';

import { getData } from './getData';
import { GetDataType } from './getData/types';
import { addParamsInPathRecommended } from './requestHandler/addParamsInPathRecommended';

/**
 * Получение заголовка link и данные из него
 * @param res - объект результата запроса
 */
export const getNextPage = (res: APIResponse) => {
  const { headers } = res;

  if (!headers) return '';

  const getHeaderLink = () => {
    if (__SERVER__) {
      return headers._headers?.link?.[0] || '';
    }

    return headers.get('link') ?? '';
  };

  return parseLinkHeader(getHeaderLink())?.next?.page ?? '';
};

/**
 * Функция получения юзер агента как на стороне сервера (вытаскивается из хедера), так и на клиенте.
 */
export const getUserAgentStr = () => {
  if (__SERVER__) {
    // @ts-expect-error: ¯\_(ツ)_/¯
    return global?.userAgent
      ? // @ts-expect-error: ¯\_(ツ)_/¯
        `&userAgent=${encodeURIComponent(global.userAgent)}`
      : '';
  }

  return window?.navigator?.userAgent
    ? `&userAgent=${encodeURIComponent(window.navigator.userAgent)}`
    : '';
};

/**
 * Функция получения данных из рекомендов с добавлением дополнительных параметров на стороне клиента.
 * @param requestParams - параметры запроса данных.
 */
export const getRCMData = async (requestParams: GetDataType) => {
  if (__SERVER__) {
    const { api, path, adtechData } = requestParams;

    const url = `${api.url}${path}`;

    const serverUrl = new URL(url);

    if (adtechData?.uid && adtechData?.scope) {
      serverUrl.searchParams.append('adtech_uid', adtechData.uid);
      serverUrl.searchParams.append('adtech_uid_scope', adtechData.scope);
    }

    const serverPath = `${serverUrl.pathname}${serverUrl.search}`;

    return getData<APIRecommender>({ ...requestParams, path: serverPath });
  }

  /*
   * добавление дополнительных параметров из топ100 к запросам на клиенте
   * https://jira.rambler-co.ru/browse/NEWS-10459
   */
  const clientPath = await addParamsInPathRecommended(requestParams.path);

  return getData<APIRecommender>({ ...requestParams, path: clientPath });
};

/**
 * Функция преобразования списка конфигов к полноценному объекту api,
 * который требуется для совершения запроса.
 * @param props - параметры функции;
 * @param props.apiConfig - объект с данными о конфигах;
 * @param props.apiName - имя api, данные о котором надо получить;
 * @param props.timeout - опциональное поле для перезаписи таймаута запроса на стороне сервера;
 * @param props.clientTimeout - опциональное поле для перезаписи таймаута запроса на стороне клиента;
 */
export const getAPIFromConfig = ({
  apiConfig,
  apiName,
  timeout,
  clientTimeout,
}: {
  apiConfig: ApiConfigType;
  apiName: API_NAMES;
  timeout?: number;
  clientTimeout?: number;
}): Api => {
  const api = apiConfig[apiName];
  const defaultAPI = apiConfig.default;

  if (!api)
    return (
      defaultAPI ??
      ({
        clientTimeout: clientTimeout ?? 0,
        timeout: timeout ?? 0,
        url: '',
      } as Api)
    );

  if (typeof api === 'string') {
    return {
      url: api,
      timeout: timeout ?? defaultAPI.timeout,
      clientTimeout: clientTimeout ?? defaultAPI.clientTimeout,
    };
  }

  return {
    url: api.url,
    clientTimeout:
      clientTimeout ?? api.clientTimeout ?? defaultAPI.clientTimeout,
    timeout: timeout ?? api.timeout ?? defaultAPI.timeout,
    keepAlive: api.keepAlive ?? undefined,
  };
};
