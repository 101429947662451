/**
 * Принимает объект и массив ключей, которые нужно убрать из объекта, возвращает объект без ключей
 * @param obj объект ключи которого нужно убрать
 * @param keys массив ключей которые нужно убрать
 */
export function omit<
  T extends { [key: string]: unknown },
  V extends (keyof T)[],
>(obj: T, keys: V) {
  const result = {} as Omit<T, V[0]>;

  Object.entries(obj).forEach(([key, value]) => {
    if (keys.includes(key)) {
      return;
    }

    // @ts-expect-error: ¯\_(ツ)_/¯
    result[key] = value;
  });

  return result;
}
