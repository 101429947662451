import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { matchType } from 'common/redux/utils';

import {
  fetchTagFinanceNews,
  fetchTopMainNews,
  fetchTopicFinanceNews,
} from './asyncs';

const thunks = [fetchTagFinanceNews, fetchTopMainNews, fetchTopicFinanceNews];

type TopMainWidgetType = {
  clusterIds: CardData['id'][];
} & Fetchable;

/**
 * Слайс виджета главного топа.
 * Вынес данные в отдельный слайс, чтобы сделать виджет независимым от страницы.
 */
const topMainSlice = createSlice({
  name: 'topMain',
  initialState: {
    clusterIds: [],
    fetching: false,
    error: '',
  } as TopMainWidgetType,
  reducers: {},
  extraReducers: (builder) => {
    // @ts-expect-error: поправить при переезде на новый синтаксис
    builder.addMatcher(matchType('pending', thunks), (state) => {
      state.fetching = true;
      state.error = '';
    });
    builder.addMatcher(
      // @ts-expect-error: поправить при переезде на новый синтаксис
      matchType('fulfilled', thunks),
      (state, { payload }: PayloadAction<CardData['id'][]>) => {
        state.clusterIds = payload;
        state.fetching = false;
        state.error = '';
      },
    );
    // @ts-expect-error: поправить при переезде на новый синтаксис
    builder.addMatcher(matchType('rejected', thunks), (state, { error }) => {
      state.fetching = false;
      state.error = error.message;
    });
  },
});

export const topMainReducer = topMainSlice.reducer;
