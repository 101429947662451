import loadable from '@loadable/component';
import React from 'react';

import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const NotFoundDesktop = loadable(() => import('desktop/pages/NotFound'));
const NotFoundMobile = loadable(() => import('mobile/pages/NotFound'));

/**
 * Роут 404.
 */
export const notFoundRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute => ({
  name: PAGE_TYPE.notFound,
  path: '**',
  render: isMobile ? () => <NotFoundMobile /> : () => <NotFoundDesktop />,
  fetchData: () => {},
});
