/**
 * Экранирование символов
 * @param str - строка для экранирования
 */
export const escapeRegExp = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/**
 * Экранирование символа +
 * @see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 * @see https://jira.rambler-co.ru/browse/NEWS-7459
 * @param str - строка для экранирования
 */
export const fixedEncodeURIComponent = (str: string) =>
  str.replace(
    /[+]/g,
    (c: string) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  );

/**
 * Конвертация строки в hex формат
 * @param string - base64 строка
 * @returns hex string
 */
export const stringToHex = (string: string) => {
  const buffer = Buffer.from(string, 'base64').subarray(0, 16);
  let result = '';

  for (let offset = 0; offset <= buffer.length - 4; offset += 4) {
    const str = buffer.readUInt32LE(offset).toString(16);

    result += str.length === 8 ? str : '0'.repeat(8 - str.length) + str;
  }

  return result.toUpperCase();
};
