import {
  CONVERTER_ID,
  CROSS_COURSE_ID,
  CURRENCIES_COURSES_ID,
  DOLLAR_COURSE_ID,
  EURO_COURSE_ID,
  EXCHANGE_COURSE_ID,
  FORECAST_COURSE_ID,
} from 'config/constants/topics';

const COMMON_LEVEL_BUTTON_NAME = 'Больше статей';

export const SERVICE_TOPICS = [
  {
    id: CURRENCIES_COURSES_ID,
    name: 'Курсы валют',
    alias: 'currencies',
    status: 1,
    project_id: 7,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Курсы валют',
      level_pos: 10,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: CONVERTER_ID,
    name: 'Конвертер валют',
    alias: 'calculators/converter',
    status: 1,
    project_id: 7,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Конвертер валют',
      level_pos: 11,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: DOLLAR_COURSE_ID,
    name: 'Курс доллара',
    alias: 'currencies/USD',
    status: 1,
    project_id: 7,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Курс доллара',
      level_pos: 12,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: EURO_COURSE_ID,
    name: 'Курс евро',
    alias: 'currencies/EUR',
    status: 1,
    project_id: 7,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Курс евро',
      level_pos: 13,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: FORECAST_COURSE_ID,
    name: 'Прогноз курса валют',
    alias: 'currencies/consensus',
    status: 1,
    project_id: 7,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Прогноз курса валют',
      level_pos: 14,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: EXCHANGE_COURSE_ID,
    name: 'Выгодный курс обмена',
    alias: 'currencies/exchange',
    status: 1,
    project_id: 7,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Выгодный курс обмена',
      level_pos: 15,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: CROSS_COURSE_ID,
    name: 'Кросс-курс',
    alias: 'currencies/kross-kurs',
    status: 1,
    project_id: 7,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Кросс-курс',
      level_pos: 16,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: 991,
    name: 'Путеводители',
    alias: 'guide',
    status: 1,
    project_id: 11,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Путеводители',
      level_pos: 20,
      level_status: 0,
    },
    rubricator_id: 2,
  },
  {
    id: 990,
    name: 'Гороскопы',
    alias: 'horoscopes',
    url: 'https://horoscopes.rambler.ru',
    status: 1,
    project_id: 4,
    details: {
      level_button_name: COMMON_LEVEL_BUTTON_NAME,
      level_name: 'Рамблер/гороскопы',
      level_pos: 8.1,
      level_status: 20,
    },
    rubricator_id: 2,
    isUtm: true,
  },
];
