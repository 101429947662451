import { createAsyncThunk } from '@reduxjs/toolkit';

import { getClusterItems, getClusterMainItem } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

const createClusterItemsData = (
  clusterItem: ATClusterItem,
): ClusterItemData => ({
  id: clusterItem.id,
  url: clusterItem.url,
  title: clusterItem.title,
  resourceId: clusterItem.resource_id,
  pubdate: clusterItem.pubdate,
});

/**
 * Функция загрузки карточек источников и главного источника кластера.
 * @param clusterId - id кластера, для которого загружаются источники;
 * @param callback - коллбек, которому возвращаются данные об источниках и главном источнике кластера.
 */
export const fetchItemsByCluster = createAsyncThunk(
  'fetchItemsByCluster',
  async (
    {
      clusterId,
      callback,
    }: {
      clusterId: ClusterData['id'];
      callback?: (
        items: ClusterItemData[],
        mainItem: ClusterItemData,
      ) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as IAppState);

    const { data: rawItems, error } = await getClusterItems(api, clusterId);

    if (error || !rawItems) {
      throw (
        error ||
        new Error(`Ошибка при получении айтемов кластера: ${clusterId}`)
      );
    }

    const { data: rawMainItem } = await getClusterMainItem(api, clusterId);

    // Главный айтем - это всегда первый айтем в списке.
    const mainItem = rawMainItem ?? rawItems[0];

    const items = rawItems.map((item) => createClusterItemsData(item));

    if (callback) await callback(items, createClusterItemsData(mainItem));

    /**
     * Возвращается ТОЛЬКО массив айтемов, так как главный айтем все равно входит в этот массив.
     *   В самом стейте будет неважно какие айтемы хранить.
     */
    return items;
  },
);
