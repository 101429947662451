import { createAsyncThunk } from '@reduxjs/toolkit';

import { getForecastsLast } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

import { FORECASTS_TYPE } from './typings';

type FetchForecastsLastPropsType = {
  charCode: CurrencyType['charCode'];
  type: FORECASTS_TYPE;
};

/**
 * Функция загрузки прогноза курса по валюте.
 * @param props.charCode - валюта для запроса;
 * @param props.type - тип прогноза (неделя/месяц/год).
 */
export const fetchForecastsLast = createAsyncThunk(
  'widgets/fetchForecastsLast',
  async ({ charCode, type }: FetchForecastsLastPropsType, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data, error } = await getForecastsLast(apiConfig, charCode, type);

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получении прогноза курса по валюте ${charCode}`)
      );
    }

    return { [type]: { ...data[0] } };
  },
);
