import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PERIODS } from 'config/constants/finance';

import { CURRENCY_SOURCES } from '../exchangeRatesWidget/typings';

type ChartWidgetPeriodsType = {
  period: PERIODS;
  disabledPeriods: Record<CURRENCY_SOURCES, PERIODS[]>;
};

type SetPeriodActionType = PayloadAction<PERIODS>;
type SetDisabledPeriodType = PayloadAction<{
  source: CURRENCY_SOURCES;
  period: PERIODS;
}>;

const initialState: ChartWidgetPeriodsType = {
  period: PERIODS.week,
  disabledPeriods: {
    [CURRENCY_SOURCES.Centrobank]: [] as PERIODS[],
    [CURRENCY_SOURCES.MMCB]: [] as PERIODS[],
    [CURRENCY_SOURCES.Forex]: [] as PERIODS[],
  },
};

/**
 * Слайс периодов виджета для графика.
 * Отдельно от widgets/chartWidget во имя избежания циклических импортов
 */
const chartWidgetPeriodsSlice = createSlice({
  name: 'chartWidgetPeriods',
  initialState,
  reducers: {
    setPeriod: (state, action: SetPeriodActionType) => {
      state.period = action.payload;
    },

    resetPeriod: (state) => {
      state.period = initialState.period;
    },

    setDisabledPeriod: (state, action: SetDisabledPeriodType) => {
      const { period, source } = action.payload ?? {};

      if (!source || !period) return;

      state.disabledPeriods[source] = [
        ...state.disabledPeriods[source],
        period,
      ];
    },

    resetDisabledPeriod: (state) => {
      state.disabledPeriods = initialState.disabledPeriods;
    },
  },
});

export const chartWidgetPeriodsReducer = chartWidgetPeriodsSlice.reducer;
export const {
  setPeriod,
  setDisabledPeriod,
  resetDisabledPeriod,
  resetPeriod,
} = chartWidgetPeriodsSlice.actions;
