import { createSlice } from '@reduxjs/toolkit';

import { fetchVideoForWidget } from './asyncs';

type VideoWidgetType = {
  clusterId: CardData['id'] | null;
} & Fetchable;

/**
 * Слайс видеовиджета.
 */
const videoWidgetSlice = createSlice({
  name: 'videoWidget',
  initialState: {
    clusterId: null,
    fetching: false,
    error: '',
  } as VideoWidgetType,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVideoForWidget.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(
      fetchVideoForWidget.fulfilled,
      (state, { payload: clusterId }) => {
        state.fetching = false;
        state.clusterId = clusterId;
      },
    );

    builder.addCase(fetchVideoForWidget.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message;
    });
  },
});

export const videoWidgetReducer = videoWidgetSlice.reducer;
