import { createSlice } from '@reduxjs/toolkit';

import { manualTagsAdapter } from './adapter';
import { fetchManualTagsByCluster } from './asyncs';

/**
 * Стейт ручных тегов.
 * @param entries - объект с ключ: id ручного тега - значение: информация о ручном теге;
 * @param fetching - флаг, что происходит загрузка хотя бы одного тега;
 * @param error - сообщение последней ошибки, произошедшей при загрузке тега.
 */
const manualTagsSlice = createSlice({
  name: 'manualTags',
  initialState: manualTagsAdapter.getInitialState({
    fetching: false,
    error: '',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchManualTagsByCluster.pending, (state) => {
      state.fetching = true;
      state.error = '';
    });

    builder.addCase(
      fetchManualTagsByCluster.fulfilled,
      (state, { payload: manualTags }) => {
        // Если появится новая информация - перезаписывается старая
        manualTagsAdapter.upsertMany(
          state,
          manualTags.map((manualTag) => ({
            ...manualTag,
            fetching: false,
            error: '',
          })),
        );
        state.fetching = false;
      },
    );

    builder.addCase(fetchManualTagsByCluster.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message || '';
    });
  },
});

export const manualTagsReducer = manualTagsSlice.reducer;
