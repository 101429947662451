import { BannersMapType } from 'typings/Config';

import { auto } from './auto-data';
import { common } from './common-data';
import { doctor } from './doctor-data';
import { finance } from './finance-data';
import { kino } from './kino-data';
import { news } from './news-data';
import { sport } from './sport-data';
import { travel } from './travel-data';
import { weekend } from './weekend-data';
import { woman } from './woman-data';

export const BANNERS: BannersMapType = {
  auto,
  doctor,
  finance,
  kino,
  news,
  sport,
  travel,
  unknown: news,
  weekend,
  woman,

  family: common,
  life: common,
  pro: common,
  dom: common,
  ai: common,
  sci: common,
};
