import Banners from 'config/constants/banner/banners';

// Баннеры с отступом в 20px внизу у контейнера
export const BANNERS_WITH_MARGIN_BOTTOM: { [index: string]: boolean } = {
  [Banners.TopBanner]: true,
  [Banners.Billboard]: false,
  [Banners.Parallax]: true,
  [Banners.Superfooter]: true,
  [Banners.Inread]: true,
  [Banners['240x400']]: true,
  [Banners['100x70']]: true,
  [Banners['240x400_2']]: true,
};

// Баннеры для вертикали финансов - десктоп
export const BANNERS_REQUIRED_FINANCE_DESKTOP: { [index: string]: boolean } = {
  [Banners.NativePartner]: true,
  [Banners['100x70']]: true,
  [Banners['240x400_2']]: true,
  [Banners.Superfooter]: true,
  [Banners.Native]: true,
  [Banners.Parallax]: true,
};

// Баннеры для вертикали финансов - мобилка
export const BANNERS_REQUIRED_FINANCE_MOBILE: { [index: string]: boolean } = {
  [Banners.TopBanner]: true,
  [Banners.Content1]: true,
  [Banners.Footer]: true,
  [Banners.Content4]: true,
  [Banners.Content2]: true,
  [Banners.ContentSpec]: true,
  [Banners.Content3]: true,
  [Banners.Listing1]: true,
  [Banners.Spec]: true,
  [Banners.Listing4]: true,
  [Banners.Listing2]: true,
  [Banners.Listing3]: true,
};

export const BANNER_PRELOAD_HEIGHT: { [x in Banners]?: number } = {
  // Desktop

  // [Banners.Billboard]: 250, Настраивается для каждого конкректного случая
  [Banners.Parallax]: 400,
  [Banners.Superfooter]: 250,

  [Banners.Inread]: 300,
  [Banners.Inpage]: 250,

  [Banners.NativePartner]: 115,

  [Banners['240x400']]: 600,
  [Banners['240x400_2']]: 600,

  [Banners.Native]: 290,
  [Banners.Context]: 290,
  [Banners['100x70']]: 290,

  // Mobile

  [Banners.TopBanner]: 250,

  [Banners.Listing1]: 250,
  [Banners.Listing2]: 250,
  [Banners.Listing3]: 250,
  [Banners.Listing4]: 250,
  [Banners.Spec]: 250,

  [Banners.Content1]: 250,
  [Banners.Content2]: 250,
  [Banners.Content3]: 250,
  [Banners.Content4]: 250,
  [Banners.ContentSpec]: 250,

  [Banners.Footer]: 250,
};
