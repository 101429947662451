import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { rnetBannersAdapter } from './adapter';

const rnetEntriesSlice = createSlice({
  name: 'widgets/rnetEntries',
  initialState: rnetBannersAdapter.getInitialState(),
  reducers: {
    /**
     * Метод добавления данных для виджетов.
     * @param action.payload - массив данных.
     */
    addEntries: (state, action: PayloadAction<ATResourceRnetBlockBanner[]>) => {
      rnetBannersAdapter.upsertMany(state, action.payload);
    },
  },
});

export const { addEntries } = rnetEntriesSlice.actions;
export const rnetEntriesReducer = rnetEntriesSlice.reducer;
