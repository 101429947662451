/**
 * Генерация Keep-Alive хедера.
 * @param keepAliveConfig - конфиг keepAlive если установлен.
 */
export const getKeepAliveHeader = (keepAliveConfig: Api['keepAlive']) => {
  if (!keepAliveConfig) {
    return {};
  }

  return {
    'Keep-Alive': `timeout=${keepAliveConfig.timeout}, max=${keepAliveConfig.max}`,
  };
};
