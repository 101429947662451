import React, { useCallback } from 'react';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { ArrowUpIcon } from 'icons';

import { scrollToTop } from './utils';

import s from './styles.module.css';

type ScrollbackPropsType = {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  top100Value: string;
  styles?: { [className: string]: string };
};

/**
 * Кнопка скролла.
 * @param elementRef - ссылка на элемент;
 * @param top100Value - значение для топ100;
 * @param styles - стили для кнопки.
 */
export const ScrollButton = ({
  elementRef,
  top100Value,
  styles = s,
}: ScrollbackPropsType) => {
  const scrollToTopElement = useCallback(() => {
    scrollToTop(elementRef.current);
  }, [elementRef]);

  const top100Attribute = useTop100AttributeWithValue(top100Value);

  return (
    <button
      aria-label="Подняться наверх"
      className={styles.button}
      type="button"
      onTouchEnd={scrollToTopElement}
      onClick={scrollToTopElement}
      data-test="scroll_button"
      {...top100Attribute}
    >
      <ArrowUpIcon className={styles.icon} />
    </button>
  );
};
