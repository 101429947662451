import { useRef, useEffect, useCallback } from 'react';

/**
 * Хук вызывающий колбэк если был щелчек за пределами ref
 * @param callback колбэк который нужно вызвать при щелчке
 * @param isVisible для проверки был ли уже клик по кнопке "Еще"
 */
export const useClickOutside = <T extends HTMLElement>(
  callback: () => void,
  isVisible: boolean,
) => {
  const ref = useRef<T>(null);

  const onClick = useCallback(
    (e: MouseEvent) => {
      const { current } = ref;

      if (
        !current ||
        (typeof current.contains === 'function' &&
          !current.contains(e.target as HTMLElement))
      ) {
        callback();
      }
    },
    [callback],
  );

  useEffect(() => {
    if (!isVisible) {
      return () => {};
    }

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [onClick, isVisible]);

  return ref;
};
