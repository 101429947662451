import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type UserState = {
  // Идентификатор авторизированного пользователя.
  userChainId: string | null;
};

const initialState: UserState = {
  userChainId: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /**
     * Метод установки уникального идентификатора пользователя.
     * @param action.payload - id пользователя.
     */
    setUserChainId: (state, action: PayloadAction<string | null>) => {
      state.userChainId = action.payload;
    },
  },
});

export const userReducer = userSlice.reducer;
export const { setUserChainId } = userSlice.actions;
