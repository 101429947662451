import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PuidsType } from 'config/constants/common';
import { PAGE_TYPE } from 'config/constants/routerName';

type StateGuideCountryPlacesType = {
  // Пуиды настройки для рекламы
  puids: PuidsType;
  // Idишки мест отображаемых на странице
  placesIds: number[];
  // Alias текущей страны
  countryAlias: string;
};

const initialState: StateGuideCountryPlacesType = {
  puids: {
    puid6: 'RTRAVEL_COUNTRY',
    puid18: 'RTRAVEL_COUNTRY_PLACES',
  },
  placesIds: [],
  countryAlias: '',
};

const guideCountryPlacesSlice = createSlice({
  name: PAGE_TYPE.guideCountryPlaces,
  initialState,
  reducers: (create) => ({
    /**
     * Сохранение списка мест отображаемых на странице
     */
    setPageCountryPlaces: create.reducer(
      (state, action: PayloadAction<number[]>) => {
        // Тк нет загрузки по страницам то через просто присваивание
        state.placesIds = action.payload;
      },
    ),

    /**
     * Сохранение страны
     */
    setPageCountryAlias: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.countryAlias = action.payload;
      },
    ),
  }),
});

export const { setPageCountryPlaces, setPageCountryAlias } =
  guideCountryPlacesSlice.actions;

export const guideCountryPlacesReducer = guideCountryPlacesSlice.reducer;
