import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAutotagPopular } from 'api';
import {
  selectApiConfig,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { AUTOTAG_TYPE } from 'config/constants/cluster';

type FetchAutotagTopPropsType = {
  autotagAlias: AUTOTAG_TYPE.organization | AUTOTAG_TYPE.person;
};

/**
 * Получение списка популярных тегов.
 * @param props.autotagAlias - алиас тега.
 */
export const fetchAutotagTop = createAsyncThunk(
  'fetchAutotagTop',
  async ({ autotagAlias }: FetchAutotagTopPropsType, { getState }) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const projectId = selectProjectId(state);

    const { error, data } = await getAutotagPopular(
      apiConfig,
      projectId,
      autotagAlias,
    );

    if (error || !data) {
      throw error || new Error(`Ошибка при получение топа: ${autotagAlias}`);
    }

    return data;
  },
);
