import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setTextScaleToCookie } from 'common/components/ClusterControl/components/TextScale/utils';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchClusterFeed } from './asyncs';

const TEXT_SCALE_VALUES = [0, 1, 2] as const;
export const MIN_TEXT_SCALE_VALUE = TEXT_SCALE_VALUES[0];
export const MAX_TEXT_SCALE_VALUE =
  TEXT_SCALE_VALUES[TEXT_SCALE_VALUES.length - 1];

export type TextScaleValueType = (typeof TEXT_SCALE_VALUES)[number];

type ClusterState = {
  clusterFeed: ClusterData['id'][];
  currentClusterId: ClusterData['id'] | null;
  topNow: ClusterData['id'][];
  topicId: ATTopic['id'] | null;
  textScaleValue: TextScaleValueType;
  speaking: ClusterSpeakingType;
  isArticle: boolean;
} & Fetchable;

const initialState: ClusterState = {
  clusterFeed: [],
  topNow: [],
  currentClusterId: null,
  topicId: null,
  textScaleValue: TEXT_SCALE_VALUES[0],
  speaking: {
    play: false,
    pause: false,
    clusterId: null,
  },
  isArticle: false,
  // Добавить ещё данных, специфичных для страницы кластера
  fetching: false,
  error: '',
};

const clusterSlice = createSlice({
  name: PAGE_TYPE.cluster,
  initialState,
  reducers: {
    /**
     * Установка топика кластера.
     * @param action.payload - id рубрики.
     */
    setClusterTopic: (
      state,
      action: PayloadAction<TopicType['id'] | undefined>,
    ) => {
      if (action.payload) {
        state.topicId = action.payload;
      }
    },

    /**
     * Установка текущего активного кластера и добавление в фид.
     * @param action.payload - id кластера.
     */
    setCurrentCluster: (state, action: PayloadAction<ClusterData['id']>) => {
      state.currentClusterId = action.payload;
      state.clusterFeed = [action.payload];
    },

    /**
     * Установка текущего активного кластера.
     * @param action.payload - id кластера.
     */
    setCurrentClusterId: (
      state,
      action: PayloadAction<ClusterData['id'] | null | undefined>,
    ) => {
      if (!action.payload) return;

      state.currentClusterId = action.payload;
    },

    /**
     * Уменьшение маштаба текста
     */
    clusterDecrementTextScale: (state) => {
      if (state.textScaleValue > TEXT_SCALE_VALUES[0])
        state.textScaleValue -= 1;
      setTextScaleToCookie(state.textScaleValue);
    },

    /**
     * Увеличение маштаба текста
     */
    clusterIncrementTextScale: (state) => {
      if (
        state.textScaleValue < TEXT_SCALE_VALUES[TEXT_SCALE_VALUES.length - 1]
      )
        state.textScaleValue += 1;
      setTextScaleToCookie(state.textScaleValue);
    },

    /**
     * Установка заданного значения маштаба текста
     * @param action.payload - значение шрифта
     */
    clusterSetTextScale: (
      state,
      { payload: value }: PayloadAction<number | null>,
    ) => {
      if (TEXT_SCALE_VALUES.includes(value as TextScaleValueType)) {
        state.textScaleValue = value as TextScaleValueType;
      }
    },

    /**
     * Изменение объекта воспроизведения текста
     * @param action.payload - объект контроля воспроизведения
     */
    clusterChangeSpeech: (
      state,
      { payload: speakingData }: PayloadAction<Optional<ClusterSpeakingType>>,
    ) => {
      state.speaking = { ...state.speaking, ...speakingData };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchClusterFeed.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(fetchClusterFeed.fulfilled, (state, action) => {
      state.fetching = false;
      state.clusterFeed = action.payload;
    });

    builder.addCase(fetchClusterFeed.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message;
    });
  },
});

export const clusterReducer = clusterSlice.reducer;
export const {
  setClusterTopic,
  setCurrentCluster,
  setCurrentClusterId,
  clusterDecrementTextScale,
  clusterIncrementTextScale,
  clusterSetTextScale,
  clusterChangeSpeech,
} = clusterSlice.actions;

export { fetchClusterFeed };
