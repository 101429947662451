import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectPages } from '../selectors';

export const selectTag = (state: IAppState) => selectPages(state).tag;

export const selectTagPuids = (state: IAppState) => selectTag(state).puids;

export const selectTagInfo = (state: IAppState) => selectTag(state).info;

export const selectTagClustersIds = (state: IAppState) =>
  selectTag(state).clustersIds;

export const selectTagCurrentPage = (state: IAppState) =>
  selectTag(state).currentPage;

export const selectTagHasNextPage = (state: IAppState) =>
  selectTag(state).hasNextPage;

export const selectTagFetchingState = (state: IAppState) =>
  selectTag(state).fetching;

export const selectTagErrorState = (state: IAppState) => selectTag(state).error;

export const selectTagClusters = (state: IAppState) =>
  (selectTagClustersIds(state)
    .map((clusterId) => selectCardById(clusterId)(state))
    .filter(Boolean) as (CardData | ClusterData)[]) || [];
