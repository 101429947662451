import { createSlice } from '@reduxjs/toolkit';

import { itemsAdapter } from './adapter';
import { fetchItemsByCluster } from './asyncs';

/**
 * Стейт карточек для источников кластеров
 * @param entries - объект с ключ: id тега - значение: информация о теге;
 * @param fetching - флаг, что происходит загрузка хотя бы одного тега;
 * @param error - сообщение последней ошибки, произошедшей при загрузке тега.
 */
const itemsSlice = createSlice({
  name: 'items',
  initialState: itemsAdapter.getInitialState({
    fetching: false,
    error: '',
  } as Fetchable),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchItemsByCluster.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(fetchItemsByCluster.fulfilled, (state, { payload }) => {
      // Если появится новая информация - перезаписывается старая
      itemsAdapter.upsertMany(state, payload);
      state.fetching = false;
    });

    builder.addCase(fetchItemsByCluster.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message;
    });
  },
});

export const itemsReducer = itemsSlice.reducer;
