import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { PuidsType } from 'config/constants/common';

import { AutotagPageType } from './typings';

export const STANDART_AUTOTAG_LIMIT_FOR_NEWS = 15;
export const EXTENDED_TIMEOUT = 1000 * 30;

/**
 * Тайпгард для проверки на то, что строчка является типом автотега.
 * @param test - любой тип данных, проверяемый на принадлежность типу автотега.
 */
export const isAutotagType = (test: any): test is AUTOTAG_TYPE =>
  Object.values(AUTOTAG_TYPE).includes(test);

export const initialState: AutotagPageType & Fetchable = {
  puids: {
    puid6: '',
    puid15: '',
  } as PuidsType,
  type: null,
  alias: '',
  id: null,
  rating: null,
  seo: {
    description: '',
    title: '',
    headline: '',
    keywords: '',
    url: '',
  },
  date: {
    dateFrom: null,
    dateTo: null,
  },
  currentPage: 1,
  hasNextPage: false,
  fetching: false,
  error: '',
  clusterIds: [],
};
