import { createSlice } from '@reduxjs/toolkit';

import { fetchForecastsLast } from './asyncs';
import { DEFAULT_CONSENSUS_CURRENCY } from './constants';
import { ConsensusCurrencyType, ConsensusForecastsType } from './typings';

type ExchangeWidgetType = {
  currency: ConsensusCurrencyType;
  forecaster: string;
  forecasts: ConsensusForecastsType;
} & Fetchable;

/**
 * Слайс виджета прогноза курса.
 */
const consensusSlice = createSlice({
  name: 'consensus',
  initialState: {
    // Выбранная валюта.
    currency: DEFAULT_CONSENSUS_CURRENCY,
    // Поставщик прогноза.
    forecaster: '',
    // Прогноз курса по выбранной валюте на неделю, месяц, год.
    forecasts: {},
    fetching: false,
    error: '',
  } as ExchangeWidgetType,
  reducers: {
    /**
     * Метод обновления выбранной валюты
     * @param action.payload - выбранная валюта.
     */
    setConsensusCurrency: (state, { payload }) => {
      state.currency = payload;
    },

    /**
     * Метод обновления поставщика прогноза
     * @param action.payload - поставщик прогноза.
     */
    setConsensusForecaster: (state, { payload }) => {
      state.forecaster = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchForecastsLast.pending, (state) => {
      state.fetching = true;
      state.error = '';
    });
    builder.addCase(fetchForecastsLast.fulfilled, (state, { payload }) => {
      state.forecasts = { ...state.forecasts, ...payload };
      state.fetching = false;
      state.error = '';
    });
    builder.addCase(fetchForecastsLast.rejected, (state, { error }) => {
      state.fetching = false;
      state.error = error.message;
    });
  },
});

export const consensusReducer = consensusSlice.reducer;

export const { setConsensusCurrency, setConsensusForecaster } =
  consensusSlice.actions;
