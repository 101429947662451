import { selectCommonData } from '../selectors';

import { DEFAULT_RESOURCE, resourcesAdapter } from './adapter';

const selectors = resourcesAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).resources,
);

export const selectResources = (state: IAppState) =>
  selectCommonData(state).resources;

export const selectResourceById =
  (resourceId: ClusterResourceType['id'] | undefined | null) =>
  (state: IAppState) =>
    typeof resourceId === 'number'
      ? selectors.selectById(state, resourceId) ?? DEFAULT_RESOURCE
      : DEFAULT_RESOURCE;

export const selectResourcesByIds =
  (resourceIds: ClusterResourceType['id'][]) => (state: IAppState) =>
    resourceIds.map((resourceId) => selectResourceById(resourceId)(state));

export const selectResourcesLoadingState = (state: IAppState) =>
  selectResources(state).fetching;
