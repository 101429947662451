import { selectCommonData } from '../selectors';

import { rnetBannersAdapter } from './rnetEntries/adapter';

const selectors = rnetBannersAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).widgets.rnetEntries,
);

export const selectWidgets = (state: IAppState) =>
  selectCommonData(state).widgets;

export const selectWidgetsFeedList = (state: IAppState) =>
  selectWidgets(state).rnetEntries.ids;

export const selectWidgetsCardById =
  (id: ATResourceRnetBlockBanner['bannerId']) => (state: IAppState) =>
    selectors.selectById(state, id);

export const selectWidgetsManyCardById =
  (ids: ATResourceRnetBlockBanner['bannerId'][]) => (state: IAppState) =>
    ids.map((id) => selectors.selectById(state, id)).filter((card) => !!card);
