import { selectPages } from '../selectors';

export const selectGuide = (state: IAppState) => selectPages(state).guide;

export const selectGuidePuids = (state: IAppState) => selectGuide(state).puids;

export const selectGuideCurrentCard = (state: IAppState) =>
  selectGuide(state).currentCard;

export const selectGuideHasNextPage = (state: IAppState) =>
  selectGuide(state).hasNextPage;

export const selectGuideRegionIds = (state: IAppState) =>
  selectGuide(state).regionIds;
