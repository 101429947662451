import { useCallback, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useObserver } from 'common/hooks/useObserver';
import { selectPageName } from 'common/redux/appController';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { REACH_GOAL_REDESIGN_PAGES } from './config';

const OBSERVER_CONFIG = { threshold: 0.2 };

/**
 * Хук для получения функций отправки целей в ЯМ для виджетов
 * @param level – этаж на котором отображается виджет
 */
export const useWidgetReachGoal = (level: number | undefined) => {
  const runtime = useAppSelector(selectRuntime);
  const pageName = useAppSelector(selectPageName);
  const [wasView, setWasView] = useState(false);

  // в момент редизайна сравниваем только рубрики и вертикали, поэтому отключаем отправку целей для регионов и пр.
  const allowReachGoal =
    !!level && REACH_GOAL_REDESIGN_PAGES.includes(pageName);

  const onWidgetView = useCallback(() => {
    if (allowReachGoal && !wasView && level === 1) {
      yaReachGoal(runtime, COUNTER_ID.WebMobile, '1stage_sidebar_show');
      setWasView(true);
    }
  }, [allowReachGoal, level, runtime, wasView]);

  const widgetRef = useObserver<HTMLDivElement>({
    callback: allowReachGoal && !wasView ? onWidgetView : null,
    observerConfig: OBSERVER_CONFIG,
  });

  const reachGoalNewsClickCallback = useCallback(() => {
    if (!allowReachGoal) return;

    if (level === 1) {
      yaReachGoal(runtime, COUNTER_ID.WebMobile, '1stage_sidebar_news_click');
    } else {
      yaReachGoal(runtime, COUNTER_ID.WebMobile, 'sidebar_news_click_Nstage', {
        stages: level,
      });
    }
  }, [allowReachGoal, level, runtime]);

  const reachGoalMoreNewsClickCallback = useCallback(() => {
    if (!allowReachGoal) return;

    if (level === 1) {
      yaReachGoal(
        runtime,
        COUNTER_ID.WebMobile,
        '1stage_sidebar_button_morenews_click',
      );
    } else {
      yaReachGoal(
        runtime,
        COUNTER_ID.WebMobile,
        'sidebar_button_morenews_click_Nstage',
        { stages: level },
      );
    }
  }, [allowReachGoal, level, runtime]);

  const reachGoalAdClickCallback = useCallback(() => {
    if (!allowReachGoal) return;

    yaReachGoal(runtime, COUNTER_ID.WebMobile, '1stage_sidebar_adver_click');
  }, [allowReachGoal, runtime]);

  return {
    reachGoalNewsClickCallback,
    reachGoalMoreNewsClickCallback,
    reachGoalAdClickCallback,
    widgetRef,
  };
};

/**
 * Хук для получения функций отправки целей в ЯМ для меню рубрик
 */
export const useTopicsMenuReachGoal = () => {
  const runtime = useAppSelector(selectRuntime, shallowEqual);
  const pageName = useAppSelector(selectPageName);
  const [wasView, setWasView] = useState(false);

  // в момент редизайна сравниваем только рубрики и вертикали, поэтому отключаем отправку целей для регионов и пр.
  const allowShowReachGoal =
    REACH_GOAL_REDESIGN_PAGES.includes(pageName) && !wasView;

  const onMenuView = useCallback(() => {
    yaReachGoal(runtime, COUNTER_ID.WebMobile, 'topics_menu_show');
    setWasView(true);
  }, [runtime]);

  const menuWrapperRef = useObserver<HTMLMenuElement>({
    callback: allowShowReachGoal ? onMenuView : null,
  });

  const reachGoalClickCallback = useCallback(
    (topicAlias: string | number | undefined) => {
      if (REACH_GOAL_REDESIGN_PAGES.includes(pageName)) {
        yaReachGoal(runtime, COUNTER_ID.WebMobile, 'topics_menu_click', {
          menu_topics: String(topicAlias),
        });
      }
    },
    [pageName, runtime],
  );

  return {
    menuWrapperRef,
    reachGoalClickCallback,
  };
};
