import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { banksAdapter, banksCharToIdAdapter } from './adapter';
import { fetchSingleBankData } from './asyncs';
import { BankType } from './typings';

type UpsertBanksPayloadActionType = PayloadAction<BankType[]>;

/**
 * Слайс, хранящий данные о банках.
 */
const banksSlice = createSlice({
  name: 'banks',
  initialState: banksAdapter.getInitialState(
    banksCharToIdAdapter.getInitialState(),
  ),
  reducers: {
    upsertBanks: (state, { payload }: UpsertBanksPayloadActionType) => {
      banksAdapter.upsertMany(
        state,
        payload.map((bank) => ({
          ...bank,
          fetching: false,
          error: undefined,
        })),
      );

      banksCharToIdAdapter.upsertMany(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSingleBankData.pending,
      (state, { meta: { arg: bankCode } }) => {
        const id = state.charToId[bankCode];

        if (!id) return;

        banksAdapter.updateOne(state, {
          id,
          changes: {
            fetching: true,
            error: undefined,
          },
        });
      },
    );

    builder.addCase(fetchSingleBankData.fulfilled, (state, { payload }) => {
      banksAdapter.upsertOne(state, {
        ...payload,
        fetching: false,
        error: undefined,
      });

      banksCharToIdAdapter.upsertOne(state, payload);
    });

    builder.addCase(
      fetchSingleBankData.rejected,
      (state, { meta: { arg: bankCode }, error }) => {
        const id = state.charToId[bankCode];

        if (!id) return;

        banksAdapter.updateOne(state, {
          id,
          changes: {
            fetching: false,
            error: error.message,
          },
        });
      },
    );
  },
});

export const { upsertBanks } = banksSlice.actions;

export const banksReducer = banksSlice.reducer;
