import { createEntityAdapter } from '@reduxjs/toolkit';

/**
 * Адаптер стран для путешествий к типу [alias страны]: { данные по стране }
 */
export const travelCountriesAdapter = createEntityAdapter<
  TravelRegionType,
  string
>({
  selectId: (country) => country.alias,
});
