import Cookies from 'js-cookie';

import { RSWITCH_COOKIE_NAME } from 'config/constants/cookies';
import { APP_TYPE } from 'config/constants/devices';

export const setRswitch = (type: APP_TYPE) => {
  Cookies.set(RSWITCH_COOKIE_NAME, type, { domain: '.rambler.ru' });
  window.location.reload();
};

export const setRswitchDesktop = () => {
  setRswitch(APP_TYPE.desktop);
};

export const setRswitchMobile = () => {
  setRswitch(APP_TYPE.mobile);
};
