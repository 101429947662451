import { createSlice } from '@reduxjs/toolkit';

import { autotagsAdapter } from './adapter';
import { fetchAutotagByAlias, fetchAutotagsByCluster } from './asyncs';
import { AliasToIdType } from './typings';

/**
 * Стейт автотегов.
 * @param entries - объект с ключ: id автотега - значение: информация о автотеге;
 * @param fetching - флаг, что происходит загрузка хотя бы одного автотега;
 * @param error - сообщение последней ошибки, произошедшей при загрузке автотега.
 */
const autotagsSlice = createSlice({
  name: 'autotags',
  initialState: autotagsAdapter.getInitialState({
    fetching: false,
    error: '',
    aliasToId: {} as AliasToIdType,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutotagsByCluster.pending, (state) => {
        state.fetching = true;
        state.error = '';
      })
      .addCase(
        fetchAutotagsByCluster.fulfilled,
        (state, { payload: autotags }) => {
          // Если появится новая информация - перезаписывается старая
          autotagsAdapter.upsertMany(
            state,
            autotags.map((autotag) => ({
              ...autotag,
              fetching: false,
              error: '',
            })),
          );

          const mappedIdToAlias = autotags.reduce((acc, autotag) => {
            acc[autotag.alias] = autotag.id;

            return acc;
          }, {} as AliasToIdType);

          state.aliasToId = { ...state.aliasToId, ...mappedIdToAlias };
          state.fetching = false;
        },
      )
      .addCase(fetchAutotagsByCluster.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message || '';
      });

    builder
      .addCase(fetchAutotagByAlias.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchAutotagByAlias.fulfilled, (state, { payload: autotag }) => {
        // Если появится новая информация - перезаписывается старая
        autotagsAdapter.upsertOne(state, {
          mentions: [],
          ...autotag,
          fetching: false,
          error: '',
        });
        state.aliasToId[autotag.alias] = autotag.id;
        state.fetching = false;
      })
      .addCase(fetchAutotagByAlias.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message || '';
      });
  },
});

export const autotagsReducer = autotagsSlice.reducer;
export { fetchAutotagsByCluster };
