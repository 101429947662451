import { html as htmlTemplate, oneLineTrim } from 'common-tags';

/**
 * Промерочный пиксель DBM
 */
export const adDoubleClickNet: string = oneLineTrim(htmlTemplate`
    <noscript>
      <img src="https://ad.doubleclick.net/ddm/activity/src=8739587;type=invmedia;cat=ezvgsnmt;u1=afisha.rambler.ru;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/>
    </noscript>
`);
