import { loadableReady } from '@loadable/component';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectAdtech,
  selectCacheConfig,
  selectIsBot,
  selectIsMobile,
  selectProfile,
  selectProjectAlias,
  selectProjectName,
  selectPushwoosh,
  selectSentryConfig,
  selectUserId,
} from 'common/redux/runtime/selectors';
import { config } from 'config';
import { ROOT_ID_NAME } from 'config/constants/common';
import { APP_TYPE } from 'config/constants/devices';
import { PAGE_TYPE } from 'config/constants/routerName';
import { initClientMetrics } from 'server/collectors/prometheus/utils/metricsBatch';
import { initServiceWorker } from 'service-worker/initServiceWorker';
import { getStore } from 'store';
import { initBrowserMetrics } from 'utils/clientMetrics';
import { initScriptMetrics, initTraffgen } from 'utils/counters';
import { RCMsetupRecommender } from 'utils/counters/atdRecommender';
import { initClientTop100 } from 'utils/counters/top100';
import { initUserRelatedDataInState } from 'utils/initUserRelatedDataInState';
import { initSentryClient } from 'utils/sentry/sentry.client';

import { DesktopApp } from './App';
import desktopReducer from './redux/reducers';

import '@csstools/normalize.css';
import 'css/fonts.css';
import 'css/kraken.css';
import 'css/reset.css';

declare const window: EnhancedWindow;

const container = document.getElementById(ROOT_ID_NAME);

const state = initUserRelatedDataInState(
  window.__PRELOADED_STATE__ as IAppState,
);

const pageName = selectPageName(state);
const cacheConfig = selectCacheConfig(state);
const pushwoosh = selectPushwoosh(state);
const projectName = selectProjectName(state);
const projectAlias = selectProjectAlias(state);
const adtech = selectAdtech(state);
const userId = selectUserId(state);
const profile = selectProfile(state);
const isMobile = selectIsMobile(state);
const isBot = selectIsBot(state);
const sentryConfig = selectSentryConfig(state);

// Если появится больше клиентских метрик - вынести все в одну функцию
initClientTop100(state);

// Отключаем на кластере восстанавление местоположения страницы
if (pageName === PAGE_TYPE.cluster) {
  window.history.scrollRestoration = 'manual';
}

initBrowserMetrics(pageName, APP_TYPE.desktop);
initServiceWorker({ cacheConfig, pushwoosh, projectName, projectAlias });
initScriptMetrics(projectAlias);
RCMsetupRecommender(adtech, userId);
initSentryClient({ profile, isMobile, isBot, sentryConfig });
initTraffgen();

if (config.ENABLE_CLIENT_METRICS) {
  initClientMetrics(state);
}

const store = getStore(desktopReducer, state);

loadableReady().then(() => {
  const component = (
    <BrowserRouter>
      <Provider store={store}>
        <DesktopApp />
      </Provider>
    </BrowserRouter>
  );

  if (module.hot) {
    const root = createRoot(container!);

    root.render(component);
  } else {
    hydrateRoot(container!, component);
  }
});

if (module.hot) {
  module.hot.accept();
}
