import { createAsyncThunk } from '@reduxjs/toolkit';

import { getIndicesAndQuotes } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

import {
  IndicesAndQuotesCharCode,
  IndicesAndQuotesMarketsName,
  IndicesAndQuotesPeriod,
} from './typings';

type FetchIndicesAndQuotesPropsType = {
  src: IndicesAndQuotesMarketsName;
  period: IndicesAndQuotesPeriod;
  charCode: IndicesAndQuotesCharCode;
};

/**
 * Функция загрузки данных для виджета Индексы и котировки.
 * @param props.src - рынок для запроса;
 * @param props.period - период для запроса;
 * @param props.charCode - тикет для запроса.
 */
export const fetchIndicesAndQuotes = createAsyncThunk(
  'widgets/fetchIndicesAndQuotes',
  async (
    { src, period, charCode }: FetchIndicesAndQuotesPropsType,
    { getState },
  ) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data, error } = await getIndicesAndQuotes({
      apiConfig,
      src,
      period,
      charCode,
      needReverseOrder: true,
    });

    if (error || !data) {
      throw error;
    }

    const currentValue = data[0].rate;
    const previousValue = data[1].rate;

    const diff =
      ((currentValue - previousValue) / ((currentValue + previousValue) / 2)) *
      100;

    return { value: currentValue, diff };
  },
);
