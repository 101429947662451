import loadable from '@loadable/component';
import React from 'react';

import { fetchTravelCountries } from 'common/redux/commonData/travelCountries';
import { fetchTravelRegionPlace } from 'common/redux/commonData/travelRegionPlaces';
import {
  setGuideContentPagePuids,
  setPlaceId,
} from 'common/redux/pages/guideContentPage';
import { getPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute } from 'typings/AppRoute';

const GuidePlacePageDesktop = loadable(
  () => import('desktop/pages/GuidePlace'),
);
const GuidePlacePageMobile = loadable(() => import('mobile/pages/GuidePlace'));

type GuidePlaceMatchRoute = {
  pointId: string;
};

/**
 * Роут контентной страницы места.
 * https://travel.rambler.ru/guide/83440-Okhotkhozyaystvo-Severnoe/#1
 */
export const guidePlaceRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<GuidePlaceMatchRoute> => ({
  name: PAGE_TYPE.guidePlace,
  exact: true,
  path: '/guide/:pointId(\\d{1,10})\\-:placeInfo/',
  render: isMobile
    ? () => <GuidePlacePageMobile />
    : () => <GuidePlacePageDesktop />,
  fetchData: async ({ dispatch }, { params }) => {
    const pageDownloaderParams = isMobile
      ? ([GuidePlacePageMobile, 'GuidePlacePageMobile'] as const)
      : ([GuidePlacePageDesktop, 'GuidePlacePageDesktop'] as const);
    const { pointId } = params;

    dispatch(setPlaceId(pointId));
    dispatch(setGuideContentPagePuids(PAGE_TYPE.guidePlace));

    await dispatch(fetchTravelCountries());

    await dispatch(fetchTravelRegionPlace({ pointId }));

    await getPageDownloader(pageDownloaderParams[0], pageDownloaderParams[1]);
  },
});
