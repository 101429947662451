import cn from 'classnames';
import React, { memo } from 'react';

import s from './styles.module.css';

/**
 * Цветные блямбы.
 */
export const BackgroundBulbs = memo(() => (
  <div className={s.root}>
    <div className={cn(s.bulb, s.left)} />
    <div className={cn(s.bulb, s.right)} />
  </div>
));
