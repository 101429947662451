export enum PROFILES {
  stageTest = 'stage:test',
  stageProd = 'stage:prod',
  stage1Test = 'stage1:test',
  stage1Prod = 'stage1:prod',
  stage2Test = 'stage2:test',
  stage2Prod = 'stage2:prod',
  stage3Test = 'stage3:test',
  stage3Prod = 'stage3:prod',
  stage4Test = 'stage4:test',
  stage4Prod = 'stage4:prod',
  stage5Test = 'stage5:test',
  stage5Prod = 'stage5:prod',
  stage6Test = 'stage6:test',
  stage6Prod = 'stage6:prod',
  extestmTest = 'extestm:test',
  extestmProd = 'extestm:prod',
  release = 'release',
  local = 'local',
}

export const STAGE_PROFILES: string[] = [
  PROFILES.stageTest,
  PROFILES.stage1Test,
  PROFILES.stage2Test,
  PROFILES.stage3Test,
  PROFILES.stage4Test,
  PROFILES.stage5Test,
  PROFILES.stage6Test,
  PROFILES.extestmTest,
];
export const PROD_PROFILES: string[] = [
  PROFILES.stageProd,
  PROFILES.stage1Prod,
  PROFILES.stage2Prod,
  PROFILES.stage3Prod,
  PROFILES.stage4Prod,
  PROFILES.stage5Prod,
  PROFILES.stage6Prod,
  PROFILES.extestmProd,
  PROFILES.release,
];
