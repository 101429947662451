import { CURRENCIES } from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';

import { selectPages } from '../selectors';

export const selectCurrenciesPage = (state: IAppState) =>
  selectPages(state).currencies;

export const selectCurrenciesPageFirstCurrencyCharCode = (state: IAppState) =>
  selectCurrenciesPage(state).firstCurrencyCharCode;

export const selectCurrenciesPageSecondCurrencyCharCode = (state: IAppState) =>
  selectCurrenciesPage(state).secondCurrencyCharCode;

export const selectCurrenciesPageCurrencyCharCodeByCurrencyType = (
  currency: CURRENCIES,
) =>
  currency === CURRENCIES.First
    ? selectCurrenciesPageFirstCurrencyCharCode
    : selectCurrenciesPageSecondCurrencyCharCode;

export const selectCurrenciesPagePuids = (state: IAppState) =>
  selectCurrenciesPage(state).puids;

export const selectCurreciesPageIsLoading = (state: IAppState) =>
  selectCurrenciesPage(state).isLoading;
