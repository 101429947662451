import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCluster, getClusterItems } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectDomainConfig,
  selectIsMobile,
  selectProjectAlias,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { adaptClusterSourceToCard, adaptClusterToCard } from 'utils/adapters';

type FetchClusterItemsClusterPropsType = {
  clusterId: string;
};

/**
 * Получение кластера по id для страницы item'ом.
 * @param props.clusterId - id кластера;
 */
export const fetchClusterItemsCluster = createAsyncThunk(
  'fetchClusterItemsCluster',
  async ({ clusterId }: FetchClusterItemsClusterPropsType, { getState }) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);

    const { error, data } = await getCluster(apiConfig, clusterId);

    if (error || !data) {
      throw error || new Error(`Ошибка при получении кластера: ${clusterId}`);
    }

    const modifyCluster = adaptClusterToCard({
      cluster: data,
      domainConfig,
      variables,
    });

    return {
      cluster: modifyCluster,
    };
  },
);

type FetchTagClustersPropsType = {
  clusterId: string;
};

/**
 * Получение списка item'ом по id кластеру.
 * @param props.clusterId - id кластера;
 */
export const fetchClusterItemsClusters = createAsyncThunk(
  'fetchClusterItemsClusters',
  async ({ clusterId }: FetchTagClustersPropsType, { dispatch, getState }) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);
    const projectAlias = selectProjectAlias(state);
    const isMobile = selectIsMobile(state);

    const { error, data } = await getClusterItems(apiConfig, clusterId);

    if (error || !data) {
      throw (
        error ||
        new Error(
          `Ошибка при получении списка источников по кластеру: ${clusterId}`,
        )
      );
    }

    const modifyClusters = data.map((cluster) =>
      adaptClusterSourceToCard({
        cluster,
        projectAlias,
        domainConfig,
        variables,
        isMobile,
      }),
    );

    dispatch(addManyEntries(modifyClusters));

    return modifyClusters.map(({ id }) => id);
  },
);
