import { RawDraftEntity } from 'draft-js';

export const DATA_EXPERT_REG = /data-expert-id=(\d+)/i;
const EXPERT_ENTITY_NAME = 'EXPERT_EMBED';
const EXPERT_ENTITY_NAME_FROM_NEW_DRAFT = 'EXPERT';

/**
 * Фукнция извлечения данных об эксперте из кластера в массив id экспертов.
 * @param cluster - объект кластера с данными драфта, в которых находятся цитаты эксперта.
 */
export const extractExpertsFromCluster = (cluster: APICluster) => {
  if (typeof cluster?.draft?.entityMap !== 'object') {
    return [];
  }

  return (Object.values(cluster.draft.entityMap) as RawDraftEntity[])
    .filter(
      (entity) =>
        entity.type === EXPERT_ENTITY_NAME ||
        // Новое наименование типа эмбеда из нового драфта. Убрать старый тип после переезда на новый драфт
        entity.type === EXPERT_ENTITY_NAME_FROM_NEW_DRAFT,
    )
    .reduce((acc, entity) => {
      /**
       * В лучшем случае id эксперта может идти в отдельном поле внутри энтити.
       * Если expertId не удалось найти, можно предположить,
       *  что мы находимся в новом драфте и можем вытащить из нового поля id,
       *  иначе его все ещё можно вытащить прямо из html.
       */
      const id =
        entity?.data?.expertId ??
        entity?.data?.id ??
        entity?.data?.data?.match(DATA_EXPERT_REG)[1];

      if (id) {
        acc.push(Number(id));
      }

      return acc;
    }, [] as number[]);
};
