import { RequestHandler } from './types';

/**
 * Обработчик запроса для дополнительных манипуляций (сбора метрик).
 * Вынесен отдельно для различия между клиентом/сервером.
 * @param request - функция запроса
 */
export const requestHandler: RequestHandler = ({ request }) =>
  new Promise((resolve) => {
    request().then(resolve);
  });
