import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectPages } from '../selectors';

export const selectDate = (state: IAppState) => selectPages(state).date;

export const selectDatePuids = (state: IAppState) => selectDate(state).puids;

export const selectDateInfo = (state: IAppState) => selectDate(state).info;

export const selectDateClustersIds = (state: IAppState) =>
  selectDate(state).clustersIds;

export const selectDateCurrentPage = (state: IAppState) =>
  selectDate(state).currentPage;

export const selectDateHasNextPage = (state: IAppState) =>
  selectDate(state).hasNextPage;

export const selectDateFetchingState = (state: IAppState) =>
  selectDate(state).fetching;

export const selectDateErrorState = (state: IAppState) =>
  selectDate(state).error;

export const selectDateClusters = (state: IAppState) =>
  (selectDateClustersIds(state)
    .map((clusterId) => selectCardById(clusterId)(state))
    .filter(Boolean) as (CardData | ClusterData)[]) || [];
