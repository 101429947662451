import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchEditorClusters } from './asyncs';

type StateEditorType = {
  id: EditorType['id'] | null;
  puids: PuidsType;
  clustersIds: CardData['id'][];
} & Fetchable;

const editorSlice = createSlice({
  name: PAGE_TYPE.editor,
  initialState: {
    id: null,
    puids: {},
    clustersIds: [],
    fetching: false,
  } as StateEditorType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setEditorPuids: (state, action: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_tag`.toUpperCase(),
      };
    },

    /**
     * Установка id автора/редактора.
     * @param action.payload - id автора/редактора.
     */
    setEditorId: (
      state,
      action: PayloadAction<EditorType['id'] | undefined>,
    ) => {
      if (!action.payload) return;

      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditorClusters.pending, (state) => {
        state.fetching = true;
        state.error = '';
      })
      .addCase(fetchEditorClusters.fulfilled, (state, { payload }) => {
        state.clustersIds = payload || [];
        state.fetching = false;
        state.error = '';
      })
      .addCase(fetchEditorClusters.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const editorReducer = editorSlice.reducer;

export const { setEditorPuids, setEditorId } = editorSlice.actions;
