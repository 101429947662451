import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectClusterPageCurrentClusterId } from 'common/redux/pages/cluster/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { TOPIC_ALIAS_MOVIES } from 'config/constants/topics';
import { adDoubleClickNet } from 'utils/counters/adDoubleclickNet';

/**
 * Функция дополнения шаблона html верстки дополнительными тегами специфично для страницы кластера.
 * @param props.getState - функция получения актуального стейта.
 */
export const renderTemplate = ({ getState }: AppStore) => {
  const state = getState() as IAppState;

  const projectId = selectProjectId(state);
  const clusterId = selectClusterPageCurrentClusterId(state);
  const firstCluster = selectClusterById(clusterId)(state);

  // ${initPixelTemplate(id, topicId)} https://jira.rambler-co.ru/browse/NEWS-10376 пока не используем

  return `
    ${
      projectId === PROJECT_IDS.Kino &&
      firstCluster?.topicAlias === TOPIC_ALIAS_MOVIES
        ? adDoubleClickNet
        : ''
    }
  `;
};
