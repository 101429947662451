import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PuidsType } from 'config/constants/common';
import { PAGE_TYPE } from 'config/constants/routerName';

type StateGuideCountryCitiesType = {
  // Пуиды настройки для рекламы
  puids: PuidsType;
  // Алиасы городов отображаемых на странице
  citiesAliases: string[];
  // Alias текущей страны
  countryAlias: string;
};

const initialState: StateGuideCountryCitiesType = {
  puids: {
    puid6: 'RTRAVEL_COUNTRY',
    puid18: 'RTRAVEL_COUNTRY_CITIES',
  },
  citiesAliases: [],
  countryAlias: '',
};

const guideCountryCitiesSlice = createSlice({
  name: PAGE_TYPE.guideCountryCities,
  initialState,
  reducers: (create) => ({
    /**
     * Сохранение списка городов отображаемых на странице
     */
    setPageCities: create.reducer((state, action: PayloadAction<string[]>) => {
      // Тк нет загрузки по страницам то через просто присваивание
      state.citiesAliases = action.payload;
    }),

    /**
     * Сохранение страны
     */
    setPageCountryAlias: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.countryAlias = action.payload;
      },
    ),
  }),
});

export const { setPageCities, setPageCountryAlias } =
  guideCountryCitiesSlice.actions;
export const guideCountryCitiesReducer = guideCountryCitiesSlice.reducer;
