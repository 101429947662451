import { PROJECT_IDS } from 'config/constants/projects/constants';

import { TAG_WIDGET } from './typings';

export const HOT_NEWS_CLUSTERS_LENGTH = 1;

export const HOT_NEWS_TAG_NAMES_BY_PROJECT_CODES: {
  [x in PROJECT_IDS]?: string;
} = {
  [PROJECT_IDS.Woman]: 'psychologists-advice',
  [PROJECT_IDS.News]: 'hotnews',
};

export const OPINIONS_CLUSTERS_LENGTH = 1;

export const OPINIONS_TAG_NAMES_BY_PROJECT_CODES: {
  [x in PROJECT_IDS]?: string;
} = {
  [PROJECT_IDS.Finance]: 'mnenia-finance',
  [PROJECT_IDS.Sport]: 'mnenia-sport',
  [PROJECT_IDS.Woman]: 'week-recipes',
  [PROJECT_IDS.Doctor]: 'doctors-answer',
  [PROJECT_IDS.Kino]: 'week-films',
  [PROJECT_IDS.News]: 'mnenia',
};

export const TAG_NAMES_MAPS_BY_WIDGET_TYPE: Record<
  TAG_WIDGET.HotNews | TAG_WIDGET.Mnenia,
  { [x in PROJECT_IDS]?: string }
> = {
  [TAG_WIDGET.HotNews]: HOT_NEWS_TAG_NAMES_BY_PROJECT_CODES,
  [TAG_WIDGET.Mnenia]: OPINIONS_TAG_NAMES_BY_PROJECT_CODES,
};

export const TAG_CLUSTERS_LENGTH: Record<
  TAG_WIDGET.HotNews | TAG_WIDGET.Mnenia,
  number
> = {
  [TAG_WIDGET.HotNews]: HOT_NEWS_CLUSTERS_LENGTH,
  [TAG_WIDGET.Mnenia]: OPINIONS_CLUSTERS_LENGTH,
};

// Запрашиваем побольше, тк могут отфильтроваться и новостей будет меньше чем SAVING_TOP_LIMIT
export const FETCHING_TOP_LIMIT = 20;
