import {
  CaptureContext,
  ExtendedError,
  Scope,
  SeverityLevel,
} from '@sentry/types';

import { transformToSnakeCase } from 'utils/transformCase';

enum DataType {
  tag = 'tag',
  extra = 'extra',
}

export type UserType = {
  id?: string;
  ip_address?: string;
};

type ObjectType = Record<string, string>;

type SetSentryDataPropsType = {
  scope: Scope;
  type: DataType;
  data: ObjectType;
};

/**
 * Функция добавления данных к ошибке.
 * @param props.scope - scope sentry;
 * @param props.type - тип данных;
 * @param props.data - данные для добавления.
 */
const setSentryData = ({ scope, type, data }: SetSentryDataPropsType) => {
  const entries = Object.entries(data);
  const setData = type === DataType.tag ? 'setTag' : 'setExtra';

  entries.forEach(([key, value]) => {
    const transformKey = transformToSnakeCase(key);

    scope[setData](transformKey, value);
  });
};

type SendToSentryPropsType = {
  withScope: (callback: (scope: Scope) => void) => void;
  captureException: (exception: any, captureContext?: CaptureContext) => void;
};

type SentryMessageSendPropsType = {
  error: ExtendedError;
  level: SeverityLevel;
  tags?: ObjectType;
  extra?: ObjectType;
};

/**
 * Принимает модуль сентри для клиента или сервера и возвращает функцию для отправки ошибки на сервер сентри.
 */
export const sendToSentry =
  ({ withScope, captureException }: SendToSentryPropsType) =>
  ({ error, level, tags, extra }: SentryMessageSendPropsType) => {
    withScope((scope: Scope) => {
      scope.setLevel(level);

      if (tags) {
        setSentryData({ scope, type: DataType.tag, data: tags });
      }

      if (extra) {
        setSentryData({ scope, type: DataType.extra, data: extra });
      }

      captureException(error);
    });
  };

type GetSentryErrorPropsType = {
  errorType: string;
  status: string;
  message: string;
  entrypoint: string;
};

/**
 * Функция создания ошибки запроса для sentry.
 * @param props.errorType - имя ошибки;
 * @param props.status - статус ошибки;
 * @param props.message - сообщение ошибки;
 * @param props.entrypoint - точка сбора метрик.
 */
export const getSentryError = ({
  errorType,
  status,
  message,
  entrypoint,
}: GetSentryErrorPropsType) =>
  new Error(
    [
      errorType,
      __BROWSER__ ? 'Client' : 'Server',
      status,
      message,
      entrypoint,
    ].join(' | '),
  );
