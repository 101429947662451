const DIR_PLAYER_TEMPLATE_ID = 11350;

/**
 * Хук для вычисления player_template_id
 * @param templateIdFromHtml - распарсеный из html главного видео template_id
 */
export const usePlayerTemplateId = (
  templateIdFromHtml: number | undefined,
  isAd: boolean,
) => {
  if (!isAd) {
    return DIR_PLAYER_TEMPLATE_ID;
  }

  return templateIdFromHtml;
};
