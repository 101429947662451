enum Banners {
  '100x70' = '100x70',
  '240x400' = '240x400',
  '240x400_2' = '240x400_2',
  Billboard = 'billboard',
  BillboardCommercial1 = 'billboard_commercial_1',
  BillboardCommercial2 = 'billboard_commercial_2',
  BillboardCommercial3 = 'billboard_commercial_3',
  Content1 = 'content1',
  Content2 = 'content2',
  Content3 = 'content3',
  Content4 = 'content4',
  ContentSpec = 'content_spec',
  Context = 'context',
  ContextRight = 'context_right',
  ContextRight2 = 'context_right_2',
  Fonbet = 'fonbet',
  Footer = 'footer',
  Fullscreen = 'fullscreen',
  Ingallery = 'ingallery',
  Inpage = 'inpage',
  Inread = 'inread',
  Listing1 = 'listing1',
  Listing2 = 'listing2',
  Listing3 = 'listing3',
  Listing4 = 'listing4',
  Native = 'native',
  NativePartner = 'native_partner',
  Parallax = 'parallax',
  Rich = 'rich',
  Spec = 'spec',
  Superfooter = 'superfooter',
  TopBanner = 'top_banner',
  Topline = 'topline',

  /**
   * Временный креатив из задачки
   * https://jira.rambler-co.ru/browse/NEWS-11618
   * Должен быть спилен через месяц после её закрытия
   */
  PromoWidget = 'promowidget',
  ShowUp = 'show_up',
}

export default Banners;
