const MODIFY_FINAL_POSITION = -65;

/**
 * Функция, передвигающая скролл в начало элемента.
 * @param elementNode - node узел элемента.
 */
export const scrollToTop = (elementNode: HTMLElement | null) => {
  if (elementNode) {
    const articleTopOffset = elementNode.getBoundingClientRect().top;
    const neededScrollDistance = articleTopOffset + MODIFY_FINAL_POSITION;

    window.scrollBy({ top: neededScrollDistance, behavior: 'smooth' });
  }
};
