import { PayloadAction } from '@reduxjs/toolkit';

import { selectTravelCountryAliases } from 'common/redux/commonData/travelCountries/selectors';
import { createSlice } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PAGE_TYPE } from 'config/constants/routerName';

import { selectGuideCurrentCard } from './selectors';

const SLICE_STEP = 20;
export const START_CARD = 0;

type StateGuideType = {
  // Массив с алиасами стран
  regionIds: string[];
  // Пуиды настройки для рекламы
  puids: PuidsType;
  // Номер текущей последней карточки
  currentCard: number;
  // Флаг, есть ли следующая страница
  hasNextPage: boolean;
  // Флаг, что идет загрузка
  fetching: boolean;
  // Текст ошибки
  error: string;
};

const guideSlice = createSlice({
  name: PAGE_TYPE.guide,
  initialState: {
    regionIds: [],
    puids: {
      puid6: 'RTRAVEL_GUIDES',
      puid18: 'RTRAVEL_GUIDES_MAIN',
    },
    currentCard: START_CARD,
    hasNextPage: false,
    fetching: false,
    error: '',
  } as StateGuideType,
  reducers: (create) => ({
    /**
     * Обновление информации о номере текущей карточки.
     */
    setGuidePage: create.reducer(
      (state, action: PayloadAction<StateGuideType['currentCard']>) => {
        state.currentCard = action.payload;
      },
    ),

    /**
     * Обновление информации о том, есть ли следующая страница
     */
    setGuideHasNextPage: create.reducer(
      (state, action: PayloadAction<StateGuideType['hasNextPage']>) => {
        state.hasNextPage = action.payload;
      },
    ),

    /**
     * Функция обновления списка алиасов стран.
     */
    fetchGuideCountries: create.asyncThunk(
      async (_props, { getState, dispatch }) => {
        const allCountryAliases = selectTravelCountryAliases(
          getState() as IAppState,
        );
        const currentCard = selectGuideCurrentCard(getState() as IAppState);

        if (allCountryAliases.length === 0 || !allCountryAliases) {
          throw new Error('Массив с алиасами стран пустой');
        }

        const guideCountryAliases = allCountryAliases.slice(
          currentCard,
          currentCard + SLICE_STEP,
        );

        dispatch(setGuidePage(currentCard + SLICE_STEP));
        dispatch(
          setGuideHasNextPage(
            allCountryAliases.length > currentCard + SLICE_STEP,
          ),
        );

        return guideCountryAliases;
      },

      {
        pending: (state) => {
          state.fetching = true;
          state.error = '';
        },
        fulfilled: (state, { payload }) => {
          state.regionIds = [...state.regionIds, ...payload];
          state.fetching = false;
          state.error = '';
        },
        rejected: (state, { error }) => {
          state.error = error.message || '';
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),
  }),
  selectors: {
    selectGuideHasNextPage: (state) => state.hasNextPage,
  },
});

export const { selectGuideHasNextPage } = guideSlice.selectors;
export const { setGuidePage, setGuideHasNextPage, fetchGuideCountries } =
  guideSlice.actions;

export const guideReducer = guideSlice.reducer;
