import { AUTOTAG_TYPE, CLUSTER_TYPE_ALIAS } from './cluster';

export const TAB_NAME_CLUSTER_TYPES: CLUSTER_TYPE_ALIAS[] = [
  CLUSTER_TYPE_ALIAS.article,
  CLUSTER_TYPE_ALIAS.news,
  CLUSTER_TYPE_ALIAS.test,
  CLUSTER_TYPE_ALIAS.photo,
  CLUSTER_TYPE_ALIAS.video,
];

export const AUTO_TAG_TYPES = [
  AUTOTAG_TYPE.person,
  AUTOTAG_TYPE.organization,
  AUTOTAG_TYPE.media,
  AUTOTAG_TYPE.auto,
  AUTOTAG_TYPE.movie,
];
