import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchExpertClusters } from './asyncs';

type StateExpertType = {
  id: ATExpertType['id'] | null;
  puids: PuidsType;
  clustersIds: CardData['id'][];
} & Fetchable;

const expertSlice = createSlice({
  name: PAGE_TYPE.expert,
  initialState: {
    id: null,
    puids: {},
    clustersIds: [],
    fetching: false,
    error: '',
  } as StateExpertType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setExpertPuids: (state, action: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_tag`.toUpperCase(),
      };
    },

    /**
     * Установка id эксперта.
     * @param action.payload - id эксперта.
     */
    setExpertId: (
      state,
      action: PayloadAction<ATExpertType['id'] | undefined>,
    ) => {
      if (!action.payload) return;

      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpertClusters.pending, (state) => {
        state.fetching = true;
        state.error = '';
      })
      .addCase(fetchExpertClusters.fulfilled, (state, { payload }) => {
        state.clustersIds = payload || [];
        state.fetching = false;
        state.error = '';
      })
      .addCase(fetchExpertClusters.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const expertReducer = expertSlice.reducer;

export const { setExpertPuids, setExpertId } = expertSlice.actions;
