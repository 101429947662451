import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { matchType } from 'common/redux/utils';

import { currenciesAdapter } from './adapter';
import {
  fetchCurrenciesInfo,
  fetchCurrenciesRates,
  fetchCurrenciesRatesFromPeriod,
  fetchCurrencyInfo,
  fetchCurrencyRates,
  fetchCurrencyRatesFromPeriod,
} from './asyncs';
import {
  CharCodeToIdType,
  CurrenciesCharCodeToIdType,
  CurrenciesValuesByIdType,
  CurrenciesValuesByPeriodType,
  FetchCurrencyRatesPayloadType,
} from './typings';

const thunks = [
  fetchCurrenciesInfo,
  fetchCurrenciesRates,
  fetchCurrenciesRatesFromPeriod,
  fetchCurrencyInfo,
  fetchCurrencyRates,
];

/**
 * Стейт валют.
 * @param entries - объект с ключ: id валюты - значение: информация о валюте;
 * @param fetching - флаг, что происходит загрузка хотя бы одной валюты;
 * @param error - сообщение последней ошибки, произошедшей при загрузке валюты;
 * @param currenciesValuesById - объект с ключ: id валюты - значение: массив объектов values с датой и котировкой валюты;
 * @param ratesByPeriod - котировки по периодам объект с ключ: id валюты - значение: массив объектов values с датой и котировкой валюты.
 * @param ratesByPeriod.fetching - флаг загрузки
 * @param ratesByPeriod.error - сообщение об ошибке
 * @param ratesByPeriod.data - объект с ключ: id валюты - значение: массив объектов values с датой и котировкой валюты.
 */
const currenciesSlice = createSlice({
  name: 'currencies',
  initialState: currenciesAdapter.getInitialState({
    fetching: false,
    error: '',
    currenciesValuesByPeriod: {},
    charCodeToId: {},
  } as Fetchable &
    CurrenciesValuesByIdType &
    CurrenciesValuesByPeriodType &
    CurrenciesCharCodeToIdType),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCurrenciesInfo.fulfilled,
        (state, { payload }: PayloadAction<CurrencyType[]>) => {
          currenciesAdapter.addMany(state, payload);

          state.charCodeToId = payload.reduce(
            (acc, currency) => {
              acc[currency.charCode] = currency.id;

              return acc;
            },
            {
              ...state.charCodeToId,
            } as CharCodeToIdType,
          );
          state.fetching = false;
        },
      )
      .addCase(
        fetchCurrencyInfo.fulfilled,
        (state, { payload }: PayloadAction<CurrencyType>) => {
          currenciesAdapter.addOne(state, payload);

          state.charCodeToId = {
            ...state.charCodeToId,
            [payload.charCode]: payload.id,
          };
          state.fetching = false;
        },
      )
      .addCase(
        fetchCurrencyRatesFromPeriod.fulfilled,
        (state, { payload }: PayloadAction<FetchCurrencyRatesPayloadType>) => {
          const { currencyRates, period } = payload;

          state.currenciesValuesByPeriod[period] = {
            ...state.currenciesValuesByPeriod[period],
            ...currencyRates,
          };
        },
      )
      .addCase(
        fetchCurrenciesRatesFromPeriod.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<CurrenciesValuesByIdType['currenciesValuesById']>,
        ) => {
          state.currenciesValuesById = payload;
          state.fetching = false;
        },
      )
      .addCase(
        fetchCurrenciesRates.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<CurrenciesValuesByIdType['currenciesValuesById']>,
        ) => {
          state.currenciesValuesById = payload;
          state.fetching = false;

          // по умолчанию в fetchCurrenciesRates приходят котировки за 3 дня
          state.currenciesValuesByPeriod.threeDays = payload;
        },
      )
      .addCase(
        fetchCurrencyRates.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<CurrenciesValuesByIdType['currenciesValuesById']>,
        ) => {
          state.currenciesValuesById = {
            ...state.currenciesValuesById,
            ...payload,
          };
          state.currenciesValuesByPeriod.threeDays = {
            ...state.currenciesValuesByPeriod.threeDays,
            ...payload,
          };
          state.fetching = false;
        },
      );
    // @ts-expect-error: поправить при переезде на новый синтаксис
    builder.addMatcher(matchType('pending', thunks), (state) => {
      state.fetching = true;
    });
    // @ts-expect-error: поправить при переезде на новый синтаксис
    builder.addMatcher(matchType('rejected', thunks), (state, { error }) => {
      state.error = error.message;
      state.fetching = false;
    });
  },
});

export const currenciesReducer = currenciesSlice.reducer;
export {
  fetchCurrenciesInfo,
  fetchCurrenciesRatesFromPeriod,
  fetchCurrenciesRates,
  fetchCurrencyInfo,
  fetchCurrencyRatesFromPeriod,
  fetchCurrencyRates,
};
