import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBankData } from 'api';
import { getApiBankData } from 'api/adapters';
import { selectApiConfig } from 'common/redux/runtime/selectors';

/**
 * Метод получения инфо по одному банку.
 * @param bankCode - код банка (пример sberbank, mcb).
 */
export const fetchSingleBankData = createAsyncThunk(
  'fetchSingleBankData',
  async (bankCode: string, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: bankData, error } = await getBankData(apiConfig, bankCode);

    if (!bankData?.length || error) {
      throw new Error(`Не удалось получить данные по коду ${bankCode}`);
    }

    return getApiBankData(bankData[0]);
  },
);
