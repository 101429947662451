import { combineReducers } from '@reduxjs/toolkit';

import { appControllerReducer } from 'common/redux/appController';
import { commonDataReducer } from 'common/redux/commonData';
import { pagesReducer } from 'common/redux/pages';
import { runtimeReducer } from 'common/redux/runtime';

export default combineReducers({
  appController: appControllerReducer,
  commonData: commonDataReducer,
  pages: pagesReducer,
  runtime: runtimeReducer,
});
