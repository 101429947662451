import { RecommendationBlockItemType } from '@media-components/recommendation-block';

import {
  BLOCK_TYPES,
  ENTITY_TYPES,
} from 'common/components/ClusterContentOnDraft/typings';
import { RECORD_ID_REGEXP } from 'common/components/VideoPlayer/hooks/useVideoParams';
import { selectIsBannedCommentsTopic } from 'common/redux/runtime/selectors';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { RCMclick, RCMshow } from 'utils/counters/atdRecommender';

import { getUrlWithUtmParams } from '../getUrlWithUtmParams';
import { getUtmParamsTopic } from '../getUtmParamsTopic';
import { generateClusterUrl, swapUrlToProxyDomain } from '../urlGenerator';

const isLiveVideoFlag = 'data-live="true"';

// Ссылки с хостом 'cdn-store' проксируются на s3
const CHECK_FOR_CDN_STORE = 'cdn-store';

export const CLUSTER_DEFAULT: APICluster = {
  body: '',
  body_short: '',
  is_paid: false,
  items_count: 0,
  creation_time: '',
  is_commercial: false,
  gallery: [],
  main_video: undefined,
  is_trash: false,
  id: null,
  title: '',
  long_title: '',
  status: null,
  no_comments: false,
  cluster_type: null,
  image: undefined,
  topic: {} as TopicType,
  annotation: '',
  publication_time: '',
  modification_time: '',
  normalized_title: '',
  comments_count: 0,
  mentions: {
    title: [],
    body: [],
  },
  display_type: undefined,
  draft: {} as RawDraftContentState,
  is_new_draft: false,
};

/**
 * Вспомогательная функция, извлекающая топик из урла кластера.
 * @param url - ссылка на кластер.
 */
const getTopicFromUrl = (url: string) =>
  url.replace(/^http(s)?:\/\/.*?\//gi, '').replace(/\/.*/gi, '');

const isBannedComments = (url: string, variables: Runtime['variables']) =>
  selectIsBannedCommentsTopic(getTopicFromUrl(url))({
    runtime: { variables },
  } as IAppState);

type AdaptClusterToCardRcmType = (props: {
  cluster: ATRcmType;
  projectId: ProjectType['id'];
  domainConfig: DomainConfigType;
  variables: Runtime['variables'];
  commentsCount?: number;
}) => CardData;

/**
 * Адаптер объекта кластера из рекомендаций в объект данных для карточки
 * @param props.cluster - объект кластера из рекомендаций;
 * @param props.projectId - id проекта
 * @param props.domainConfig - объект данных о домене
 * @param props.variables - список переменных, полученных из админки Главной
 * @param props.commentsCount - количество комментариев, отображаемых в карточке;
 */
export const adaptClusterToCardRcm: AdaptClusterToCardRcmType = ({
  cluster: {
    item,
    itemID: id,
    itemTitle: title,
    itemPubDate,
    description,
    itemUrl: url,
    itemImage,
    displayType,
    topic,
  },
  projectId,
  domainConfig,
  variables,
  commentsCount,
}) => ({
  id: String(id),
  item,
  title,
  normalizedTitle: '',
  description,
  url: swapUrlToProxyDomain({ domainConfig, projectId, url }),
  image: {
    url: itemImage,
    s3: itemImage.includes(CHECK_FOR_CDN_STORE),
    source: {
      url: '',
      title: '',
    },
  },
  video: {
    url: null,
  },
  displayType,
  topic,
  publicationTime: itemPubDate,
  // Рекоменды не отдают два нижних поля
  creationTime: '',
  modificationTime: '',
  commentsCount,
  noComments: isBannedComments(url, variables),
  resourcesCount: 0,
  isRec: true,
});

type AdaptClusterToCardType = (props: {
  cluster: (APICluster | APICard) & { url?: string };
  domainConfig: DomainConfigType;
  variables: Runtime['variables'];
}) => CardData;

/**
 * Адаптер объекта кластера в объект данных для карточки
 * @param cluster - объект кластера с данными
 * @param domainConfig - объект данных о домене
 * @param variables - внешние переменные
 */
export const adaptClusterToCard: AdaptClusterToCardType = ({
  cluster,
  domainConfig,
  variables,
}) => ({
  id: String(cluster.id),
  type: cluster.cluster_type ?? undefined,
  title: cluster.title,
  normalizedTitle: cluster.normalized_title ?? '',
  description: cluster?.long_title,
  url:
    // Если снаружи уже сгенерирован url для кластера, не пытаемся ничего делать
    cluster.url ??
    generateClusterUrl({
      domainConfig,
      clusterId: String(cluster.id),
      normalizedTitle: cluster.normalized_title,
      addDomain: true,
      topic: cluster.topic,
    }),
  image: {
    url: cluster.image?.url ?? '',
    width: cluster.image?.width || null,
    height: cluster.image?.height || null,
    s3: !!cluster.image?.s3,
    description: cluster.image?.description || '',
    id: cluster.image?.id || null,
    source: {
      url: cluster.image?.source?.url ?? '',
      title: cluster.image?.source?.title ?? '',
    },
  },
  displayType: cluster.display_type,
  topic: cluster.topic?.name ?? '',
  mainTopicId: cluster.topic?.id ?? undefined,
  topicUrl: cluster.topic?.url ?? '',
  topicAlias: cluster.topic?.alias ?? '',
  annotation: cluster.annotation ?? '',
  publicationTime: cluster.publication_time ?? '',
  modificationTime: cluster.modification_time ?? '',
  creationTime: 'creation_time' in cluster ? cluster.creation_time : '',
  // Специально оставил без фоллбечного значения, чтобы не было ошибок рассчета
  commentsCount: cluster.comments_count,
  noComments:
    cluster.no_comments || isBannedComments(cluster?.topic?.alias, variables),
  resourcesCount: (cluster as APICluster).items_count,
  video: {
    duration: cluster.main_video?.details?.duration ?? 0,
    url: cluster.main_video?.details?.file_url ?? '',
    preview: cluster.main_video?.details?.image ?? '',
    recordId:
      cluster.main_video?.details?.record_id ??
      cluster.main_video?.embed_code?.match(RECORD_ID_REGEXP)?.[1] ??
      '',
    isLive: cluster.main_video?.embed_code?.includes(isLiveVideoFlag) ?? false,
    embedCode: cluster.main_video?.embed_code ?? '',
    videoData: cluster.main_video?.video_data || null,
  },
  isRec: false,
});

type AdaptClusterSourceToCardType = (props: {
  cluster: ATClusterItem;
  domainConfig: DomainConfigType;
  variables: Runtime['variables'];
  projectAlias: ProjectType['alias'];
  isMobile: SettingsType['isMobile'];
}) => CardData;

/**
 * Адаптер данных источника кластера в объект данных для карточки
 * @param cluster - данные источника кластера
 * @param projectAlias - alias проекта
 * @param domainConfig - объект данных о домене
 * @param variables - внешние переменные
 * @param isMobile – флаг мобильной версии
 */
export const adaptClusterSourceToCard: AdaptClusterSourceToCardType = ({
  cluster,
  projectAlias,
  domainConfig,
  variables,
  isMobile,
}) => {
  const {
    resource_id: resourceId,
    id,
    pubdate,
    title,
    url,
    resource,
  } = cluster;

  const utmParamsTopic = getUtmParamsTopic({
    resourceId,
    projectAlias,
    isMobile,
  });

  return adaptClusterToCard({
    cluster: {
      ...CLUSTER_DEFAULT,
      id,
      publication_time: pubdate,
      title,
      url: getUrlWithUtmParams({
        url,
        params: { source: `r${projectAlias}` },
      }),
      topic: {
        id: resource.id,
        name: resource.title,
        alias: '',
        status: null,
        project_id: null,
        details: null,
        url:
          getUrlWithUtmParams({ url: resource.url, params: utmParamsTopic }) ||
          '',
      },
    },
    domainConfig,
    variables,
  });
};

type AdaptCardToClusterType = (props: {
  card: CardData;
  body?: APICluster['body'];
  annotation?: APICluster['annotation'];
  longTitle?: APICluster['long_title'];
  draft?: APICluster['draft'];
  gallery?: APICluster['gallery'];
  isCommercial?: APICluster['is_commercial'];
  isPaid?: APICluster['is_paid'];
  isNewDraft?: APICluster['is_new_draft'];
}) => ClusterData;

/**
 * Адаптер, расширяющий тип карточки до типа кластера.
 * @param props - объект с данными карточки и уточняющими данными кластера;
 * @param props.card - данные карточки, которые лягут в основу кластера;
 * @param props.annotation - данные аннотации кластера;
 * @param props.longTitle - расширенный заголовок кластера;
 * @param props.draft - драфт кластера, если есть;
 * @param props.gallery - данные о галлерее кластера;
 * @param props.isCommercial - флаг, что кластер коммерческий Лонгрид;
 * @param props.isPaid - флаг, что кластер оплаченный Партнерский материал;
 * @param props.isNewDraft - флаг, что для этого кластера сгенерирован новый формат драфта.
 * @returns Объект кластера.
 */
export const adaptCardToCluster: AdaptCardToClusterType = ({
  card,
  body,
  annotation,
  longTitle,
  draft,
  gallery,
  isCommercial,
  isPaid,
  isNewDraft,
}) => ({
  ...card,
  body: body ?? '',
  annotation: annotation ?? '',
  longTitle: longTitle ?? '',
  resourceId: undefined,
  editorId: null,
  manualTagIds: [],
  autotagIds: [],
  related: [],
  topicIds: [],
  themeIds: [],
  expertIds: [],
  itemIds: [],
  mainItemId: null,
  puids: {},
  draft,
  gallery: gallery ?? [],
  isCommercial: isCommercial ?? false,
  isPaid: isPaid ?? false,
  isNewDraft: isNewDraft ?? false,
  isArticle: false,
});

const IGNORE_DRAFT_BLOCKS = [ENTITY_TYPES.RAdvTurbo];

/**
 * Фильтрация от пустых блоков, чтобы изображение кластера гарантировано вставлялось после первого абзаца текста (ex. 51823661, 51895761)
 * @param block.type – тип блока
 * @param block.text – текст блока
 * @param block.entityRanges – вставки для блока
 */
const isEmptyBlock = ({
  type,
  text,
  entityRanges,
}: RawDraftContentState['blocks'][number]) => {
  const typesForCheck = [BLOCK_TYPES.Unstyled, BLOCK_TYPES.Paragraph];

  return (
    typesForCheck.includes(type as BLOCK_TYPES) &&
    !text.trim() &&
    !entityRanges.length
  );
};

/**
 * Функция фильтрации от блоков драфта которые мы не обрабатываем. На данный момент это RAdvTurbo и пустые блоки.
 * Важно!!! Если он когда нибудь понадобится или без него нельзя будет обойтись нужно будет придумать что нибудь,
 * чтобы он не вставлялся посередине списков и не ломал нумерацию как на кластере 44097221 или 52637339, а также чтобы
 * в кластере 51794435 реклама не вставала в конец кластера @see checkCanInsertBanners
 * @param draft – объект кластера для драфта.
 */
const filterDraftUnusedBlocks = (
  draft: APICluster['draft'],
): RawDraftContentState => {
  if (!Array.isArray(draft?.blocks)) return draft;

  const newBlocks = draft.blocks.filter((block, index) => {
    const isAdvTurboBlock = block.entityRanges.some(({ key }) =>
      IGNORE_DRAFT_BLOCKS.includes(draft.entityMap[key].type as ENTITY_TYPES),
    );

    if (index === 0) {
      return !isAdvTurboBlock && !isEmptyBlock(block);
    }

    return !isAdvTurboBlock;
  });

  return { ...draft, blocks: newBlocks };
};

type AdaptClusterToClusterExtendedType = (props: {
  cluster: APICluster;
  domainConfig: DomainConfigType;
  variables: Runtime['variables'];
}) => ClusterData;

/**
 * Расширенный тип карточки.
 * @param cluster - объект кластера с данными;
 * @param domainConfig - объект данных о домене
 * @param variables - внешние переменные
 */
export const adaptClusterToClusterExtended: AdaptClusterToClusterExtendedType =
  ({ cluster, domainConfig, variables }) =>
    adaptCardToCluster({
      card: adaptClusterToCard({
        cluster,
        domainConfig,
        variables,
      }),
      body: cluster.body,
      annotation: cluster.annotation,
      longTitle: cluster.long_title,
      draft: filterDraftUnusedBlocks(cluster.draft),
      gallery: cluster.gallery,
      isCommercial: cluster.is_commercial,
      isPaid: cluster.is_paid,
      isNewDraft: cluster.is_new_draft,
    });

/**
 * Type guard для разделения объекта кластера и объекта карточки (у них разное содержимое)
 * @param cluster - объект кластера или карточки для проверки на тип.
 */
export const isClusterData = (
  cluster: CardData | ClusterData | null,
): cluster is ClusterData => (cluster ? 'body' in cluster : false);

type AdaptClustersToCardsType = (props: {
  clusters: ((APICluster | APICard) & { url?: string })[] | undefined | null;
  domainConfig: DomainConfigType;
  variables: Runtime['variables'];
  isAbsoluteUrl?: boolean;
}) => CardData[];

/**
 * Обертка над адаптером, превращающая массив кластеров в безопасный массив карточек.
 * @param clusters - массив кластеров
 * @param domainConfig - объект данных о домене
 * @param variables - внешние переменные
 */
export const adaptClustersToCards: AdaptClustersToCardsType = ({
  clusters,
  domainConfig,
  variables,
}) => {
  if (Array.isArray(clusters)) {
    return clusters
      .filter((cluster) => cluster)
      .map((cluster) =>
        adaptClusterToCard({
          cluster,
          domainConfig,
          variables,
        }),
      );
  }

  return [];
};

type AdaptClusterDataToRcmBlockItemsPropsType = {
  clusters: CardData[];
  blockId?: string;
  contextItemId?: RCM_BLOCK_TYPE;
};

/**
 * Функция трансформации данных кластера в данные для виджета рекомендаций.
 * @param props.clusters - массив карточек кластеров;
 * @param props.blockId - id рекомендательного блока;
 * @param props.contextItemId - идентификатор материала, на котором показывается блок рекомендаций.
 */
export const adaptClusterDataToRcmBlockItems = ({
  clusters,
  blockId,
  contextItemId,
}: AdaptClusterDataToRcmBlockItemsPropsType): RecommendationBlockItemType[] =>
  clusters.map(
    (cluster, index) =>
      ({
        /**
         * Данные ниже мы отбрасываем при адаптации.
         * Если они все таки понадобятся, их можно попытаться сохранить.
         */
        _id: '',
        feed_title: '',
        rcm_id: '',
        img_color: '',
        show_id: '',
        item_author: '',
        item_category: [],
        feed_id: blockId,
        item_id: cluster.id,
        item_title: cluster.title,
        item_description: cluster.description,
        item_image: cluster.image.url,
        item_pubdate: cluster.publicationTime,
        item_url: cluster.url,
        comments_count: cluster.commentsCount,
        onClick: () => {
          if (!cluster.item || !blockId || !contextItemId) return;

          RCMclick({
            blockId,
            item: cluster.item,
            position: index + 1,
            contextItemId,
          });
        },
        onShow: () => {
          if (!cluster.item || !blockId || !contextItemId) return;

          RCMshow({
            blockId,
            item: cluster.item,
            position: index + 1,
            contextItemId,
          });
        },
      }) as RecommendationBlockItemType,
  );
