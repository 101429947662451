export const COMMON_COUNTER_ID = '85474414';

export enum COUNTER_ID {
  Portal = 'ya_metrika_portal',
  WebMobile = 'ya_metrika_web_mob',
  Common = 'ya_metrika_common',
}

export enum DEVICE_TAG {
  MOBILE = 'mob',
  DESKTOP = 'web',
}
