import { createSlice } from '@reduxjs/toolkit';

import { resourcesAdapter } from './adapter';
import { fetchResourceByCluster } from './asyncs';

/**
 * Стейт источников.
 * @param entries - объект с ключ: id источника - значение: информация об источнике;
 * @param fetching - флаг, что происходит загрузка хотя бы одного источника;
 * @param error - сообщение последней ошибки, произошедшей при загрузке источника.
 */
const resourcesSlice = createSlice({
  name: 'resources',
  initialState: resourcesAdapter.getInitialState({
    fetching: false,
    error: '',
  } as Fetchable),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchResourceByCluster.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(
      fetchResourceByCluster.fulfilled,
      (state, { payload: resource }) => {
        // Если появится новая информация - перезаписывается старая
        resourcesAdapter.upsertOne(state, {
          ...resource,
          fetching: false,
          error: '',
        });
        state.fetching = false;
      },
    );

    builder.addCase(fetchResourceByCluster.rejected, (state, error) => {
      state.fetching = false;
      state.error = error.error.message;
    });
  },
});

export const resourcesReducer = resourcesSlice.reducer;
