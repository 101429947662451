import { createSlice } from '@reduxjs/toolkit';

import { rnetWidgetAdapter } from './adapter';
import { fetchRnetNews } from './asyncs';

/**
 * Слайс виджета новости источника.
 */
const rnetWidgetSlice = createSlice({
  name: 'rnetWidget',
  initialState: rnetWidgetAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRnetNews.pending, (state, { meta }) => {
      const { blockId } = meta.arg;

      rnetWidgetAdapter.upsertOne(state, {
        id: blockId,
        fetching: true,
        error: '',
        bannerIds: null,
      });
    });

    builder.addCase(fetchRnetNews.fulfilled, (state, { payload, meta }) => {
      const { blockId } = meta.arg;

      rnetWidgetAdapter.upsertOne(state, {
        id: blockId,
        bannerIds: payload,
        fetching: false,
        error: '',
      });
    });

    builder.addCase(fetchRnetNews.rejected, (state, { error, meta }) => {
      const { blockId } = meta.arg;

      rnetWidgetAdapter.upsertOne(state, {
        id: blockId,
        bannerIds: null,
        fetching: false,
        error: error.message,
      });
    });
  },
});

export const rnetReducer = rnetWidgetSlice.reducer;
