import { RCM_BLOCK_TYPE } from 'config/constants/rcm';

import { selectCommonData } from '../selectors';

import { recommendedClustersAdapter } from './adapter';

const selectors = recommendedClustersAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).recommendedClusters,
);

export const selectRecommendedClusters = (state: IAppState) =>
  selectCommonData(state).recommendedClusters;

export const selectAllRecommendedClusters = (state: IAppState) =>
  selectors.selectAll(state);

export const selectRecommendedClustersByIds =
  (ids: CardData['id'][]) => (state: IAppState) =>
    ids
      .map((id) => id && selectors.selectById(state, id))
      .filter((cluster) => !!cluster) as Omit<
      ATRcmType,
      | 'description'
      | 'displayType'
      | 'itemTitle'
      | 'itemPubDate'
      | 'itemUrl'
      | 'itemImage'
      | 'top'
    >[];

export const selectRecommendedClustersIdsByBlockType =
  (rcmBlockType: RCM_BLOCK_TYPE) => (state: IAppState) =>
    selectRecommendedClusters(state)[rcmBlockType]?.clusterIds || [];

export const selectRecommendedClustersSessionID =
  (rcmBlockType: RCM_BLOCK_TYPE) => (state: IAppState) =>
    selectRecommendedClusters(state)[rcmBlockType]?.sessionID || '';

export const selectRecommendedClustersByBlockType =
  (rcmBlockType: RCM_BLOCK_TYPE) => (state: IAppState) => {
    const clustersIds =
      selectRecommendedClustersIdsByBlockType(rcmBlockType)(state);

    return selectRecommendedClustersByIds(clustersIds)(state) || [];
  };

export const selectRecommendedClusterById =
  (clusterId: ATRcmType['itemID']) => (state: IAppState) =>
    selectors.selectById(state, clusterId);
