import {
  PROJECT_ALIAS_BY_ID,
  PROJECT_ALIASES,
  PROJECT_IDS,
} from 'config/constants/projects/constants';

export const NEWS_EXCLUDE_TOPICS_IDS = [
  // "Проишествия"
  'incidents',
  // "Армия"
  'army',
  // "Шоубизнес"
  'starlife',
  // "Игры"
  'games',
];

export const AUTO_EXCULDE_TOPICS_IDS = [
  // ДТП
  'roadaccidents',
];

export const DOCTOR_EXCLUDE_TOPICS_IDS = [
  // Народная медицина
  'folkmedicine',
];

export const WOMAN_TO_WEEKEND_EXCLUDE_TOPICS_IDS = [
  // "Любовь"
  'love',
  // "Здоровье"
  'health',
  // "Красота"
  'beauty',
  // "Психология"
  'psychology',
];

export const WOMAN_TO_DOCTOR_EXCLUDE_TOPICS_IDS = [
  // "Любовь"
  'love',
  // "Мода"
  'fashion',
];

export const WOMAN_TO_TRAVEL_EXCLUDE_TOPICS_IDS = [
  // "Любовь"
  'love',
  // "Здоровье"
  'health',
  // "Дом и Сад"
  'home',
  // "Психология"
  'psychology',
];

// Особые рубрики, которые нужно загружать для виджетов вертикалей
export const TECH_TOPIC_ID = 172;
export const STARS_TOPIC_ID = 286;
export const STARLIFE_TOPIC_ID = 175;

export const TEST_GREEN_PROJECT = [PROJECT_ALIASES.Sport];

export const GREEN_PROJECT_IDS = [
  PROJECT_IDS.Family,
  PROJECT_IDS.Pro,
  PROJECT_IDS.Dom,
];

export const MIXED_PROJECT_IDS = [
  PROJECT_IDS.Finance,
  PROJECT_IDS.Weekend,
  PROJECT_IDS.Doctor,
  PROJECT_IDS.Auto,
];

export const GREEN_AND_MIXED_PROJECT_IDS = [
  ...GREEN_PROJECT_IDS,
  ...MIXED_PROJECT_IDS,
];

export const GREEN_PROJECT_ALIASES = GREEN_PROJECT_IDS.map(
  (id) => PROJECT_ALIAS_BY_ID[id],
);

export const MIXED_PROJECT_ALIASES = MIXED_PROJECT_IDS.map(
  (id) => PROJECT_ALIAS_BY_ID[id],
);
