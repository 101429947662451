import { UID } from '@adtech/adtech-uid';

const UIDFactory = new UID();

/**
 * Добавление дополнтительных параметров для клиентских запросов в рекоменды
 * @param path - урл запроса с параметрами
 */
export const addParamsInPathRecommended = async (path: string) => {
  const uid = UIDFactory.getID();

  return uid?.uuid && uid?.scope
    ? `${path}&adtech_uid=${uid.uuid}&adtech_uid_scope=${uid.scope}`
    : path;
};
