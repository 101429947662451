import loadable from '@loadable/component';
import React from 'react';

import { fetchHoroscopeName } from 'common/redux/commonData/widgets/horoscopeNameWidget/asyncs';
import {
  setAutotagInfo,
  setAutotagPuids,
  setAutotagSeo,
} from 'common/redux/pages/autotag';
import {
  fetchAutotagInfo,
  fetchAutotagClustersWithComments,
  fetchAutotagRating,
} from 'common/redux/pages/autotag/asyncs';
import { selectAutotagDate } from 'common/redux/pages/autotag/selectors';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { AUTO_TAG_TYPES } from 'config/constants/autotag';
import { AUTOTAG_TYPE } from 'config/constants/cluster';
import { HOROSCOPE_NAMES } from 'config/constants/horoscopeNames';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const AutotagDesktop = loadable(() => import('desktop/pages/Autotag'));
const AutotagMobile = loadable(() => import('mobile/pages/Autotag'));

type AutotagMatchRoute = {
  autotagType: AUTOTAG_TYPE;
  autotagAlias: string;
};

/**
 * Роут автотега
 * https://news.rambler.ru/organization/briks/
 * https://news.rambler.ru/person/sobyanin-sergey/
 */
export const autotagRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<AutotagMatchRoute> => ({
  name: PAGE_TYPE.autotag,
  exact: true,
  path: `/:autotagType(${AUTO_TAG_TYPES.join('|')})/:autotagAlias/`,
  render: isMobile ? () => <AutotagMobile /> : () => <AutotagDesktop />,
  fetchData: async (
    { getState, dispatch },
    { params: { autotagType, autotagAlias } },
    queryParams,
  ) => {
    const page = Number(queryParams?.page) || 1;

    const projectAlias = selectProjectAlias(getState());
    const { dateTo, dateFrom } = selectAutotagDate(getState());

    dispatch(setAutotagPuids(projectAlias));
    dispatch(setAutotagInfo({ type: autotagType, alias: autotagAlias }));

    const promiseList: PromiseListType = [
      // Получние информации по автотегу
      dispatch(fetchAutotagInfo({ type: autotagType, alias: autotagAlias })),
      // Получние кластеров
      dispatch(
        fetchAutotagClustersWithComments({
          type: autotagType,
          alias: autotagAlias,
          dateFrom,
          dateTo,
          page,
        }),
      ),
    ];

    // Кнопка рейтинга персон или организаций
    if (
      [AUTOTAG_TYPE.person, AUTOTAG_TYPE.organization].includes(autotagType)
    ) {
      promiseList.push(
        dispatch(
          fetchAutotagRating({ type: autotagType, alias: autotagAlias }),
        ),
      );
    }

    // Виджет гороскопа на странице персоны
    if (autotagType === AUTOTAG_TYPE.person) {
      const nameAlias = HOROSCOPE_NAMES[autotagAlias];

      if (nameAlias) {
        promiseList.push(dispatch(fetchHoroscopeName({ nameAlias })));
      }
    }

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? AutotagMobile : AutotagDesktop,
        key: Object.keys({ AutotagDesktop })[0],
      }),
    );

    dispatch(setAutotagSeo(getState()));
  },
});
