export enum CUSTOM_DEBUG {
  forceClusterFeed = 'forceClusterFeed',
}

export const SWITCHER_ON = '1';
export const SWITCHER_OFF = '0';

export const debugModeQuery = '3FLkEUEGk';
export const disableAdsQuery = 'GFM6EbBwX';
export const disableAnalyticsQuery = 'jNQC2Gyuu';
// TODO(NEWS-0000): только для теста, после – удалить
export const forceArticleQuery = 'forceArticle';

export enum ACCEPTED_SWITCHERS {
  debugMode = debugModeQuery,
  disableAds = disableAdsQuery,
  disableAnalytics = disableAnalyticsQuery,
  forceAdFallback = 'forceAdFallback',
  forceBot = 'forceBot',
  forceRussia = 'forceRussia',
  forceArticle = forceArticleQuery,

  // Тумблеры с админки
  gigaEnabled = 'gigaEnabled',
  promoWidgetEnabled = 'promoWidgetEnabled',
  promoWidgetPlaceholderEnabled = 'promoWidgetPlaceholderEnabled',
}

export const SWITCHERS_WITHOUT_PREFIX = [
  ACCEPTED_SWITCHERS.debugMode,
  ACCEPTED_SWITCHERS.disableAds,
  ACCEPTED_SWITCHERS.disableAnalytics,
];

export const DEBUGGER_SWITCHERS = [
  ACCEPTED_SWITCHERS.disableAds,
  ACCEPTED_SWITCHERS.disableAnalytics,
  ACCEPTED_SWITCHERS.forceAdFallback,
  ACCEPTED_SWITCHERS.forceBot,
  ACCEPTED_SWITCHERS.forceRussia,
  ACCEPTED_SWITCHERS.gigaEnabled,
  ACCEPTED_SWITCHERS.promoWidgetEnabled,
  ACCEPTED_SWITCHERS.promoWidgetPlaceholderEnabled,
  ACCEPTED_SWITCHERS.forceArticle,
];

export const DEBUGGER_SWITCHERS_NAMES: {
  [key: string]: string;
} = {
  [ACCEPTED_SWITCHERS.disableAds]: 'Отключить рекламу',
  [ACCEPTED_SWITCHERS.disableAnalytics]: 'Отключить аналитику',
  [ACCEPTED_SWITCHERS.forceAdFallback]: 'Фоллбек для рекламы',
  [ACCEPTED_SWITCHERS.forceBot]: 'Включить режим приложения для ботов',
  [ACCEPTED_SWITCHERS.forceRussia]: 'Включить геолокацию из Россия',
  [ACCEPTED_SWITCHERS.gigaEnabled]: 'Включить GigaChat',
  [ACCEPTED_SWITCHERS.promoWidgetEnabled]: 'Включить дополнительный креатив',
  [ACCEPTED_SWITCHERS.promoWidgetPlaceholderEnabled]:
    'Включить плейсхолдер под баннер ДЗО',
  [ACCEPTED_SWITCHERS.forceArticle]: 'Сделать первый кластер статьей',
};
