import { selectPages } from '../selectors';

export const selectConverterPage = (state: IAppState) =>
  selectPages(state).converter;

export const selectConverterPageAmount = (state: IAppState) =>
  selectConverterPage(state).amount;

export const selectConverterPageFirstCurrency = (state: IAppState) =>
  selectConverterPage(state).firstCurrency;

export const selectConverterPageFirstCurrencyConverter = (state: IAppState) =>
  selectConverterPage(state).firstCurrencyConverter;

export const selectConverterPageSecondCurrency = (state: IAppState) =>
  selectConverterPage(state).secondCurrency;

export const selectConverterPageSecondCurrencyConverter = (state: IAppState) =>
  selectConverterPage(state).secondCurrencyConverter;

export const selectConverterPageCrossCourse = (state: IAppState) =>
  selectConverterPage(state).crossCourse;

export const selectConverterPageFixedValue = (state: IAppState) =>
  selectConverterPage(state).fixedValue;

export const selectConverterPagePuids = (state: IAppState) =>
  selectConverterPage(state).puids;

export const selectConverterPageIsLoading = (state: IAppState) =>
  selectConverterPage(state).isLoading;
