import _throttle from 'lodash.throttle';
import { useState, useEffect, MutableRefObject } from 'react';

const FIXED_BOTTOM = 30;
const MODIFY_FIXED_BOUNDARY = 380;

type ShouldWrapperBeFixedPropsType = {
  elementNode: HTMLElement;
  wrapperNode: HTMLElement;
  fixedBottom: number | undefined;
};

/**
 * Функция, определяющая видимость обертки в зависимости от положения.
 * @param elementNode - узел элемента;
 * @param wrapperNode - узел обертки;
 * @param fixedBottom - величина до фиксируемого низа.
 */
const shouldWrapperBeFixed = ({
  elementNode,
  wrapperNode,
  fixedBottom = FIXED_BOTTOM,
}: ShouldWrapperBeFixedPropsType) => {
  const elementTopPosition =
    elementNode.getBoundingClientRect().top + MODIFY_FIXED_BOUNDARY;
  const isElementTopAboveViewport = elementTopPosition < 0;

  const blockButtomPosition =
    wrapperNode.getBoundingClientRect().bottom + fixedBottom;
  const isBlockBelowViewport = blockButtomPosition > window.innerHeight;

  return isElementTopAboveViewport && isBlockBelowViewport;
};

type UseFloatingWrapperType = (props: {
  elementRef: MutableRefObject<HTMLElement | null>;
  wrapperRef: MutableRefObject<HTMLElement | null>;
  shouldFloat: boolean;
  fixedBottom?: number;
}) => boolean;

/**
 * Хук, навешивает событие на скролл, возрвращает true, если обертка должна плавать.
 * @param elementRef - ссылка на элемент;
 * @param wrapperRef - ссылка на обертку;
 * @param shouldFloat - флаг плавающих при скролле кнопок;
 * @param fixedBottom - величина до фиксируемого низа.
 */
export const useFloatingWrapper: UseFloatingWrapperType = ({
  elementRef,
  wrapperRef,
  shouldFloat,
  fixedBottom,
}) => {
  const [isFixed, setIsFixed] = useState(false);
  const [isVisibleContainer, setIsVisibleContainer] = useState(false);

  useEffect(() => {
    const target = wrapperRef.current;

    if (!target) {
      return () => null;
    }

    const callback = (entries: IntersectionObserverEntry[]) =>
      setIsVisibleContainer(entries[0]?.isIntersecting);

    const observer = new IntersectionObserver(callback, {
      threshold: 1.0,
    });

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (!elementRef.current || !wrapperRef.current || !shouldFloat) {
      return () => null;
    }

    const changeCssPosition = () => {
      const shouldBeFixed = shouldWrapperBeFixed({
        elementNode: elementRef.current as HTMLElement,
        wrapperNode: wrapperRef.current as HTMLElement,
        fixedBottom,
      });

      if (shouldBeFixed !== isFixed) {
        setIsFixed(shouldBeFixed);
      }
    };

    changeCssPosition();

    const throttledChangeCssPosition = _throttle(changeCssPosition, 30);

    window.addEventListener('scroll', throttledChangeCssPosition);

    return () => {
      window.removeEventListener('scroll', throttledChangeCssPosition);
    };
  }, [
    elementRef,
    wrapperRef,
    shouldFloat,
    fixedBottom,
    isFixed,
    isVisibleContainer,
  ]);

  return isFixed;
};
