/**
 * Функция трансформации ключа в синтаксис camel case.
 * @param objectKey - ключ объекта.
 */
export const transformToCamelCase = (objectKey: string) =>
  objectKey
    .split('_')
    .map((char, index) => {
      if (index === 0) return char;

      return `${char.charAt(0).toUpperCase()}${char.slice(1)}`;
    })
    .join('');

/**
 * Функция трансформации ключа в синтаксис snake case.
 * @param objectKey - ключ объекта.
 */
export const transformToSnakeCase = (objectKey: string) =>
  objectKey.replace(/[A-Z]/g, (char) => `_${char.toLowerCase()}`);
