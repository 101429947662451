import { CURRENCY_CHAR_CODE } from 'config/constants/finance';

import { CROSS_RATE } from './typings';

export const CROSS_RATES = [
  CROSS_RATE.AUDCAD,
  CROSS_RATE.AUDCHF,
  CROSS_RATE.AUDJPY,
  CROSS_RATE.AUDNZD,
  CROSS_RATE.AUDUSD,
  CROSS_RATE.CADCHF,
  CROSS_RATE.CADJPY,
  CROSS_RATE.CHFJPY,
  CROSS_RATE.CHFSGD,
  CROSS_RATE.EURAUD,
  CROSS_RATE.EURCAD,
  CROSS_RATE.EURCHF,
  CROSS_RATE.EURGBP,
  CROSS_RATE.EURJPY,
  CROSS_RATE.EURMXN,
  CROSS_RATE.EURNOK,
  CROSS_RATE.EURNZD,
  CROSS_RATE.EURSEK,
  CROSS_RATE.EURSGD,
  CROSS_RATE.EURUSD,
  CROSS_RATE.GBPAUD,
  CROSS_RATE.GBPCAD,
  CROSS_RATE.GBPCHF,
  CROSS_RATE.GBPJPY,
  CROSS_RATE.GBPNZD,
  CROSS_RATE.GBPSEK,
  CROSS_RATE.GBPUSD,
  CROSS_RATE.NOKJPY,
  CROSS_RATE.NZDCAD,
  CROSS_RATE.NZDCHF,
  CROSS_RATE.NZDJPY,
  CROSS_RATE.NZDUSD,
  CROSS_RATE.SGDJPY,
  CROSS_RATE.USDCAD,
  CROSS_RATE.USDCHF,
  CROSS_RATE.USDDKK,
  CROSS_RATE.USDJPY,
  CROSS_RATE.USDMXN,
  CROSS_RATE.USDNOK,
  CROSS_RATE.USDSEK,
  CROSS_RATE.USDSGD,
];

export const DEFAULT_CROSS_CURRENCY = CROSS_RATE.EURUSD;
export const DEFAULT_CROSS_FIRST_CURRENCY = CURRENCY_CHAR_CODE.EUR;
export const DEFAULT_CROSS_SECOND_CURRENCY = CURRENCY_CHAR_CODE.USD;

export const START_LENGTH = 0;
export const MIDLE_LENGTH = 3;
export const FINISH_LENGTH = 6;
