import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRateExchangeForecast } from 'api';
import { selectApiConfig } from 'common/redux/runtime/selectors';

/**
 * Функция загрузки основной информации о валютах.
 */
export const fetchRateExchangeForecast = createAsyncThunk(
  'widgets/fetchRateExchangeForecast',
  async (_props, { getState }) => {
    const apiConfig = selectApiConfig(getState() as IAppState);

    const { data: currenciesInfo, error } =
      await getRateExchangeForecast(apiConfig);

    if (error || !currenciesInfo) {
      throw error || new Error('Ошибка при получении информации о валютах');
    }

    return currenciesInfo;
  },
);
