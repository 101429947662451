import { createAsyncThunk } from '@reduxjs/toolkit';
import _uniq from 'lodash.uniq';

import { getCommentsByClusterID } from 'api';
import { fetchClustersByTopic } from 'common/redux/commonData/topics/asyncs';
import { selectApiConfig } from 'common/redux/runtime/selectors';

import { updateEntries, upsertEntries } from './commonData/entries';
import { fetchExpertsByClusterId } from './commonData/experts/asyncs';
import {
  fetchClustersByManualTag,
  FetchClustersByManualTagType,
} from './commonData/manualTags/asyncs';

interface FetchEntriesComments {
  // id кластеров, для которых получаются комментарии.
  cardIds: CardData['id'][];
}

/**
 * Функция получения данных комментариев для указанных кластеров.
 * @see FetchEntriesComments
 */
export const fetchCommentsForEntries = createAsyncThunk(
  'fetchCommentsForEntries',
  async ({ cardIds }: FetchEntriesComments, { dispatch, getState }) => {
    const api = selectApiConfig(getState() as IAppState);

    const { error, data } = await getCommentsByClusterID(api, cardIds);

    if (error || !data) {
      throw error || new Error(`Ошибка при получении комментариев: ${cardIds}`);
    }

    const commentsCards = data.map(
      ({ cluster_id: id, comments_count: commentsCount }) => ({
        id: String(id),
        changes: {
          commentsCount,
        },
      }),
    );

    dispatch(updateEntries(commentsCards));
  },
);

/**
 * Обертка загрузки кластеров, принадлежащий топику. Добавляет кластера в слайс после загрузки
 * @param topicId - id топика, для которого загружаются кластера.
 */
export const fetchClustersByTopicForEntries = createAsyncThunk(
  'fetchClustersByTopicForEntries',
  async ({ topicId }: { topicId: ATTopic['id'] | undefined }, { dispatch }) => {
    const { payload } = await dispatch(
      fetchClustersByTopic({
        topicId,
        callback: (clusters) => {
          dispatch(upsertEntries(clusters));
        },
      }),
    );

    return payload;
  },
);

/**
 * Обертка для загрузки кластера с экспертами. Добавляет кластер в слайс после загрузки
 * @param clusterId - id кластера, эксперты для которого загружаются.
 */
export const fetchExpertsByClusterIdForEntries = createAsyncThunk(
  'fetchExpertsByClusterIdForEntries',
  async ({ clusterId }: { clusterId: CardData['id'] }, { dispatch }) => {
    const { payload } = await dispatch(
      fetchExpertsByClusterId({
        clusterId,
        callback: (cluster) => {
          dispatch(upsertEntries([cluster]));
        },
      }),
    );

    return payload;
  },
);

/**
 * Обертка для функции загрузки кластеров по ручному тегу. Добавляет кластера в слайс после загрузки
 * @param manualTag - алиас ручного тега
 * @param projectId - id вертикали
 */
export const fetchClustersByManualTagForEntries = createAsyncThunk(
  'fetchClustersByManualTagForEntries',
  async (
    params: Omit<FetchClustersByManualTagType, 'callback'>,
    { dispatch },
  ) => {
    const { payload } = await dispatch(
      fetchClustersByManualTag({
        ...params,
        callback: (clusters) => {
          dispatch(upsertEntries(clusters));
        },
      }),
    );

    return payload;
  },
);
