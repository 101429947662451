import loadable from '@loadable/component';
import React from 'react';

import { fetchFullCluster } from 'common/redux/commonData/entries';
import {
  clusterCommentsSetClusterId,
  clusterCommentsSetPuids,
} from 'common/redux/pages/clusterComments';
import { selectProjectAlias } from 'common/redux/runtime/selectors';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PAGE_TYPE } from 'config/constants/routerName';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';

const ClusterCommentsDesktop = loadable(
  () => import('desktop/pages/ClusterComments'),
);
const ClusterCommentsMobile = loadable(
  () => import('mobile/pages/ClusterComments'),
);

type ClusterCommentsMatchRoute = {
  clusterId: string;
  topicAlias: TopicType['alias'];
};

/**
 * Страница комментариев кластера
 * https://news.rambler.ru/world/51042514-glava-frantsuzskogo-regiona-prizvala-omolodit-otvetstvennost-za-besporyadki/comments/
 */
export const clusterCommentsRoutes = (
  isMobile: SettingsType['isMobile'],
): IAppRoute<ClusterCommentsMatchRoute> => ({
  name: PAGE_TYPE.clusterComments,
  exact: true,
  path: '/:topicAlias/:clusterId(\\d{6,10})\\-:clusterInfo/comments/',
  render: isMobile
    ? () => <ClusterCommentsMobile />
    : () => <ClusterCommentsDesktop />,
  fetchData: async ({ dispatch, getState }, { params }) => {
    const { clusterId, topicAlias } = params;

    const projectAlias = selectProjectAlias(getState());

    dispatch(clusterCommentsSetPuids({ topicAlias, projectAlias }));
    dispatch(clusterCommentsSetClusterId(clusterId));

    const promiseList: PromiseListType = [
      // Данные о кластере
      dispatch(fetchFullCluster({ clusterId })),
    ];

    await Promise.all(
      appendPageDownloader({
        promiseList,
        Component: isMobile ? ClusterCommentsMobile : ClusterCommentsDesktop,
        key: Object.keys({ ClusterCommentsDesktop })[0],
      }),
    );
  },
});
