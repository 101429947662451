import { createSlice } from '@reduxjs/toolkit';

import { getActionCurrency } from 'utils/finance';

import { banksRatesWidgetAdapter } from './adapter';
import { fetchBanksRates } from './asyncs';
import { BANKS_RATES_CURRENCY } from './typings';

/**
 * Слайс виджета Курсы обмена.
 */
const banksRatesSlice = createSlice({
  name: 'banksRates',
  initialState: banksRatesWidgetAdapter.getInitialState({
    currentCurrency: BANKS_RATES_CURRENCY.USD,
  }),
  reducers: {
    /**
     * Метод обновления текущей валюты для виджета.
     * @param action.payload - данные валюты.
     */
    setCurrentCurrency(state, { payload }) {
      state.currentCurrency = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBanksRates.pending, (state, { meta }) => {
      const { sort } = meta.arg;

      banksRatesWidgetAdapter.addOne(state, {
        id: getActionCurrency(sort),
        data: null,
        fetching: true,
        error: '',
      });
    });
    builder.addCase(fetchBanksRates.fulfilled, (state, { meta, payload }) => {
      const { sort } = meta.arg;

      banksRatesWidgetAdapter.upsertOne(state, {
        id: getActionCurrency(sort),
        data: payload,
        fetching: false,
        error: '',
      });
    });
    builder.addCase(fetchBanksRates.rejected, (state, { meta, error }) => {
      const { sort } = meta.arg;

      banksRatesWidgetAdapter.upsertOne(state, {
        id: getActionCurrency(sort),
        data: null,
        fetching: false,
        error: error.message,
      });
    });
  },
});

export const { setCurrentCurrency } = banksRatesSlice.actions;

export const banksRatesReducer = banksRatesSlice.reducer;
