import { selectTravelRegionPlacesByIds } from 'common/redux/commonData/travelRegionPlaces/selectors';

import { selectPages } from '../selectors';

export const selectGuideCountryPlaces = (state: IAppState) =>
  selectPages(state).guideCountryPlaces;

export const selectGuideCountryPlacesPuids = (state: IAppState) =>
  selectGuideCountryPlaces(state).puids;

export const selectGuideCountryPlacesIds = (state: IAppState) =>
  selectGuideCountryPlaces(state).placesIds;

export const selectGuideCountryPlacesAlias = (state: IAppState) =>
  selectGuideCountryPlaces(state).countryAlias;

export const selectGuideCountryPlacesData = (state: IAppState) => {
  const ids = selectGuideCountryPlacesIds(state);

  return selectTravelRegionPlacesByIds(ids)(state);
};
