import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchLatestClusters } from './asyncs';

type StateLatestType = {
  puids: PuidsType;
  clustersIds: CardData['id'][];
  currentPage: number;
  hasNextPage: boolean;
} & Fetchable;

const latestSlice = createSlice({
  name: PAGE_TYPE.latest,
  initialState: {
    puids: {},
    clustersIds: [],
    currentPage: 1,
    hasNextPage: false,
    fetching: false,
  } as StateLatestType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setLatestPuids: (state, action: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_main`.toUpperCase(),
      };
    },

    /**
     * Установка номера текущей страницы.
     * @param action.payload - номер страницы.
     */
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestClusters.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchLatestClusters.fulfilled, (state, { payload }) => {
        const { clusterIds, currentPage, hasNextPage } = payload;

        state.clustersIds = [...state.clustersIds, ...clusterIds];
        state.currentPage = currentPage;
        state.hasNextPage = hasNextPage;
        state.fetching = false;
      })
      .addCase(fetchLatestClusters.rejected, (state, error) => {
        state.fetching = false;
        state.error = error.error.message;
      });
  },
});

export const latestReducer = latestSlice.reducer;

export const { setLatestPuids, setCurrentPage } = latestSlice.actions;
