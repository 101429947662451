import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectUserId } from 'common/redux/commonData/user/selectors';
import { top100Wrapper } from 'utils/counters/top100/top100Wrapper';

/**
 * Хук, меняющий значение юзера внутри топ100 при изменении данных пользователя.
 */
export const useTop100User = () => {
  const chainId = useSelector(selectUserId);

  useEffect(() => {
    top100Wrapper.syncUserId(chainId);
    top100Wrapper.setRamblerId(chainId);
  }, [chainId]);
};
