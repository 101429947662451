export enum RCM_BLOCK_TYPE {
  clusterFeedDesktop = 'clusterFeedDesktop',
  clusterFeedMobile = 'clusterFeedMobile',
  desktopWidget = 'desktopWidget',
  homeDesktop = 'homeDesktop',
  homeMobile = 'homeMobile',
  mobileWidget = 'mobileWidget',
  regionDesktop = 'regionDesktop',
  regionMobile = 'regionMobile',
  rnetHorizontalDesktop = 'rnetHorizontalDesktop',
  rnetHorizontalDesktopRedesign = 'rnetHorizontalDesktopRedesign',
  rnetHorizontalMobile = 'rnetHorizontalMobile',
  rnetVertical1Desktop = 'rnetVertical1Desktop',
  rnetVertical5Desktop = 'rnetVertical5Desktop',
  rnetVerticalMobile = 'rnetVerticalMobile',
  topicDesktop = 'topicDesktop',
  topicMobile = 'topicMobile',
  topNewsWidgetDesktop = 'topNewsWidgetDesktop',
  topNowDesktop = 'topNowDesktop',
}
