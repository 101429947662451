import Banners from 'config/constants/banner/banners';

/**
 * Функция высчитывания отступа для рекламы.
 * @param name - название баннера.
 */
export const getMarginPercent = (name: Banners) => {
  if (name === Banners['100x70']) {
    return 0.8;
  }

  return 0.25;
};
