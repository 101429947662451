import { RouteConfig, matchRoutes } from 'react-router-config';

import { selectIsMobile } from 'common/redux/runtime/selectors';
import { getCommonRoutes } from 'common/routes';

/**
 * Запрос данных для страницы, на которую осуществляется переход
 * @param url - ссылка для перехода
 * @param callback - колбек для выполнения после загрузки данных (например пуш урла в роутер)
 */
export const clientFetchData =
  (url: string, callback?: (route: RouteConfig) => void) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    const isMobile = selectIsMobile(getState());

    const routes = getCommonRoutes(isMobile);

    const [{ route, match }] = matchRoutes(routes as RouteConfig[], url);

    await route.fetchData({ dispatch, getState } as AppStore, match);

    if (callback) callback(route);
  };
