import { BannersDataType } from 'typings/Config';

import { PUIDS } from '../common';

import Banners from './banners';

export const common: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 579505410,
    banners: {
      [Banners.Topline]: {
        display: 'ssp',
        begun: 579506534,
      },
      [Banners.Billboard]: {
        display: 'ssp',
        begun: 579505414,
      },
      [Banners.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [Banners.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [Banners.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [Banners.Parallax]: {
        display: 'sspScroll',
        begun: 579505502,
      },
      [Banners['240x400']]: {
        display: 'reloadssp',
        begun: 579505416,
      },
      [Banners['240x400_2']]: {
        display: 'sspScroll',
        begun: 579505500,
      },
      [Banners.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '193',
        },
        begun: 579505506,
      },
      [Banners.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '194',
        },
        begun: 579505508,
      },
      [Banners.Superfooter]: {
        display: 'sspScroll',
        begun: 579505418,
      },
      [Banners['100x70']]: {
        display: 'sspScroll',
        begun: 579505498,
      },
      [Banners.Inread]: {
        display: 'ssp',
        begun: 579505512,
      },
      [Banners.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 579505510,
        dir1: {
          [PUIDS.puid61]: '830',
        },
      },
      [Banners.Ingallery]: {
        display: 'reloadssp',
        begun: 579505652,
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579505486,
      },
      [Banners.ShowUp]: {
        display: 'ssp',
        begun: 579505488,
      },
      [Banners.Fullscreen]: {
        display: 'ssp',
        begun: 579505504,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 579505412,
    banners: {
      [Banners.TopBanner]: {
        begun: 579505420,
        p1: 'bzmeq',
      },
      [Banners.Rich]: {
        begun: 579505496,
      },
      [Banners.Fullscreen]: {
        begun: 579505538,
      },
      [Banners.Listing1]: {
        begun: 579505422,
      },
      [Banners.Listing2]: {
        begun: 579505424,
      },
      [Banners.Listing3]: {
        begun: 579505426,
      },
      [Banners.Listing4]: {
        begun: 579505430,
      },
      [Banners.Content1]: {
        begun: 579505432,
        p1: 'bwxfh',
      },
      [Banners.Content2]: {
        begun: 579505434,
        p1: 'bymxx',
      },
      [Banners.Content3]: {
        begun: 579505436,
        p1: 'bymxy',
      },
      [Banners.Content4]: {
        begun: 579505438,
        p1: 'bymxz',
      },
      [Banners.Spec]: {
        begun: 579505428,
      },
      [Banners.ContentSpec]: {
        begun: 579505544,
      },
      [Banners.Inpage]: {
        begun: 579505540,
      },
      [Banners.Footer]: {
        begun: 579505536,
        p1: 'bymya',
      },
      [Banners.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579505494,
      },
    },
  },
};
