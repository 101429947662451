import { createSlice } from '@reduxjs/toolkit';

import { recommendationWidgetAdapter } from './adapter';
import { fetchRecommendedNews } from './asyncs';

/**
 * Слайс виджета рекоммендаций.
 */
const recommendationSlice = createSlice({
  name: 'recommendationWidget',
  initialState: recommendationWidgetAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendedNews.pending, (state, { meta }) => {
      const { clusterId } = meta.arg;

      recommendationWidgetAdapter.upsertOne(state, {
        id: clusterId,
        fetching: true,
        error: '',
        clusterIds: [],
      });
    });

    builder.addCase(
      fetchRecommendedNews.fulfilled,
      (state, { meta, payload }) => {
        const { clusterId } = meta.arg;
        const { clusterIds } = payload;

        recommendationWidgetAdapter.upsertOne(state, {
          id: clusterId,
          clusterIds,
          fetching: false,
          error: '',
        });
      },
    );

    builder.addCase(fetchRecommendedNews.rejected, (state, { error, meta }) => {
      const { clusterId } = meta.arg;

      recommendationWidgetAdapter.upsertOne(state, {
        id: clusterId,
        clusterIds: [],
        fetching: false,
        error: error.message,
      });
    });
  },
});

export const recommendationReducer = recommendationSlice.reducer;
