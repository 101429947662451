import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchRateExchangeForecast } from './asyncs';
import { CurrencyForExchangeRatesWidgetType } from './typings';

/**
 * Слайс виджета Индексы и котировки
 */
const rateExchangeForecastSlice = createSlice({
  name: 'rateExchangeForecast',
  initialState: {
    firstCurrency: {} as CurrencyForExchangeRatesWidgetType,
    secondCurrency: {} as CurrencyForExchangeRatesWidgetType,
    fetching: false,
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRateExchangeForecast.pending, (state) => {
      state.fetching = true;
      state.error = '';
    });

    builder.addCase(
      fetchRateExchangeForecast.fulfilled,
      (
        state,
        { payload }: PayloadAction<CurrencyForExchangeRatesWidgetType[]>,
      ) => {
        const [firstCurrency, secondCurrency] = payload;

        state.firstCurrency = firstCurrency;
        state.secondCurrency = secondCurrency;
        state.fetching = false;
        state.error = '';
      },
    );

    builder.addCase(fetchRateExchangeForecast.rejected, (state, { error }) => {
      state.fetching = false;
      state.error = error.message || '';
    });
  },
});

export const rateExchangeForecastReducer = rateExchangeForecastSlice.reducer;
export { fetchRateExchangeForecast };
