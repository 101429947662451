export enum CURRENCY_SOURCES {
  // Центробанк
  Centrobank = 'cbr',
  // Московская межбанковская валютная биржа. Грубо говоря это другой источник, но бек сам не до конца определился что есть что
  MMCB = 'bcs',
  // Форекс ¯\_(ツ)_/¯
  Forex = 'forex',
}

export const isCurrencySource = (val: unknown): val is CURRENCY_SOURCES =>
  Object.values(CURRENCY_SOURCES).includes(val as CURRENCY_SOURCES);

export type CurrencyData = {
  date: string;
  last: number;
  prev: number;
};

export type ExchangeRatesTabType = {
  [x in CURRENCY_SOURCES]: CurrencyData;
};

export type ForecastTabType = {
  dateFrom: string;
  dateTo: string;
  rate: number;
};

export type CurrencyForExchangeRatesWidgetType = {
  currencyName: string;
  exchangeRatesTab: ExchangeRatesTabType;
  favorableExchangeRateTab: {
    buy: {
      bankId: number;
      rate: number;
    };
    sell: {
      bankId: number;
      rate: number;
    };
  };
  forecastTab: ForecastTabType[];
};

export enum CURRENCIES {
  First = 'first',
  Second = 'second',
}
