/* eslint-disable camelcase */
import { DefaultBankRegionsType } from 'common/redux/commonData/bankRegions/typings';
import { BankType } from 'common/redux/commonData/banks/typings';
import {
  CrossDataType,
  DefaultCrossDataType,
} from 'common/redux/commonData/widgets/crossWidget/typings';
import {
  CurrencyForExchangeRatesWidgetType,
  CURRENCY_SOURCES,
} from 'common/redux/commonData/widgets/exchangeRatesWidget/typings';
import {
  BankExchangeType,
  CurrencyDataExchangeType,
  CurrencyExchangeType,
} from 'common/redux/commonData/widgets/exchangeWidget/typings';
import { BankObjectsType } from 'common/redux/pages/banks/typings';
import { omit } from 'utils/omit';

const TOPIC_DOMAIN: null = null;
const DISPLAY_TYPE_DOMAIN = 'display_type';

/**
 * Конвертор экспертов к общему виду с авторами
 * для использования общими UI компонентами
 * @param responseData - данные ответа запроса
 * @returns - объект эксперта
 */
export const getApiExpert = (
  responseData: APIExpertType,
): ATExpertType | null => {
  if (!responseData) return null;
  const {
    id,
    name,
    alias,
    position,
    social_networks = [],
    description,
    image,
  } = responseData;

  return {
    id,
    name,
    alias,
    description,
    image,
    specialization: position,
    socials: social_networks.map((data) => ({
      ...data,
      type: data.network_type,
    })),
  };
};

/**
 * Генератор объекта рубрики
 * @param topic - объект рубрики
 * @returns - объект рубрики (без лишних полей)
 */
export const getApiTopic = (topic: ATTopic): ATTopic | null => {
  if (!topic) return null;

  const {
    id,
    status,
    name,
    alias,
    project_id,
    title,
    details: topicDetails,
    seo: topicSeo,
  } = topic;

  const details = {
    level_button_name: topicDetails?.level_button_name,
    level_name: topicDetails?.level_name,
    level_pos: topicDetails?.level_pos,
    level_status: topicDetails?.level_status,
  };

  const seo = {
    description: topicSeo?.description,
    keywords: topicSeo?.keywords,
    puid61: topicSeo?.puid61,
    puid_rnet: topicSeo?.puid_rnet,
    title: topicSeo?.title,
  };

  return {
    id,
    status,
    name,
    alias,
    project_id,
    title,
    details,
    seo,
  };
};

/**
 * Конвертор массива рубрик
 * @param topicList - массив объектов рубрик
 * @returns - массив объектов карточек (урезанный кластер)
 */
export const getApiTopicList = (topicList: ATTopic[]): ATTopic[] | null =>
  topicList.length
    ? (topicList.map(getApiTopic).filter(Boolean) as ATTopic[])
    : null;

/**
 * Генератор интерфейса карточки. Отбрасывает лишние поля
 * @param cluster - объект полноценного кластера
 * @returns - объект карточки (урезанный кластер)
 */
export const getApiCard = (cluster: APICluster): APICard => ({
  id: cluster.id,
  status: cluster.status,
  cluster_type: cluster.cluster_type,
  title: cluster.title,
  long_title: cluster.long_title,
  annotation: cluster.annotation,
  comments_count: cluster.comments_count,
  image: cluster.image,
  normalized_title: cluster.normalized_title,
  modification_time: cluster.modification_time,
  publication_time: cluster.publication_time,
  topic: cluster.topic,
  no_comments: cluster.no_comments,
  mentions: cluster.mentions,
  main_video: cluster.main_video,
  display_type: cluster.display_type ?? cluster.display_type,
});

/**
 * Генератор интерфейса кластера. Основывается на карточке
 * @param cluster - объект полноценного кластера
 * @returns - объект кластера
 */
export const getApiCluster = (
  cluster: APICluster | null,
): APICluster | null => {
  if (!cluster) return null;

  return {
    ...getApiCard(cluster),
    body: cluster.body,
    body_short: cluster.body_short,
    is_paid: cluster.is_paid,
    items_count: cluster.items_count,
    is_commercial: cluster.is_commercial,
    gallery: cluster.gallery,
    main_video: cluster.main_video,
    creation_time: cluster.creation_time,
    is_trash: cluster.is_trash,
    draft: cluster.draft,
    is_new_draft: cluster.is_new_draft ?? false,
  };
};

/**
 * Конвертор массива кластеров к массиву карточек
 * @param clustersList - массив объектов полноценного кластера
 * @returns - массив объектов карточек (урезанный кластер)
 */
export const getApiCardList = (
  clustersList: APICluster[] | undefined,
): APICard[] | null =>
  clustersList?.length ? clustersList.map(getApiCard) : null;

/**
 * Генератор интерфейса автотегов.
 * @param autotag - объект автотега
 * @returns - объект автотега с правильными полями autotag_type
 */
export const getApiAutotag = (autotag: APIAutotag): ATAutotag => ({
  id: autotag.id,
  alias: autotag.alias,
  displayName: autotag.display_name,
  editedName: autotag.edited_name,
  autotagType: autotag.autotag_type,
  mentions: autotag.mentions,
  externalId: autotag.external_id,
});

/**
 * Конвертор к массиву автотегов кластера
 * @param autotags - массив объектов автотегов кластера
 * @returns - массив объектов автотегов (урезанных)
 */
export const getApiAutotagList = (
  autotags: APIAutotag[],
): ATAutotag[] | null => (autotags.length ? autotags.map(getApiAutotag) : null);

/**
 * Генератор интерфейса новости источника кластера. Отбрасывает лишние поля
 * @param item - новость источника кластера
 * @returns - объект новости источника кластера (урезанная)
 */
export const getApiClusterItem = (
  item: ATClusterItem,
): ATClusterItem | null => {
  if (!item) return null;

  return {
    title: item.title,
    url: item.url,
    id: item.id,
    resource: item.resource,
    resource_id: item.resource_id,
    pubdate: item.pubdate,
  };
};

/**
 * Конвертор массива новостей источников кластера
 * @param itemsList - массив объектов полноценных новостей источников кластера
 * @returns - массив объектов новостей источников кластера (урезанный)
 */
export const getApiClusterItemsList = (
  itemsList: ATClusterItem[],
): ATClusterItem[] | null =>
  itemsList.length
    ? (itemsList.map(getApiClusterItem).filter(Boolean) as ATClusterItem[])
    : null;

/**
 * Адаптирует данные для страницы автотега (убирает лишние поля, добавляет external_id)
 * @param autotagInfo информация для страницы автотега с апи
 * @returns урезанный объект информации для страницы автотега с только необходимыми полями
 */
export const getApiAutotagPageInfo = (
  autotagInfo: APIAutotagInfo,
): ATAutotagInfo | null => {
  if (!autotagInfo) {
    return null;
  }

  let externalId: ATAutotagInfo['externalId'] = autotagInfo?.external_id || '';

  if (externalId.indexOf('http') === -1) {
    // это должна быть ссылка на фильм
    externalId = undefined;
  }

  return {
    externalId,
    displayName: autotagInfo.display_name,
    editedName: autotagInfo.edited_name,
    autotagType: autotagInfo.autotag_type,
    ...omit(autotagInfo, [
      'external_id',
      'autotag_type',
      'display_name',
      'edited_name',
    ]),
  };
};

/**
 * Функция для получения типа отображения карточки
 * @param item - объект кластера из рекомендаций
 */
export const getRecommendDisplayType = (item: RecommendationsType) =>
  (item?.item_category?.find(({ domain }) => domain === DISPLAY_TYPE_DOMAIN)
    ?.value as DisplayTypeType) || null;

/**
 * Функция для получения тэга карточки
 * @param item - объект кластера из рекомендаций
 */
export const getRecommendTopicName = (item: RecommendationsType) =>
  item?.item_category?.find(({ domain }) => domain === TOPIC_DOMAIN)?.value ||
  null;

/**
 * Генератор интерфейса рекоммендованных кластеров. Отбрасывает лишние поля
 * @param item - новость источника кластера
 * @returns - объект новости источника кластера (урезанная)
 */
export const getApiRecommendedCluster = (
  item: RecommendationsType,
): ATRcmType => ({
  // весь item нужен для прямой передачи в рекоменды при отправке событий
  item,
  feedID: item.feed_id,
  title: item.feed_title,
  description: item.item_description,
  itemID: item.item_id,
  itemImage: item.item_image,
  itemTitle: item.item_title,
  itemUrl: item.item_url,
  itemPubDate: item.item_pubdate,
  rcmID: item.rcm_id,
  showID: item.show_id,
  topic: getRecommendTopicName(item),
  displayType: getRecommendDisplayType(item),
});

/**
 * Адаптер типа инфо о сюжете. Убирает snake_case
 * @param theme - инфо о сюжете
 * @returns - инфо о сюжете (преобразованное)
 */
export const getApiThemeInfo = (theme: APIThemeInfo): ATThemeInfo | null => {
  if (!theme) return null;

  return {
    id: theme.id,
    projectId: theme.project_id,
    alias: theme.alias,
    name: theme.name,
    status: theme.status,
    title: theme.title,
    annotation: theme.annotation,
    bigImage: theme.big_image,
    ctime: theme.ctime,
    mtime: theme.mtime,
  };
};

/**
 * Конвертор массива объектов рекоммендованных кластеров
 * @param clustersList - массив объектов полноценных рекоммендованных кластеров
 * @returns - массив рекоммендованных кластеров (урезанных)
 */
export const getApiRecommendedClustersList = (
  recommended: APIRecommender | undefined,
): ATRecommender | null => {
  if (!recommended) return null;

  const {
    recommendations,
    ruid,
    split,
    user_id: userID,
    session_id: sessionID,
    xuid,
  } = recommended;

  return {
    recommendations: recommendations.map(getApiRecommendedCluster),
    ruid,
    split,
    userID,
    xuid,
    sessionID,
  };
};

// Работа с внешним API, интерфейс не определен, поэтому any
export const getApiRamblerId = (data: any) =>
  data.result.profile.info.chain_id.all[0];

/**
 * Адаптер типа инфо о регионе. Убирает snake_case
 * @param region - инфо об регионе
 * @returns - инфо о регионе (преобразованное)
 */
export const getApiRegion = (
  region: APIRegion | undefined,
): ATRegion | null => {
  if (!region) return null;

  return {
    id: region.id,
    alias: region.alias,
    geoTagId: region.geo_tag_id,
    name: region.name,
    namer: region.namer,
    geoId: region.geo_id,
    status: region.status,
    longTitle: region.long_title,
  };
};

export const getApiTagInfo = (tag: APITagInfo): ATTagInfo | null => {
  if (!tag) return null;

  return {
    alias: tag.alias,
    text: tag.text,
    projectId: tag.project_id,
  };
};

/**
 * Адаптер топа по автотегу. Убирает snake_case, поля
 * @param tag - автотег
 * @returns - инфо о автотеге (преобразованное)
 */
const getApiAutotagPopular = (
  tag: APIAutotagPopular,
): ATAutotagPopular | null => {
  if (!tag) return null;

  return {
    alias: tag.alias,
    displayName: tag.display_name,
    mentionsCount: tag.mentions_count,
  };
};

/**
 * Массив топов по автотегу
 * @param tags - массив автотегов
 */
export const getApiAutotagPopularList = (
  tags: APIAutotagPopular[],
): ATAutotagPopular[] | null =>
  tags.length
    ? (tags.map(getApiAutotagPopular).filter(Boolean) as ATAutotagPopular[])
    : null;

/**
 * Адаптер данных для видео-реклам по вертикалям
 * @param videos – массив даных по видео-рекламам
 */
export const getVideoAd = (videos: APIVideoAd[]): VideoAdData[] | null => {
  if (!Array.isArray(videos)) return null;

  return videos.map((video) => ({
    projectId: video.project_id,
    title: video.title,
    embedCode: video.embed_code,
    active: video.active,
    excludedTopics: video.excluded_topics,
  }));
};

/**
 * Адаптер данных о блоках rnet для источника
 * @param blocks - данные о блоках
 */
export const getApiResourceRnet = (
  blocks: APIResourcesRnet,
): ATResourcesRnet =>
  blocks.length
    ? blocks.map((block: APIResourceRnet) => {
        const {
          block: { block_id, block_title, enable_trueview },
          banners,
        } = block;

        const adaptedBlockInfo = {
          blockId: block_id,
          blockTitle: block_title,
          enableTrueview: enable_trueview,
        };

        const adaptedBanners = banners.map(
          ({
            type,
            banner_id,
            logo,
            url,
            title,
            description,
            topic_id,
            partner_name,
            flight_id,
            html,
          }: APIResourceRnetBlockBanner) => ({
            type,
            bannerId: banner_id,
            logo,
            url,
            title,
            description,
            topicId: topic_id,
            partnerName: partner_name,
            flightId: flight_id,
            html,
          }),
        );

        return {
          block: adaptedBlockInfo,
          banners: adaptedBanners,
        };
      })
    : [];

/**
 * Адаптер данных новостей источника для кластера
 * @param relatedItems - новости источника
 */
export const getApiClusterRelated = (
  relatedItems: ApiClusterRelatedType[],
): ATClusterRelatedType[] => {
  if (!relatedItems?.length || !Array.isArray(relatedItems)) {
    return [];
  }

  return relatedItems.map(({ title, url, image_url: imageUrl }) => ({
    title,
    url,
    imageUrl,
  }));
};

/**
 * Адаптер данных о валюте - отбрасывает лишние поля и приводит поля к camelCase
 * @param currency - полноценный объект с информацией о валюте.
 */
export const getApiCurrencyData = (
  currency: FullCurrencyDataType,
): CurrencyType => ({
  accusativeSingular: currency?.accusative_singular || '',
  charCode: currency?.char_code,
  gen: currency?.gen,
  gender: currency?.gender,
  genitivePlural: currency?.genitive_plural || '',
  genitiveSingular: currency?.genitive_singular || '',
  genitiveSingularCorrect: currency?.genitive_singular_correct || '',
  id: currency?.id,
  nom: currency?.nom,
  nominal: currency?.nominal,
  nominalAccusative: currency?.nominal_accusative || '',
  nominativeSingular: currency?.nominative_singular || '',
  prepositionalPlural: currency?.prepositional_plural || '',
});

/**
 * Адаптер данных о банкe - отбрасывает лишние поля и приводит поля к camelCase
 * @param bank - данные о банке;
 * @param charCode - валюта для банка.
 */
export const getApiBankData = (
  bank: APIBanks,
  charCode?: CurrencyType['charCode'],
): BankType => {
  const charCodesMap = charCode
    ? {
        [charCode]: {
          updatedAt: bank[charCode].updated_at,
          sellRate: bank[charCode].sell_rate,
          buyRate: bank[charCode].buy_rate,
        },
      }
    : {};

  return {
    ...charCodesMap,
    address: bank.address,
    bankType: bank.bank_type,
    code: bank.code,
    id: bank.id,
    licence: bank.licence,
    logoUrl: bank.logo_url,
    name: bank.name,
    nameFull: bank.name_full,
    site: bank.site,
  };
};

/**
 * Адаптер данных о банках - отбрасывает лишние поля и приводит поля к camelCase
 * @param banks - массив банков;
 * @param charCode - валюта для банков.
 */
export const getApiBanksData = (
  banks: APIBanks[],
  charCode?: CurrencyType['charCode'],
): BankType[] => banks.map((bank) => getApiBankData(bank, charCode));

/**
 * Адаптер данных о регионах - отбрасывает лишние поля и приводит поля к camelCase
 * @param bankRegions - массив регионов.
 */
export const getApiBankRegionsData = (bankRegions: DefaultBankRegionsType[]) =>
  bankRegions.map(({ region }) => ({
    alias: region.alias,
    id: region.id,
    name: region.name,
    accusativeName: region.namer,
  }));

/**
 * Адаптер данных о кросс-курсе валют - отбрасывает лишние поля и приводит поля к camelCase
 * @param crossRates - массив кросс-курса валют.
 */
export const getApiCrossRateData = (
  crossRates: DefaultCrossDataType[],
): CrossDataType[] =>
  crossRates.map((crossRate) => ({
    charCode: crossRate.char_code,
    description: crossRate.descr,
  }));

/**
 * Адаптер данных о банках - отбрасывает лишние поля и приводит поля к camelCase
 * @param banks - массив банков;
 * @param charCodeArray - массив символов валют.
 */
export const getApiBanksExchangeData = (
  banks: APIBanks[],
  charCodes: CurrencyExchangeType[],
): BankExchangeType[] =>
  banks.map((bank) => {
    const currenciesObject = charCodes.reduce(
      (acc, charCode) => {
        if (charCode in bank) {
          acc[charCode] = {
            updatedAt: bank[charCode].updated_at,
            sellRate: bank[charCode].sell_rate.toFixed(2),
            buyRate: bank[charCode].buy_rate.toFixed(2),
          };
        } else {
          acc[charCode] = {
            updatedAt: '',
            sellRate: (0).toFixed(2),
            buyRate: (0).toFixed(2),
          };
        }

        return acc;
      },
      {} as Record<CurrencyExchangeType, CurrencyDataExchangeType>,
    );

    return {
      code: bank.code,
      id: bank.id,
      name: bank.name,
      ...currenciesObject,
    };
  });

/**
 * Адаптер для апи отделений банков.
 * @param bankObjects - массив с отделениями банков из апи;
 * @returns адаптированный массив только с нужными данными.
 */
export const getApiBanksObjectsData = (
  bankObjects: APIBankObjects[],
): BankObjectsType[] =>
  bankObjects.map((bankObject) => ({
    id: bankObject.id ?? -1,
    name: bankObject.name ?? '',
    phone: bankObject.phone ?? '',
    address: bankObject.address ?? '',
    schedule: bankObject.schedule_entities ?? '',
  }));

/**
 * Адаптер данных о котировках валюты - выделяем из общей информации о валюте данные о котировках
 * и преобразуем к объекту, где ключ: id валюты - значение: массив values.
 * @param currencies - массив объектов с информацией о валюте.
 */
export const getApiCurrenciesRates = (
  currencies: FullCurrencyDataType[],
): FullCurrencyRatesType =>
  currencies?.reduce((acc, { id, values }) => {
    acc[id] = values;

    return acc;
  }, {} as FullCurrencyRatesType);

/**
 * Функция приведения данных о названии валюты от бека к нормальному виду.
 * @param currName - название валюты.
 */
const adaptCurrencyName = (currName: string) => {
  if (currName === 'euro') {
    return 'eur';
  }

  return currName;
};

/**
 * Адаптрер данных для виджета "Курсы валют".
 * Приводит поля к camelCase, некоторые объекты к одному виду.
 * @param currencyName - код валюты;
 * @param currencyData - объект с информацией о валюте.
 */
export const getApiExchangeRatesForecast = (
  currencyName: string,
  currencyData: CurrencyExchangeRatesForecast,
): CurrencyForExchangeRatesWidgetType => ({
  currencyName: adaptCurrencyName(currencyName),
  exchangeRatesTab: {
    [CURRENCY_SOURCES.Centrobank]: currencyData[CURRENCY_SOURCES.Centrobank],
    [CURRENCY_SOURCES.Forex]: {
      date: currencyData[CURRENCY_SOURCES.Forex].date,
      last: currencyData[CURRENCY_SOURCES.Forex].clos,
      prev: currencyData[CURRENCY_SOURCES.Forex].open,
    },
    [CURRENCY_SOURCES.MMCB]: {
      date: currencyData[CURRENCY_SOURCES.MMCB].date,
      last: currencyData[CURRENCY_SOURCES.MMCB].clos,
      prev: currencyData[CURRENCY_SOURCES.MMCB].open,
    },
  },
  favorableExchangeRateTab: {
    buy: {
      rate: currencyData.buy.rate,
      bankId: currencyData.buy.bank_id,
    },
    sell: {
      rate: currencyData.sell.rate,
      bankId: currencyData.sell.bank_id,
    },
  },
  forecastTab: currencyData.forecast.map(({ date_from, date_to, rate }) => ({
    dateFrom: date_from,
    dateTo: date_to,
    rate,
  })),
});

/**
 * Адаптер данных для страны/города.
 * Приводит поля к camelCase, некоторые объекты к одному виду.
 * @param region - объект данных для одного региона.
 */
export const getApiTravelRegionInfo = (
  region: TravelRegionDataType | undefined,
): TravelRegionType => ({
  alias: region?.alias || '',
  cities: region?.cities,
  galleries: region?.galleries,
  geoType: region?.geo_type || '',
  id: region?.id ?? 0,
  name: region?.name || '',
  namer: region?.namer || '',
  regionInfo: {
    annotation: region?.region_info?.annotation || '',
    description: region?.region_info?.description || '',
    id: region?.region_info.id ?? 0,
    image: region?.region_info.image,
    isCapital: region?.region_info.is_capital ?? false,
    longTitle: region?.region_info.long_title || '',
    seoDescription: region?.region_info.seo_description || '',
    seoTitle: region?.region_info.seo_title || '',
  },
  short: region?.short || '',
  country: region?.country?.country,
});

/**
 * Адаптер данных для стран и городов.
 * Приводит поля к camelCase, некоторые объекты к одному виду.
 * @param regions - массив полученных регионов.
 */
export const getApiTravelRegions = (
  regions: TravelRegionDataType[],
): TravelRegionType[] =>
  regions?.map((region) => getApiTravelRegionInfo(region));

/**
 * Адаптер данных для мест.
 * Приводит поля к camelCase, некоторые объекты к одному виду.
 * @param places - массив полученных мест.
 */
export const getApiTravelPlaces = (
  places: TravelPlaceDataType[],
): TravelPlaceType[] =>
  places.map((place) => ({
    id: place.id,
    alias: place.alias || '',
    galleries: place.galleries,
    annotation: place.annotation || '',
    description: place.description || '',
    image: place.image && {
      id: place.image.id,
      url: place.image.url || '',
    },
    name: place.name || '',
    seoDescription: place.seo_description || '',
    seoTitle: place.seo_title || '',
    cityName: place.region.name || '',
    countryName: place.region.country?.name || '',
    countryAlias: place.region.country?.alias || '',
  }));
