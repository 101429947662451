import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _omit from 'lodash.omit';

import { RCM_BLOCK_TYPE } from 'config/constants/rcm';

import { recommendedClustersAdapter } from './adapter';

type InitialStateType = {
  [rcmBlockType: string]: Omit<ATRecommender, 'recommendations'> & {
    clusterIds: CardData['id'][];
  };
};

const CARD_DATA_DUPLICATE_KEYS = [
  'itemTitle',
  'itemPubDate',
  'description',
  'itemUrl',
  'itemImage',
  'displayType',
  'topic',
] as const;

/**
 * Слайс кластеров от рекомендов по типу блоков
 */
const recommendedClustersSlice = createSlice({
  name: 'recommendedClusters',
  initialState: recommendedClustersAdapter.getInitialState(
    {} as InitialStateType,
  ),
  reducers: {
    /**
     * Метод добавления кластеров (если кластер с таким id уже есть то не добавляется)
     * нужен чтобы например карточки рекомендаций не затирали данные которые уже есть
     * @param action.payload - массив кластеров или данных карточек.
     */
    addRecommends: (
      state,
      action: PayloadAction<{
        data: ATRecommender;
        rcmBlockType: RCM_BLOCK_TYPE;
      }>,
    ) => {
      const { data, rcmBlockType } = action.payload;
      const { recommendations, ...blockData } = data;
      const clusterIds = recommendations.map(({ itemID }) => itemID);

      /*
       * удаляем поля которые преобразуются внутри createRecCardData, чтобы хранить только те данные, которых нет в CardData
       * это нужно чтобы избежать дублирования данных, если какие то из этих полей понадобились, то кластер должен браться из entries
       */
      const reducedRecommendations = recommendations.map((item) =>
        _omit(item, CARD_DATA_DUPLICATE_KEYS),
      );

      state[rcmBlockType] = { ...blockData, clusterIds };
      recommendedClustersAdapter.addMany(state, reducedRecommendations);
    },
  },
});

export const recommendedClustersReducer = recommendedClustersSlice.reducer;
export const { addRecommends } = recommendedClustersSlice.actions;
