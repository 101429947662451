import { createAsyncThunk } from '@reduxjs/toolkit';

import { getExpertNewsByProject } from 'api';
import { addManyEntries } from 'common/redux/commonData/entries';
import {
  selectApiConfig,
  selectDomainConfig,
  selectVariables,
} from 'common/redux/runtime/selectors';
import { adaptClusterToCard } from 'utils/adapters';

type FetchExpertClustersPropsType = {
  expertId: number | undefined;
};

/**
 * Получение списка новостей эксперта.
 * @param props.expertId - id эксперта.
 */
export const fetchExpertClusters = createAsyncThunk(
  'fetchExpertClusters',
  async (
    { expertId }: FetchExpertClustersPropsType,
    { dispatch, getState },
  ) => {
    const state = getState() as IAppState;

    const apiConfig = selectApiConfig(state);
    const domainConfig = selectDomainConfig(state);
    const variables = selectVariables(state);

    if (!expertId) {
      throw new Error(`Отсутствует expertId: ${expertId}`);
    }

    const { error, data } = await getExpertNewsByProject(apiConfig, expertId);

    if (error || !data) {
      throw (
        error ||
        new Error(`Ошибка при получение новостей эксперта: ${expertId}`)
      );
    }

    const modifyClusters = data.map((cluster) =>
      adaptClusterToCard({ cluster, domainConfig, variables }),
    );

    dispatch(addManyEntries(modifyClusters));

    return modifyClusters.map(({ id }) => id);
  },
);
