import { BankObjectsType } from './typings';

export const NO_DATA_SYMBOL = '—';

/**
 * Тулза, трансформирующая небезопасные спецсимволы
 * в символы unicode.
 * @param stringWithSpec - строка, в которой надо сделать подмену.
 * @returns очищенная строка.
 */
export const changeSpecSymbolsToUnicode = (stringWithSpec: string) =>
  stringWithSpec
    .replaceAll('&mdash;', '\u2014')
    .replaceAll('&nbsp;', '\u00A0')
    .replaceAll('&laquo;', '\u00AB')
    .replaceAll('&raquo;', '\u00BB')
    .replaceAll('&#8470;', '\u2116');

/**
 * Тулза для фильтрации небезопасных данных в описании объектов банков.
 * Отсутствующие данные приводятся к тире.
 * @param bankObjects - массив данных с объектами банков,
 *  которые нужно отфильтровать.
 * @returns отфильтрованный массив данных с объектами банков.
 */
export const fixUnsafeSymbolsInData = (
  bankObjects: BankObjectsType[],
): BankObjectsType[] =>
  bankObjects.map(({ id, name, address, phone, schedule }) => {
    const fixedSchedule = schedule
      ? changeSpecSymbolsToUnicode(schedule)
      : NO_DATA_SYMBOL;
    const fixedAddress = `${changeSpecSymbolsToUnicode(name)}\n${address}`;

    return {
      id,
      name,
      address: fixedAddress,
      phone: phone ? phone.replaceAll(',', ',\n') : '—',
      schedule: fixedSchedule,
    };
  });
