import { useSelector } from 'react-redux';

import {
  selectIsMobile,
  selectProjectAlias,
} from 'common/redux/runtime/selectors';
import { APP_TYPE } from 'config/constants/devices';

export type Top100AttributeType = { [key: string]: string | number };

/**
 * Формирует data атрибут для топ100 в зависимости от вертикали и устройства
 */
export const useTop100Attribute = () => {
  const isMobile = useSelector(selectIsMobile);
  const projectAlias = useSelector(selectProjectAlias);

  return `data-${projectAlias}_media-${
    isMobile ? APP_TYPE.mobile : APP_TYPE.desktop
  }`;
};

/**
 * Возвращает объект в котором ключ это атрибут для топ100, а знначение это разметка для элемента
 * @param value Значение разметки для топ100
 */
export const useTop100AttributeWithValue = (
  value: string | number | undefined,
) => {
  const top100Attribute = useTop100Attribute();

  return value ? { [top100Attribute]: value } : {};
};
