import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { matchType } from 'common/redux/utils';
import { PERIODS } from 'config/constants/finance';

import { CURRENCY_SOURCES } from '../exchangeRatesWidget/typings';

import {
  loadDataForManyCourseChart,
  loadDataForSingleCourseChart,
} from './asyncs';
import { ChartDataType } from './typings';

type ChartWidgetType = {
  period: PERIODS;
  tooltipTitle: string;
  chartData: ChartDataType[];
  minY: number;
  maxY: number;
  averageVal: number;
  changeBetween: number;
  disabledPeriods: Record<CURRENCY_SOURCES, PERIODS[]>;
} & Fetchable;

type FullFilledActionType = PayloadAction<{
  chartData: ChartDataType[];
  minY: number;
  maxY: number;
  average: number;
  change: number;
}>;

type SetTooltipTitleActionType = PayloadAction<string>;

const initialState: ChartWidgetType = {
  chartData: [] as ChartDataType[],
  tooltipTitle: '',
  minY: 0,
  maxY: 0,
  changeBetween: 0,
  averageVal: 0,
  period: PERIODS.week,
  disabledPeriods: {
    [CURRENCY_SOURCES.Centrobank]: [] as PERIODS[],
    [CURRENCY_SOURCES.MMCB]: [] as PERIODS[],
    [CURRENCY_SOURCES.Forex]: [] as PERIODS[],
  },
  fetching: false,
  error: undefined,
};

const thunks = [loadDataForSingleCourseChart, loadDataForManyCourseChart];

/**
 * Слайс виджета для графика.
 */
const chartWidgetSlice = createSlice({
  name: 'chartWidget',
  initialState,
  reducers: {
    setTooltipTitle: (state, action: SetTooltipTitleActionType) => {
      state.tooltipTitle = action.payload;
    },
  },
  extraReducers: (builder) => {
    // @ts-expect-error: поправить при переезде на новый синтаксис
    builder.addMatcher(matchType('pending', thunks), (state) => {
      state.fetching = true;
    });

    builder.addMatcher(
      // @ts-expect-error: поправить при переезде на новый синтаксис
      matchType('fulfilled', thunks),
      (state, action: FullFilledActionType) => {
        state.fetching = false;
        state.error = undefined;

        state.chartData = action.payload.chartData;
        state.minY = action.payload.minY;
        state.maxY = action.payload.maxY;
        state.averageVal = action.payload.average;
        state.changeBetween = action.payload.change;
      },
    );

    builder.addMatcher(
      // @ts-expect-error: поправить при переезде на новый синтаксис
      matchType('rejected', thunks),
      (state, action: { error: Error }) => {
        state.fetching = false;
        state.error = action.error.message;
      },
    );
  },
});

export const chartWidgetReducer = chartWidgetSlice.reducer;
export const { setTooltipTitle } = chartWidgetSlice.actions;
