import { createEntityAdapter } from '@reduxjs/toolkit';

type RcmType = Omit<
  ATRcmType,
  | 'itemTitle'
  | 'itemPubDate'
  | 'description'
  | 'itemUrl'
  | 'itemImage'
  | 'displayType'
  | 'top'
>;

/**
 * Адаптер кластеров от рекомендов [id кластера]: { данные о рекомендованном кластере }
 */
export const recommendedClustersAdapter = createEntityAdapter({
  selectId: (cluster: RcmType) => cluster.itemID,
});
