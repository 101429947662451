import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { bankRegionCharToIdAdapter, bankRegionsAdapter } from './adapter';
import { fetchBankRegions } from './asyncs';
import { BankRegionType } from './typings';

type AddBankRegionsPayloadActionType = PayloadAction<BankRegionType[]>;

const initialState: Errorable & Fetchable = {
  fetching: false,
  error: undefined,
};

/**
 * Слайс для виджета регионов банков.
 */
const bankRegionsSlice = createSlice({
  name: 'bankRegions',
  initialState: bankRegionsAdapter.getInitialState(
    bankRegionCharToIdAdapter.getInitialState(initialState),
  ),
  reducers: {
    addBankRegions: (state, { payload }: AddBankRegionsPayloadActionType) => {
      bankRegionsAdapter.upsertMany(state, payload);
      bankRegionCharToIdAdapter.upsertMany(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBankRegions.pending, (state) => {
      state.fetching = true;
      state.error = '';
    });
    builder.addCase(fetchBankRegions.fulfilled, (state, { payload }) => {
      bankRegionsAdapter.upsertMany(state, payload);
      bankRegionCharToIdAdapter.upsertMany(state, payload);
      state.fetching = false;
      state.error = '';
    });
    builder.addCase(fetchBankRegions.rejected, (state, { error }) => {
      state.fetching = false;
      state.error = error.message;
    });
  },
});

export const { addBankRegions } = bankRegionsSlice.actions;

export const bankRegionsReducer = bankRegionsSlice.reducer;
