const excludePhonesList = [
  {
    ua: 'Mozilla/5.0 (Linux; Android 8.0.0; G3112',
  },
];

export const checkingXperias = (): boolean => {
  const { userAgent } = window.navigator;

  return excludePhonesList.some(({ ua }) => userAgent?.includes(ua));
};
