import { selectClusterExpertIds } from '../entries/selectors';
import { selectCommonData } from '../selectors';

import { expertsAdapter } from './adapter';

const selectors = expertsAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).experts,
);

export const selectExperts = (state: IAppState) =>
  selectCommonData(state).experts;

export const selectExpertById =
  (expertId: ClusterResourceType['id'] | null) => (state: IAppState) =>
    expertId ? selectors.selectById(state, expertId) : undefined;

export const selectExpertsByIds =
  (expertIds: ClusterResourceType['id'][] | undefined) =>
  (state: IAppState) => {
    if (!Array.isArray(expertIds)) return [];

    return expertIds
      .filter((expert) => expert)
      .map((id) => selectors.selectById(state, id)) as ATExpertType[];
  };

export const selectExpertsLoadingState = (state: IAppState) =>
  selectExperts(state).fetching;

export const selectExpertsByClusterId =
  (clusterId: CardData['id']) => (state: IAppState) => {
    const expertIds = selectClusterExpertIds(clusterId)(state);

    return selectExpertsByIds(expertIds)(state);
  };

export const selectExpertAliasToId = (state: IAppState) =>
  selectExperts(state).aliasToId;

export const selectExpertIdByAlias =
  (expertAlias: ATExpertType['alias'] | undefined) => (state: IAppState) =>
    selectExpertAliasToId(state)[expertAlias ?? ''];

export const selectExpertByAlias =
  (expertAlias: ATExpertType['alias'] | undefined) => (state: IAppState) =>
    selectExpertById(selectExpertIdByAlias(expertAlias)(state))(state);

export const selectExperId =
  (expertAlias: ATExpertType['alias'] | undefined) => (state: IAppState) =>
    selectExpertByAlias(expertAlias)(state)?.id;
