import _pick from 'lodash.pick';

import { PuidsType } from 'config/constants/common';

/**
 * @param text - проверяемый на вставку видео через iframe текст
 */
export const checkIframeInText = (text: string) => text.includes('iframe');

// Puids, которые нужно передавать при вставке видео EaglePlatform
export const EAGLEPLATFORM_PUIDS: { [puid in string]: boolean } = {
  puid6: true,
  puid15: true,
  puid59: true,
  puid62: true,
  puid48: true,
};

type PuidsForEaglePlatformType = {
  puids: PuidsType | undefined;
  newPlayer?: boolean;
};

/**
 * Функция, возвращающая строку или объект с пуидами для видео EaglePlatform
 * @param puids - рекламные пуиды
 * @param newPlayer - флаг, что пуиды нужны для нового плеера
 */
export const getPuidsStrForEaglePlatform = ({
  puids,
  newPlayer = false,
}: PuidsForEaglePlatformType): PuidsType | undefined => {
  if (puids && newPlayer) {
    return _pick(puids, Object.keys(EAGLEPLATFORM_PUIDS));
  }

  return newPlayer ? undefined : {};
};

/**
 * Функция преобразования iframe с видео
 * @param text - текст в котором есть видео iframe
 * @param puids - рекламные пуиды
 * @returns - текст с проставленным ad_template_id и puids в видео iframe
 */
export const changeVideoIframe = (text: string, puids?: PuidsType) => {
  const srcRegexp = new RegExp(/src\s*=\s*"(.+?)"/);
  const iframeSrc = text.match(srcRegexp);

  const puidsStr = getPuidsStrForEaglePlatform({ puids });
  const formattedText = `${puidsStr}`;

  return iframeSrc
    ? text.replace(iframeSrc[1], `${iframeSrc[1]}${formattedText}`)
    : text;
};
