import { createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type ExchangeState = {
  puids: PuidsType;
};

const initialState: ExchangeState = {
  // Рекламные пуиды
  puids: {},
};

const exchangeSlice = createSlice({
  name: PAGE_TYPE.exchange,
  initialState,
  reducers: {
    /**
     * Генерация puids для страницы.
     */
    setExchangePuids(state) {
      const puidName = getPuidName(PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_banks`.toUpperCase(),
        puid18: `${puidName}_banks_exchange`.toUpperCase(),
      };
    },
  },
});

export const exchangeReducer = exchangeSlice.reducer;

export const { setExchangePuids } = exchangeSlice.actions;
