import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUserChainId } from 'common/redux/commonData/user';
import { selectIsDebug } from 'common/redux/runtime/selectors';

/**
 * Функция-генератор реакции на эвент получения данных от юзера.
 */
export const useToplineAuth = () => {
  const dispatch = useDispatch();
  const isDebug = useSelector(selectIsDebug);

  return useCallback(
    (userId: string) => {
      dispatch(setUserChainId(userId || null));

      if (!userId && (__DEV__ || isDebug)) {
        console.error('useToplineAuth. Не обнаружен userId.');
      }
    },
    [dispatch, isDebug],
  );
};
