import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type StateEditorsType = {
  puids: PuidsType;
  editorsIds: FullEditorType['id'][];
} & Fetchable;

const editorsSlice = createSlice({
  name: PAGE_TYPE.editors,
  initialState: {
    puids: {},
    editorsIds: [],
    fetching: false,
  } as StateEditorsType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setEditorsPuids: (state, action: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_main`.toUpperCase(),
      };
    },

    /**
     * Айдишники редакторов/авторов доступные для отображения.
     * @param action.payload - массив айдишников редакторов/авторов.
     */
    setEditorsIds: (state, action: PayloadAction<FullEditorType['id'][]>) => {
      state.editorsIds = action.payload;
    },
  },
});

export const editorsReducer = editorsSlice.reducer;

export const { setEditorsPuids, setEditorsIds } = editorsSlice.actions;
