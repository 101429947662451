import { COUNTERS_NAMES, MetricsParamsType } from 'server/typings';

import { hitRequestErrorCounter } from '../../index';

type LocalCountersType = {
  [x in COUNTERS_NAMES]?: (params: MetricsParamsType<x>) => void;
};

export const LOCAL_COUNTERS: LocalCountersType = {
  [COUNTERS_NAMES.RequestError]: (
    params: MetricsParamsType<COUNTERS_NAMES.RequestError>,
  ) => {
    hitRequestErrorCounter({
      entrypoint: params.entrypoint,
      status: params.status,
      errorType: params.errorType,
    });
  },
};
