import { getPuidName } from 'common/redux/utils';
import {
  CLUSTER_TYPE,
  AUTOTAG_TYPE,
  CLUSTER_TAG_ID_TYPE,
} from 'config/constants/cluster';
import { PUIDS, PuidsType } from 'config/constants/common';

export const PUID15_MAP = {
  [CLUSTER_TYPE.news]: 'news',
  [CLUSTER_TYPE.photo]: 'photogallery',
  [CLUSTER_TYPE.video]: 'video',
  [CLUSTER_TYPE.article]: 'article',
  [CLUSTER_TYPE.test]: 'test',
};

/**
 * Та же функция генерации пуида, но менее требовательная к типам.
 * Предыдущую версию можно удалить, когда не станет компонента кластера, её использующего.
 * @param projectAlias - данные об никнейме проекта;
 * @param rawTopicAlias - данные о никнейме топика;
 * @param clusterType - данные о типе кластера;
 * @param clusterIsPaid - данные о том, что кластер является коммерческим;
 * @param clusterIsTrash - данные о том, что это кластер со смертью;
 * @param clusterAutotags - данные об автотегах кластера;
 * @param clusterTags - данные о тегах кластера.
 */
export const generateClusterPagePuids = ({
  projectAlias,
  topicAlias: rawTopicAlias,
  clusterType,
  clusterIsPaid,
  clusterIsTrash,
  clusterAutotags,
  clusterTags,
}: {
  projectAlias: ProjectType['alias'];
  topicAlias: TopicType['alias'];
  clusterType: ClusterData['type'];
  clusterIsPaid: ClusterData['isPaid'];
  clusterIsTrash: ClusterData['isTrash'];
  clusterAutotags: ATAutotagInfos;
  clusterTags: (ClusterTagsType | ManualTagData)[];
}): PuidsType => {
  const puidName = getPuidName(projectAlias);
  const topicAlias = rawTopicAlias.replace(/_/g, '').replace(/-/g, '');

  const puids: Partial<PuidsType> = {
    [PUIDS.puid6]: `${puidName}_${topicAlias}`.toUpperCase(),
    [PUIDS.puid15]: clusterType ? PUID15_MAP[clusterType] : undefined,
  };

  if (clusterIsPaid) {
    puids[PUIDS.puid48] = '1';
  }

  // Коммерческий контент
  const isCommercial = clusterAutotags.some(
    ({ autotagType: type }) => type === AUTOTAG_TYPE.category,
  );

  if (isCommercial) {
    const commercialAliasList = clusterAutotags
      .filter(({ autotagType: type }) => type === AUTOTAG_TYPE.category)
      .map(({ alias }) => alias);

    puids[PUIDS.puid59] = commercialAliasList.join(':');
  }

  // Трагический контент
  const setPuid62Tragedy = clusterTags.find(
    ({ id }) => id === CLUSTER_TAG_ID_TYPE.tragedy,
  );
  // Контент со смертью
  const setPuid62Death = clusterIsTrash;

  if (setPuid62Tragedy && setPuid62Death) {
    puids[PUIDS.puid62] = '1:10';
  } else if (setPuid62Tragedy) {
    puids[PUIDS.puid62] = '10';
  } else if (setPuid62Death) {
    puids[PUIDS.puid62] = '1';
  }

  return puids;
};
