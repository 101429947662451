import { MULTICLUSTER_TAG_ALIASES } from 'config/constants/cluster';

import { selectCommonData } from '../selectors';

import { manualTagsAdapter } from './adapter';

const selectors = manualTagsAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).manualTags,
);

export const selectManualTags = (state: IAppState) =>
  selectCommonData(state).manualTags;

export const selectManualTagById =
  (manualTagId: ClusterResourceType['id']) => (state: IAppState) =>
    manualTagId ? selectors.selectById(state, manualTagId) : undefined;

export const selectManualTagsLoadingState = (state: IAppState) =>
  selectManualTags(state).fetching;

export const selectManualTagIsMulticluster =
  (
    manualTagIds: ClusterResourceType['id'][] | undefined,
    displayType: ClusterData['displayType'],
  ) =>
  (state: IAppState) => {
    if (displayType === 'multicluster') return true;

    const multiclusterAliases = Object.values(MULTICLUSTER_TAG_ALIASES);

    return (
      manualTagIds?.some((tagId) =>
        multiclusterAliases.includes(
          selectManualTagById(tagId)(state)?.alias as MULTICLUSTER_TAG_ALIASES,
        ),
      ) || false
    );
  };
