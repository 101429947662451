import { createAsyncThunk } from '@reduxjs/toolkit';

import { getClusterStories } from 'api';
import { getApiThemeInfo } from 'api/adapters';
import { selectApiConfig } from 'common/redux/runtime/selectors';

/**
 * Функция загрузки сюжета кластера.
 * @param clusterId - id кластера, для которого загружаются сюжеты;
 * @param callback - коллбек, которому возвращаются сокращенные данные о сюжетах кластера.
 */
export const fetchThemesByCluster = createAsyncThunk(
  'fetchThemesByCluster',
  async (
    {
      clusterId,
      callback,
    }: {
      clusterId: ClusterData['id'];
      callback?: (resource: ATThemeInfo[]) => Promise<void> | void;
    },
    { getState },
  ) => {
    const api = selectApiConfig(getState() as IAppState);

    const { data: rawThemes, error } = await getClusterStories(api, clusterId);

    if (error || !rawThemes) {
      throw (
        error ||
        new Error(`Ошибка при получении топиков по кластеру: ${clusterId}`)
      );
    }

    const themes = rawThemes
      .map(getApiThemeInfo)
      .filter(Boolean) as ATThemeInfo[];

    if (callback) await callback(themes);

    return themes;
  },
);
