export const BLUE_COLOR = '#315efb';
export const BLUE_HOVER_COLOR = '#2c54e2';
export const BLUE_LIGHT_COLOR = '#eff5ff';
export const NEWS_COLOR = BLUE_COLOR;
export const WOMAN_COLOR = BLUE_COLOR;
export const KINO_COLOR = '#c60d8b';
export const SPORT_COLOR = '#ffc000';
export const AUTO_COLOR = '#274bc8';
export const FINANCE_COLOR = '#28bc00';
export const WEEKEND_COLOR = '#6674a6';
export const TRAVEL_COLOR = '#ff9800';
export const DOCTOR_COLOR = '#00baad';
export const GAMES_COLOR = '#649dff';
export const POSITIVE_COLOR = '#209600';
export const NEGATIVE_COLOR = '#e54d46';
export const WHITE_COLOR = '#ffffff';
export const LIGHT_GRAY_COLOR = '#d1d5e0';
export const DARK_GRAY_COLOR = '#777e98';
export const LIGHT_BLUE_COLOR = '#c1d8ff';

export const COPY_COLOR = '#343b4c';

export const WINDOW_WIDTH_COMPACT = 1299;
export const WINDOW_WIDTH_SMALL = 995;

export const CONTENT_WIDTH_MOBILE = 640;

export const IMAGE_HEIGHT = 350;
export const IMAGE_HEIGHT_COMPACT = 365;

export const IMAGE_HEIGHT_SLIDE = 565;
export const IMAGE_HEIGHT_SLIDE_COMPACT = 390;

export const IMAGE_HEIGHT_LONGREAD_BIG = 550;
export const IMAGE_WIDTH_LONGREAD_BIG = 985;
export const IMAGE_HEIGHT_LONGREAD_SMALL = 430;
export const IMAGE_WIDTH_LONGREAD_SMALL = 768;
