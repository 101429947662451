import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { fetchDateClusters } from './asyncs';

type DateInfoType = {
  title: string;
  year: string;
  month: string;
  day: string;
  fullDate: string;
};

type StateDateType = {
  puids: PuidsType;
  info: DateInfoType;
  clustersIds: CardData['id'][];
  currentPage: number;
  hasNextPage: boolean;
  requestId: string;
} & Fetchable;

const dateSlice = createSlice({
  name: PAGE_TYPE.date,
  initialState: {
    puids: {},
    info: {} as DateInfoType,
    clustersIds: [],
    currentPage: 1,
    hasNextPage: false,
    fetching: false,
    requestId: '',
  } as StateDateType,
  reducers: {
    /**
     * Генерация puids для страницы.
     * @param action.payload - alias проекта, на котором запускается приложение.
     */
    setDatePuids: (state, action: PayloadAction<PROJECT_ALIASES>) => {
      const puidName = getPuidName(action.payload);

      state.puids = {
        puid6: `${puidName}_feed`.toUpperCase(),
        puid18: `${puidName}_feed_day`.toUpperCase(),
      };
    },

    /**
     * Установка информации для страницы.
     * @param action.payload - данные для страницы.
     */
    setDateInfo: (state, action: PayloadAction<DateInfoType>) => {
      state.info = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDateClusters.pending, (state, { meta }) => {
        state.fetching = true;
        state.requestId = meta.requestId;

        // Сброс данных о загруженных кластерах чтобы при смене дат в календаре не показывались уже загруженные кластера за другие даты
        if (meta.arg.page === 1) {
          state.clustersIds = [];
          state.currentPage = 1;
          state.hasNextPage = false;
          state.error = '';
        }
      })
      .addCase(fetchDateClusters.fulfilled, (state, { payload }) => {
        const { clusterIds, currentPage, hasNextPage } = payload;

        state.clustersIds = [...state.clustersIds, ...clusterIds];
        state.currentPage = currentPage;
        state.hasNextPage = hasNextPage;
        state.fetching = false;
      })
      .addCase(fetchDateClusters.rejected, (state, action) => {
        // костыль тк выполняется abort(), потом fetch нового запроса, потом error предыдущего запроса
        if (action.meta.requestId === state.requestId) {
          state.fetching = false;
          state.error = action.error.message;
        }
      });
  },
});

export const dateReducer = dateSlice.reducer;

export const { setDatePuids, setDateInfo } = dateSlice.actions;
