import { getCommentsByClusterID, getRecommendedClusters } from 'api';
import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectAdtech,
  selectApiConfig,
  selectDomainConfig,
  selectIsBot,
  selectProjectId,
  selectRuid,
  selectUserId,
  selectVariables,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { selectPageTopic } from 'common/redux/selectors';
import { createSlice } from 'common/redux/utils';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { RUID_MOCK } from 'config/constants/recommend';
import { adaptClusterToCardRcm } from 'utils/adapters';

import { addManyEntries } from '../../entries';
import { addRecommends } from '../../recommendedClusters';

const FETCHING_RCM_LIMIT = 5;

type FetchRecommendedTeaserPropsType = {
  clusterId: CardData['id'];
  itemExcludedIds: CardData['id'][];
  sessionId?: string;
};

type RecommendationTeaserType = {
  clusterIds: CardData['id'][];
} & Fetchable;

/**
 * Слайс тизера рекоммендаций для кластеров-статей.
 * Временно не используется. Причины в NEWS-12018
 */
const recommendationTeaserSlice = createSlice({
  name: 'recommendationTeaser',
  initialState: {
    clusterIds: [],
    fetching: false,
    error: '',
  } as RecommendationTeaserType,
  reducers: (create) => ({
    /**
     * Функция загрузки новостей для тизера рекомендаций.
     * @param clusterId - id кластера, для которого грузятся рекоммендации;
     * @param itemExcludedIds - список id кластеров, которые будут исключены из выдачи рекоммендаций.
     * @param sessionId - id сессии общения с рекомендами
     */
    fetchRecommendedTeaser: create.asyncThunk(
      async (
        {
          clusterId,
          itemExcludedIds,
          sessionId,
        }: FetchRecommendedTeaserPropsType,
        { getState, dispatch },
      ) => {
        /* Использовать аккуратно - стейт перестает динамически обновляться */
        const state = getState() as IAppState;

        const variables = selectVariables(state);
        const domainConfig = selectDomainConfig(state);
        const projectId = selectProjectId(state);
        const isBot = selectIsBot(state);
        const adtech = selectAdtech(state);
        const userId = selectUserId(state);
        const ruid = selectRuid(state);
        const pageTopic = selectPageTopic(state);
        const apiConfig = selectApiConfig(state);
        const pageName = selectPageName(state);
        const isMobile = selectIsMobile(state);

        const blockId = adtech.recommendBlockID[RCM_BLOCK_TYPE.topNowDesktop];

        const { data, error } = await getRecommendedClusters({
          blockId,
          xuid: ruid || (__DEV__ ? RUID_MOCK : ''),
          itemId: clusterId,
          itemExcludedIds,
          limit: FETCHING_RCM_LIMIT,
          userId,
          pageName,
          isBot,
          category: pageTopic?.name,
          apiConfig,
          sessionID: sessionId,
        });

        if (error || !data) {
          throw error;
        }

        dispatch(
          addRecommends({
            data,
            rcmBlockType: isMobile
              ? RCM_BLOCK_TYPE.clusterFeedMobile
              : RCM_BLOCK_TYPE.clusterFeedDesktop,
          }),
        );

        const clusterIds = data.recommendations.map((item) => item.itemID);

        const { data: comments, error: commentsError } =
          await getCommentsByClusterID(apiConfig, clusterIds);

        if (commentsError || !comments) {
          throw commentsError;
        }

        const cards = data.recommendations.map((cluster, index) =>
          adaptClusterToCardRcm({
            cluster,
            projectId,
            domainConfig,
            variables,
            commentsCount: comments[index].comments_count,
          }),
        );

        dispatch(addManyEntries(cards));

        // slice оставляем тк ручка лагает и иногда присылает больше рекомендаций чем запрашиваем
        return {
          clusterIds: cards.map(({ id }) => id).slice(0, FETCHING_RCM_LIMIT),
          sessionId: data.sessionID,
        };
      },

      {
        pending: (state) => {
          state.fetching = true;
          state.error = '';
        },
        fulfilled: (state, { payload }) => {
          state.clusterIds = payload.clusterIds;
          state.fetching = false;
        },
        rejected: (state, { error }) => {
          state.error = error.message || '';
        },
        settled: (state) => {
          state.fetching = false;
        },
      },
    ),
  }),
});

export const { fetchRecommendedTeaser } = recommendationTeaserSlice.actions;

export const recommendationTeaserReducer = recommendationTeaserSlice.reducer;
