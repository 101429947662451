import { selectClusterById } from '../entries/selectors';
import { selectCommonData } from '../selectors';

import { itemsAdapter } from './adapter';

const selectors = itemsAdapter.getSelectors(
  (state: IAppState) => selectCommonData(state).items,
);

export const selectItems = (state: IAppState) => selectCommonData(state).items;

export const selectItemsById =
  (itemId: ClusterResourceType['id']) => (state: IAppState) =>
    selectors.selectById(state, itemId);

export const selectItemsIds =
  (clusterId: ClusterData['id']) => (state: IAppState) =>
    selectClusterById(clusterId)(state)?.itemIds;

export const selectItemsLoadingState = (state: IAppState) =>
  selectItems(state).fetching;
