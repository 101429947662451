import { selectCardById } from 'common/redux/commonData/entries/selectors';

import { selectPages } from '../selectors';

export const selectTheme = (state: IAppState) => selectPages(state).theme;

export const selectThemePuids = (state: IAppState) => selectTheme(state).puids;

export const selectThemeInfo = (state: IAppState) => selectTheme(state).info;

export const selectThemeClustersIds = (state: IAppState) =>
  selectTheme(state).clustersIds;

export const selectThemeCurrentPage = (state: IAppState) =>
  selectTheme(state).currentPage;

export const selectThemeHasNextPage = (state: IAppState) =>
  selectTheme(state).hasNextPage;

export const selectThemeFetchingState = (state: IAppState) =>
  selectTheme(state).fetching;

export const selectThemeErrorState = (state: IAppState) =>
  selectTheme(state).error;

export const selectThemeClusters = (state: IAppState) =>
  selectThemeClustersIds(state).map((clusterId) =>
    selectCardById(clusterId)(state),
  ) || [];
