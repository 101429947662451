import { PUIDS, PuidsType } from 'config/constants/common';

/* https://confluence.rambler-co.ru/pages/viewpage.action?pageId=39128552#id-%D0%97%D0%BD%D0%B0%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%D0%BF%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D0%BE%D0%B2puid%D0%B4%D0%BB%D1%8F%D0%BC%D0%B5%D0%B4%D0%B8%D0%B9%D0%BD%D1%8B%D1%85%D0%B2%D0%B5%D1%80%D1%82%D0%B8%D0%BA%D0%B0%D0%BB%D0%B5%D0%B9-PUID41(%D0%A8%D0%B8%D1%80%D0%B8%D0%BD%D0%B0%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0) */
const PUID41_BID = '1';

/**
 * Получение настроек рекламы относительно вертикалей.
 * @param alias - символьное имя проекта.
 * @returns объект с настройками рекламы по вертикали.
 */
const getPuid31Obj = (
  alias: ProjectType['alias'],
): { [PUIDS.puid31]: string } => ({
  [PUIDS.puid31]: `${alias}.rambler`,
});

/**
 * Получение puids, настроек для баннера
 * @param banner -  объект конфигурации баннера
 * @param puids - рекламные пуиды
 * @param props - дополнительные конфигурации пуидов
 */
export const getBannerPuids = ({
  isMobile,
  alias,
  ...props
}: {
  isMobile: SettingsType['isMobile'];
  alias: ProjectType['alias'];
} & PuidsType) => {
  let puidsObj: PuidsType = isMobile ? {} : { puid41: PUID41_BID };

  if (alias) puidsObj = { ...puidsObj, ...getPuid31Obj(alias) };

  if (props) puidsObj = { ...puidsObj, ...props };

  return puidsObj;
};
