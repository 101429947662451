/**
 * Лимиты для получения рекомендательных кластеров в зависимости от вертикали (от 5 до 7)
 */
export const LIMIT_TOPIC_REC_SHORT = 5;
export const LIMIT_TOPIC_REC_MIDDLE = 6;
export const LIMIT_TOPIC_REC_LONG = 7;

export const LIMIT_FOR_FIRST_CLUSTERS_BLOCK = 12;

export const TEST_GREEN_TOPIC = ['economics'];

export const GREEN_TOPIC_ALIASES = [
  'vybrat-avto',
  'est-avto',
  'novosti',
  'hochu-kupit',
  'remont-i-obsluzhivanie',
  'prodazha-avto',
  'avtostrahovanie',
  'kvadrocikly-i-snegohody',
  'shtrafy-i-prava',
  'katalog-brendov',
  'diety',
  'profilaktika',
  'kak-pohudet',
  'medicinskiy-navigator',
  'diagnostika-i-lechenie',
  'pervaya-pomosch',
  'beremennost-i-rody',
  'razvitie-rebenka',
  'zdorove-rebenka',
  'povedenie-rebenka',
  'detskoe-pitanie',
  'navigator-lgot',
  'obrazovanie',
  'rabota',
  'produktivnost',
  'povyshenie-kvalifikacii',
  'yuridicheskaya-pomosch',
  'samozanyatye-ip',
  'lgoty-i-granty',
  'vklady',
  'kredity',
  'karty-i-platezhi',
  'ipoteka',
  'strahovanie',
  'bankovskie-produkty',
  'predprinimatelyu',
  'pomosch-yurista',
  'finansovaya-gramotnost',
  'filmy',
  'restorany',
  'kultura',
  'muzyka',
  'serialy',
  'kuda-poyti',
  'pereplanirovka',
  'dizayn-interera',
  'vospitanie',
  'otnosheniya',
  'remont',
  'sad-i-ogorod',
  'domashnie-zhivotnye',
  'igry',
  'soft',
  'zozh',
  'lekarstva',
  'bolezni',
  'karernyy-rost',
  'gadzhety',
  'roboty',
  'neyroseti',
  'trendy-ii',
  'chto-delat-esli',
  'finansovaya-bezopasnost',
  'socialnaya-podderzhka',
];
