import { createSlice } from '@reduxjs/toolkit';

/**
 * Стейт с общей информацией приложения @see Runtime.
 * Генерируется внутри @see runtimeGenerator и устанавливается в initialState через configureStore.
 * Не меняется после configureStore, поэтому здесь пусто.
 */
const runtimeSlice = createSlice({
  name: 'runtime',
  initialState: {},
  reducers: {},
});

export const runtimeReducer = runtimeSlice.reducer;
