import loadable from '@loadable/component';
import React from 'react';

import { fetchGigaChatData } from 'common/redux/commonData/gigaChat';
import { selectTopicsByProjectId } from 'common/redux/commonData/topics/selectors';
import { fetchTopicNews } from 'common/redux/commonData/widgets/topicNewsWidget/asyncs';
import { fetchTop, setHomePuids } from 'common/redux/pages/home';
import {
  selectProjectId,
  selectProjectAlias,
  selectIsHumanCenteredStrategyStateActivated,
  selectHumanCenteredStrategyValue,
} from 'common/redux/runtime/selectors';
import { selectIsGigaChatShow } from 'common/redux/selectors';
import { LIMIT_BY_CLUSTERS_FETCHING } from 'common/routes/constants';
import { appendPageDownloader } from 'common/routes/pageLoadable';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';
import { TOP_ID_PROJECT } from 'config/constants/tops';
import { IAppRoute, PromiseListType } from 'typings/AppRoute';
import { filterTopics } from 'utils/filterTopics';

import { getTopLimitBy } from '../utils';

import { loadWidgetData } from './utils';

const HomeDesktop = loadable(() => import('desktop/pages/Home'));
const HomeMobile = loadable(() => import('mobile/pages/Home'));

/**
 * Роут вертикали.
 * https://news.rambler.ru/
 */
export const homeRoutes = (isMobile: SettingsType['isMobile']): IAppRoute => ({
  name: PAGE_TYPE.home,
  exact: true,
  path: '/',

  /**
   * В целях уменьшения лишних ререндерингов попробовал передавать функцию рендеринга, а не непосредственно компонент,
   *  так как компоненту передаются пропсы вроде match, которые триггерят его ререндер.
   */
  render: isMobile ? () => <HomeMobile /> : () => <HomeDesktop />,
  fetchData: async ({ dispatch, getState }) => {
    const projectId = selectProjectId(getState());
    const projectAlias = selectProjectAlias(getState());
    const projectTopics = selectTopicsByProjectId(projectId)(getState());
    const filteredTopics = filterTopics(projectTopics, projectId);
    const forceRedesign =
      selectIsHumanCenteredStrategyStateActivated(getState());
    const redesignSplitValue = selectHumanCenteredStrategyValue(getState());
    const gigaEnabled = selectIsGigaChatShow(getState());

    const limitBy = getTopLimitBy({
      forceRedesign,
      redesignSplitValue,
      projectAlias,
      gigaEnabled,
      isMobile,
    });

    dispatch(setHomePuids(projectAlias));

    const promiseList: PromiseListType = [
      dispatch(
        fetchTop({
          topID: TOP_ID_PROJECT,
          length: LIMIT_BY_CLUSTERS_FETCHING,
          limitBy,
        }),
      ),
    ];

    if (projectId === PROJECT_IDS.News) {
      promiseList.push(dispatch(fetchGigaChatData()));
    }

    if (isMobile) {
      await Promise.all(
        appendPageDownloader({
          promiseList,
          Component: HomeMobile,
          key: Object.keys({ HomeMobile })[0],
        }),
      );

      return;
    }

    await Promise.all(
      appendPageDownloader({
        promiseList: [
          ...promiseList,
          ...loadWidgetData({ projectId }).map((promise) => dispatch(promise)),
          dispatch(fetchTopicNews({ topicId: filteredTopics[0]?.id })),
        ],
        Component: HomeDesktop,
        key: Object.keys({ HomeDesktop })[0],
      }),
    );
  },
});
