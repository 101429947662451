import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPuidName } from 'common/redux/utils';
import { PuidsType } from 'config/constants/common';
import { CURRENCY_CHAR_CODE } from 'config/constants/finance';
import { PROJECT_ALIASES } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

type ConverterState = {
  firstCurrency: CurrencyType['charCode'];
  firstCurrencyConverter: CurrencyType['charCode'];
  secondCurrency: CurrencyType['charCode'];
  secondCurrencyConverter: CurrencyType['charCode'];
  amount: number | undefined;
  crossCourse: number | undefined;
  fixedValue: number | undefined;
  puids: PuidsType;
  isLoading: boolean;
};

const initialState: ConverterState = {
  // Первая валюта, если она уже указана на странице.
  firstCurrency: CURRENCY_CHAR_CODE.RUB,
  // Первая валюта конвертера для расчета в форме.
  firstCurrencyConverter: CURRENCY_CHAR_CODE.RUB,
  // Вторая валюта, если она уже указана на странице.
  secondCurrency: CURRENCY_CHAR_CODE.USD,
  // Вторая валюта конвертера для расчета в форме.
  secondCurrencyConverter: CURRENCY_CHAR_CODE.USD,
  // Количество конвертируемых денег.
  amount: undefined,
  // Рассчитанный кросс-курс для валют.
  crossCourse: undefined,
  // Рассчитанное по кросс-курсу количество знаков после запятой.
  fixedValue: undefined,
  // Рекламные пуиды
  puids: {},
  // Флаг загрузки
  isLoading: false,
};

type SetCurrenciesPayloadAction = PayloadAction<{
  firstCurrency: CurrencyType['charCode'];
  secondCurrency: CurrencyType['charCode'];
  amount: number | undefined;
  crossCourse: number | undefined;
  fixedValue: number | undefined;
  firstCurrencyConverter?: CurrencyType['charCode'];
  secondCurrencyConverter?: CurrencyType['charCode'];
}>;

type SetCurrencyPayloadAction = PayloadAction<CurrencyType['charCode']>;

type SetCrossCoursePayloadAction = PayloadAction<number>;

const converterSlice = createSlice({
  name: PAGE_TYPE.converter,
  initialState,
  reducers: {
    /**
     * Установка валют для данной страницы.
     * @param action.payload - данные для инициализации страницы;
     * @param action.payload.firstCurrency - первая валюта из пары конвертера;
     * @param action.payload.secondCurrency - вторая валюта из пары конвертера;
     * @param action.payload.amount - количество денег для конвертации.
     * @param action.payload.crossCourse - кросс-курс
     * @param action.payload.fixedValue - расчитанное количество знаков после запятой.
     */
    setCurrencies(state, action: SetCurrenciesPayloadAction) {
      if (!action.payload) return;

      const {
        amount,
        firstCurrency,
        secondCurrency,
        crossCourse,
        fixedValue,
        firstCurrencyConverter,
        secondCurrencyConverter,
      } = action.payload;

      state.amount = amount;
      state.firstCurrency = firstCurrency;
      state.firstCurrencyConverter = firstCurrencyConverter || firstCurrency;
      state.secondCurrency = secondCurrency;
      state.secondCurrencyConverter = secondCurrencyConverter || secondCurrency;
      state.crossCourse = crossCourse;
      state.fixedValue = fixedValue;
    },

    /**
     * Установка первой валюты.
     * @param action.payload.currency - первая валюта из пары конвертера.
     */
    setFirstCurrency(state, { payload }: SetCurrencyPayloadAction) {
      state.firstCurrency = payload;
    },

    /**
     * Установка первой валюты конвертера для расчета в форме.
     * @param action.payload.currency - первая валюта из пары конвертера.
     */
    setFirstCurrencyConverter(state, { payload }: SetCurrencyPayloadAction) {
      state.firstCurrencyConverter = payload;
    },

    /**
     * Установка второй валюты.
     * @param action.payload.currency - вторая валюта из пары конвертера.
     */
    setSecondCurrency(state, { payload }: SetCurrencyPayloadAction) {
      state.secondCurrency = payload;
    },

    /**
     * Установка второй валюты конвертера для расчета в форме.
     * @param action.payload.currency - вторая валюта из пары конвертера.
     */
    setSecondCurrencyConverter(state, { payload }: SetCurrencyPayloadAction) {
      state.secondCurrencyConverter = payload;
    },

    /**
     * Установка кросс-курса
     * @param action.payload - кросс-курс.
     */
    setCrossCourse(state, { payload }: SetCrossCoursePayloadAction) {
      state.crossCourse = payload;
    },

    /**
     * Генерация puids для страницы конвертера
     */
    setConverterPuids(state) {
      const puidName = getPuidName(PROJECT_ALIASES.Finance);

      state.puids = {
        puid6: `${puidName}_currencies`.toUpperCase(),
        puid18: `${puidName}_currencies_converter`.toUpperCase(),
      };
    },

    /**
     * Установка страницы конвертера в состояние загрузки. Нужно для отображения лодера при spa переходов
     * @param action.payload - флаг загрузки.
     */
    setIsLoading(state, { payload: isLoading }) {
      state.isLoading = isLoading;
    },
  },
});

export const converterReducer = converterSlice.reducer;
export const {
  setCurrencies,
  setFirstCurrency,
  setFirstCurrencyConverter,
  setSecondCurrency,
  setSecondCurrencyConverter,
  setCrossCourse,
  setConverterPuids,
  setIsLoading,
} = converterSlice.actions;
